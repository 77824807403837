import React, {
  useState, useEffect, useMemo, useCallback, Fragment,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  Modal,
  ButtonGroup,
  Table,
  ButtonToolbar,
  Nav,
} from 'react-bootstrap';
import {
  FaMap, FaPlus, FaPen, FaTrash,
} from 'react-icons/fa';
import Api from '../../services/api';
import MapForm from '../../components/MapForm';
import WidgetForm from '../../components/WidgetForm';
import LegendForm from '../../components/LegendForm';

import MapWidgets from './MapWidgets';
import AttributeGroupForm from './AttributeGroupForm';
import { withSettingsStore } from '../common/SettingsContext';

const emptyProjectMap = () => ({
  name: '',
  description: '',
  id: -1,
  arcgisOnlineMapAppId: '',
  projectId: -1,
  isPublic: false,
});

const Maps = withSettingsStore((props) => {
  const { t } = useTranslation();
  const { projectId } = props;
  const [initialized, setInitialized] = useState(false);
  const [showMapDialog, setShowMapDialog] = useState(false);
  // const [showAddDataDialog, setShowAddDataDialog] = useState(false)
  const [maps, setMaps] = useState([]);
  const [showWidgetDialog, setShowWidgetDialog] = useState(false);
  const [showLegendDialog, setShowLegendDialog] = useState(false);
  const [selectedLayer, setSelectedLayer] = useState(null);
  const [selectedMapId, setSelectedMapId] = useState(null);
  const [widget, setWidget] = useState({
    name: '',
    description: '',
    type: null,
    layerName: '',
    attributeName: '',
    alwaysVisible: true,
    widgets: [],
  });
  const [projectMap, setProjectMap] = useState({ ...emptyProjectMap(), projectId });
  const isAdmin = useMemo(() => props.isInRole(['Admin'], Number(projectId)), [projectId, props]);
  const getMaps = useCallback(async () => {
    const maps = await Api().maps.get({ projectId });
    setMaps(maps);
  }, [projectId]);

  useEffect(() => {
    if (!initialized) {
      getMaps();
      setInitialized(true);
    }
  }, [initialized, getMaps]);

  const handleCreateWidget = async () => {
    await Api().maps.widget(projectMap.id).post(widget);
    setShowWidgetDialog(false);
    getMaps();
  };
  const handleCreateMap = async () => {
    console.log('saving', projectMap);
    if (projectMap.id <= 0) {
      await Api().maps.post(projectMap);
    } else {
      await Api().maps.update(projectMap);
    }
    setShowMapDialog(false);
    getMaps();
  };
  const handleRemoveMap = async (id) => {
    await Api().maps.delete({ id });
    getMaps();
  };
  const handleRemoveLayer = async (mapId, layerId) => {
    await Api().maps.layer(mapId).delete({ layerId });
    getMaps();
  };

  const handleViewAttributeGroups = async (layer) => {
    console.log(layer);
    setSelectedLayer(layer);
  };

  const getMapUrl = (id) => `${process.env.REACT_APP_PUBLIC_URL}map/${id}/`;

  const selectedMap = useMemo(() => maps.find((m) => m.id === selectedMapId), [maps, selectedMapId]);

  return (
    <div>
      <Modal show={showWidgetDialog} onHide={() => { setShowWidgetDialog(false); }}>
        <Modal.Header closeButton>
          <Modal.Title>{t('New widget')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <WidgetForm attributeGroups={projectMap.attributeGroups} layers={projectMap.layers} onChange={(widget) => setWidget(widget)} />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => handleCreateWidget()}>{t('Add widget')}</Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showLegendDialog} onHide={() => { setShowLegendDialog(false); }}>
        <Modal.Header closeButton>
          <Modal.Title>{t('Map legends')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LegendForm projectMap={projectMap} onChange={(map) => setProjectMap(map)} />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => handleCreateMap()}>{t('Save legends')}</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showMapDialog} onHide={() => { setShowMapDialog(false); }}>
        <Modal.Header closeButton>
          <Modal.Title>{projectMap.id <= 0 ? t('Create map') : t('Edit map')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MapForm projectMap={projectMap} onChange={(map) => setProjectMap(map)} />
        </Modal.Body>
        <Modal.Footer>
          {projectMap.id <= 0 ? (<Button onClick={() => handleCreateMap()}>{t('Create map')}</Button>) : (<Button onClick={() => handleCreateMap()}>{t('Edit map')}</Button>)}
        </Modal.Footer>
      </Modal>

      <Nav variant="pills" defaultActiveKey={selectedMapId} className="mb-4">
        {maps.sort((a, b) => a.id - b.id).map((m) => (
          <Nav.Item>
            <Nav.Link eventKey={m.id} onClick={() => setSelectedMapId(m.id)}>
              <FaMap />
&nbsp;
              {m.name}
            </Nav.Link>
          </Nav.Item>
        ))}
        <div style={{ flexGrow: 1 }} />
        {isAdmin && (
          <Button
            variant="primary"
            onClick={() => {
              setProjectMap({ ...emptyProjectMap(), projectId });
              setShowMapDialog(true);
            }}
          >
            <FaPlus />
            {' '}
            {t('Add new map')}
          </Button>
        )}
      </Nav>
      {selectedMap !== undefined
        ? (
          <Card className="mb-2" key={selectedMap.id}>
            <Card.Header>
              <h3>
                <FaMap />
&nbsp;
                {selectedMap.name}
              </h3>
            </Card.Header>

            {isAdmin && (
            <ButtonToolbar style={{
              background: '#EFEFEF', borderBottom: '1px solid #CCC', padding: '0.2em', paddingLeft: '1.25rem', paddingRight: '1.25rem',
            }}
            >
              <ButtonGroup>
                <Button
                  variant="outline-primary"
                  onClick={() => {
                    setProjectMap(selectedMap);
                    setShowMapDialog(true);
                  }}
                >
                  <FaPen />
                  {' '}
                  {t('Edit map')}
                </Button>
                <Button
                  variant="outline-primary"
                  onClick={() => {
                    setProjectMap(selectedMap);
                    setShowLegendDialog(true);
                  }}
                >
                  <FaPen />
                  {' '}
                  {t('Edit legends')}
                </Button>
                <Button onClick={() => { setProjectMap(selectedMap); setShowWidgetDialog(true); }}>
                  <FaPlus />
                  {' '}
                  {t('Add widget')}
                </Button>
                <Button variant="danger" onClick={() => handleRemoveMap(selectedMap.id)}>
                  <FaTrash />
                  {' '}
                  {t('Remove map')}
                </Button>
              </ButtonGroup>
            </ButtonToolbar>
            )}
            <Card.Body>
              <Container>
                <Row>
                  <Col>
                    {t('Public URL')}
                    :
                    {!selectedMap.isPublic ? (<span>Map is not public</span>) : (<a href={getMapUrl(selectedMap.id)}>{getMapUrl(selectedMap.id)}</a>)}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <MapWidgets map={selectedMap} />
                  </Col>
                </Row>
              </Container>
            </Card.Body>
            <Card.Body>
              <h3>{t('Layers')}</h3>
              <Table>
                <thead>
                  <tr>
                    <th>{t('#')}</th>
                    <th>{t('Name')}</th>
                    <th>{t('Description')}</th>
                    <th>{t('Actions')}</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedMap.layers.sort((a, b) => a.id - b.id).map((l) => (
                    <Fragment key={l.id}>
                      <tr>
                        <td>{l.id}</td>
                        <td>{l.name}</td>
                        <td>{l.description}</td>
                        <td>
                          <ButtonGroup>
                            <Button variant="danger" onClick={() => handleRemoveLayer(selectedMap.id, l.id)}><FaTrash /></Button>
                            <Button variant="primary" onClick={() => handleViewAttributeGroups(l)}>
                              <FaPen className="mr-2" />
                              {t('Edit attributes')}
                            </Button>
                          </ButtonGroup>
                        </td>
                      </tr>
                      {selectedLayer !== null && selectedLayer.id === l.id && (
                      <tr>
                        <td colSpan="4">
                          <AttributeGroupForm layer={selectedLayer} />
                        </td>
                      </tr>
                      )}
                    </Fragment>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        ) : <div>{t('No map selected')}</div>}
    </div>
  );
});

export default Maps;
