import { getLogger } from 'loglevel';
import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';

const serviceDescriptionEn = (
  <>
    <h1>LIVCY by Ramboll Service Description</h1>
    <p>Thank you for your interest in LIVCY by Ramboll and welcome to the service. The service is provided by Ramboll Finland Oy (The Service Provider).</p>
    <p><strong>A short description of what the LIVCY by Ramboll service provides: </strong></p>
    <p>LIVCY by Ramboll is a web-based service (the Service) that enables users to monitor and analyze the livability of the built environment. The Service provides a visualized GIS-based data platform with different indicators of livability to help with the design and decision-making processes when developing sustainable and livable neighborhoods. The basic version of the Service is based on open data and data collected by Ramboll. The Service can be customized to include other data, such as design documents and data provided by the Customer.</p>
    <p>A link to the Service can be found at: https://fi.ramboll.com/livcy</p>
    <p><strong>Availability of the Service</strong></p>
    <p>The Service is essentially always available as in there are no time restrictions as to when it can be used. Despite the efforts of the Service Provider, there may be temporary or short-term interruptions due to, inter alia, maintenance, general system or data communication malfunctions, or due to force majeure. The user is therefore, not absolutely guaranteed uninterrupted access to the Service.</p>
    <p><strong>User IDs and Customer Accounts</strong></p>
    <p>The user signs up to the Service by providing their contact details.</p>
    <p>The customer's master IDs are used to manage basic customer definitions, user accounts, sub accounts, and customer account information.</p>
    <p>The administrator's root user IDs can, if needed, manage accounts for all users and clients, as well as basic configuration, user accounts, user rights, sub-accounts, and account information for accounts.</p>
    <p><strong>Advice and Support Services</strong></p>
    <p>The Service includes email advice (on weekdays from 9am to 3pm CET) on issues related to the Service and its content. The advice covers essential questions that do not require special research or investigation. However, more extensive agreements requiring special research or investigation may be agreed upon separately.</p>
    <p>The Service advice includes such questions as:</p>
    <ul>
      <li><p>The benefits of using the Service and how it can best be utilized in your projects</p></li>
      <li><p>What information you need to obtain</p></li>
      <li><p>How to order additional support services</p></li>
    </ul>
    <p><strong>Content Provided by Third Parties</strong></p>
    <p>Links to third party websites may be included in the Service. All content, services and any applications provided by such third parties are subject to the terms and conditions defined by such parties and the Service Provider is not responsible for content or services supplied by third parties.</p>
    <p><strong>Customer Information and Personal Data</strong></p>
    <p>In this service description Customer Information refers to any information that a customer enters into the Service when using it. Personal Data refers to any information relating to an identified or identifiable natural person (Data Subject). An identifiable natural person is a person who can be identified, directly or indirectly, in particular, by their identifying information such as name, personal identification number, location information or domain identifier information.</p>
    <p>Customer Information may include Personal Data relating to the users of the Service or to other people if the customer discloses such information within the Service.</p>
    <p><strong>Personal Data to be Processed</strong></p>
    <p>The customer decides what Personal Data to disclose to the Service, the purpose for which the Personal Data is processed, and what Service functionality it uses for processing the Personal Data. Whenever possible, the customer should avoid entering Personal Data that is sensitive by nature or threatens the privacy of the Data Subjects. The customer should also try to minimize the processing of Personal Data in the Service to only what is necessary, as required by data protection legislation.</p>
    <p>It is possible to process at least the following types of Personal Data in the Service:</p>
    <ul>
      <li><p>Personal contact information such as name, email address and phone, name of employer, location of employer, organization size and type</p></li>
      <li><p>Information recorded by the system user in the system such as messages, comments, reminders, and notes</p></li>
      <li><p>Users' electronic identification and address information such as username and IP address</p></li>
    </ul>
    <p><strong>The Personal Data in the Service relates to the following groups of registered persons:</strong></p>
    <ul>
      <li><p>Registered users such as employees of customers and partners</p></li>
      <li><p>Personal Data relating to other users or persons, provided the customer furnishes such information to the Service</p></li>
      <li><p>People who develop and maintain the service</p></li>
    </ul>
    <p><strong>Processing of Personal Data</strong></p>
    <p>The Service Provider treats the Personal Data provided by the customer to the Service in accordance with current data protection legislation, in particular with regard to the requirements of the EU General Data Protection Regulation (Regulation 679/2016 / EU, GDPR).</p>
    <p>As regards the processing of Personal Data, the customer acts as a Data Controller within the meaning of the GDPR and thus determines the purposes and the means of the collection of Personal Data and is responsible for providing proper information to the Data Subjects allowing the Data Subjects to exercise their rights as well as taking care of other obligations imposed by the law.</p>
    <p>The Service Provider processes Personal Data as a Data on behalf of the customer in order to be able to provide the Service to the customer in accordance with the service contract and the necessary data processing agreement. The Service Provider's right to process Personal Data that the customer has entered into the Service is always subject to the customer's right to processing.</p>
    <p>If the customer's business is subject to special provisions concerning processing of information or Personal Data, Customer shall inform and instruct the Service Provider of such specific obligations.</p>
    <p><strong>Access to Customer Information</strong></p>
    <p>Access to Customer Information is provided by the customer and by users authorized by the customer, as well as by those employees who maintain the Service Provider's system or provide support or counselling on the Service on behalf of the Service Provider.</p>
    <p><strong>Helping the customer</strong></p>
    <p>The Service Provider will assist the customer separately on request eg. in the following cases in connection with the Service:</p>
    <ul>
      <li><p>in matters relating to the rights of data subjects</p></li>
      <li><p>about adding / removing authorisations of registered users</p></li>
      <li><p>in requests concerning investigations or notifications on personal data breaches</p></li>
    </ul>
    <p>To provide the service, the Service Provider acquires content production, data processing and IT services from its external contract partners. The Service Provider will use only qualified service providers who are assessed or certified, who have committed themselves to confidentiality and where the subcontractors process personal data as sub-processors they also have a DPA in place with the Service Provider and provide sufficient guarantees to implement technical and organizational measures to protect Personal Data under the GDPR. The suitability of the subcontractors shall be regularly reviewed and audited.</p>
    <p>Services that may be subcontracted may include, for example, advisory and support services, deployment and management of enterprise and end user services, telecommunications and data services, data backup, server management, and service maintenance. Details of subcontracted services and their suppliers are available upon request. The use of subcontractors is based on the prior authorization of customers and any information on possible changes in the use of subcontractors is communicated to customers.</p>
    <p>Customer Information will not be disclosed to third parties unless otherwise instructed by the customer or unless otherwise required by law.</p>
    <p><strong>Cookies and web analytics</strong></p>
    <p>The website of the Service uses cookies and web analytics. For more information on network traffic privacy and cookie policies, visit: https://ramboll.com/legal-information</p>
    <p><strong>Physical location of Customer Information</strong></p>
    <p>For machine, platform and backup services, the location of devices and the physical location of data is in EU. Customer Information will not be transferred to other services (maintenance, data recovery, data destruction).</p>
    <p><strong>Retention time for Customer Information</strong></p>
    <p>Customer Information is retained for as long as the user account is active, after which it is automatically removed from the service after two months retention period. In addition, backups and logs are permanently deleted from the systems within 2 months of the data being removed from the service. Deleted information cannot be recovered.</p>
    <p>Data security and data protection related logs are kept as long as deemed necessary</p>
    <p>in case they are needed to identify or resolve potential data breaches</p>
    <p>Service requests and other messages sent to the support service will be retained for the purpose of resolving possible contingency situations after the end of the contract or freeze period.</p>
    <p><strong>Documents maintained by the Service Provider</strong></p>
    <p>The Service Provider maintains a log on processing activities in accordance with Article 30 (2) of the GDPR and makes it available to the customer and the authorities as appropriate. The document is available on request.</p>
    <p><strong>Potential privacy breaches</strong></p>
    <p>Any potential data breaches of Personal Data will be notified in accordance to Article 33 without undue delay to the customer's data protection contact person or other recipient the customer has specifically named for this purpose.</p>
    <p><strong>Data Protection</strong></p>
    <p>Data Protection refers to the protection of Personal Data, information systems and services from threats and from harm to the business or customers. Personal Data is processed in manner ensuring appropriate security in order to protect systems from unauthorized access, any illegal, unintentional or accidental processing, modification, transfer, disclosure or deletion by using appropriate technical or organizational measures (integrity and confidentiality)</p>
    <p>The Personal Data is accessible only to the customer and users authorized by customer, accurate, and where necessary, kept up to date, and rectified or deleted without delay when the purposes of processing no longer exist.</p>
    <p>These security principles apply to all Customer Information, regardless of whether they contain Personal Data or not. In addition to the principles outlined herein, the general security policies of the Service Provider are available upon request.</p>
    <p><strong>Organization and management of information security</strong></p>
    <p>The service provider has a designated person responsible for coordinating and supervising the processing of Personal Data in accordance to GDPR. The roles and responsibilities of the maintenance of equipment and systems containing Personal Data and customer data have been defined and the responsibilities associated with data security have been taken into account in the assignments of those processing the data.</p>
    <p><strong>Staff security</strong></p>
    <p>The confidentiality of the Personal Data and other information and the nature of the duties are taken into account in the assignment of processing duties. The importance of maintaining confidentiality is emphasized through the employment contracts of employees and/or through a separate confidentiality agreement, where necessary.</p>
    <p>The rules on processing Personal Data and Customer Information and security instructions are available to everyone. Personnel is trained and familiarized with and directed to information-safe practices and the safe handling and protection of Personal Data and Customer Information as well as information security. Personnel is informed about security risks (related to terminal equipment, network connections, e-mail, software, network services).</p>
    <p><strong>Physical security</strong></p>
    <p>Attention has been paid to the installation of server equipment used to process Personal Data and Customer Information, and the equipment is positioned in appropriate and purpose-built premises, taking into account dust, air temperature and humidity, fire and water damage prevention and lock and burglary protection. The power supply of the devices is secured and protected against power surges and power outages.</p>
    <p>Access to premises containing Personal Data or information systems containing Customer Information is restricted to designated authorized persons only.</p>
    <p><strong>Hardware Security</strong></p>
    <p>Processing of Personal Data and Customer Information is only done in enterprise-specific devices that meet the requirements (such as compatibility, security and control) and whose security features are ensured. The security of the usage of server, network and terminal equipment is ensured by careful installation and controlled deployment and continuous maintenance. Improper access to the devices is blocked by password protection.</p>
    <p>Critical server and network devices have been duplicated and, if necessary, available terminal equipment. All available devices are listed and their lifecycle is reviewed from time to time, taking into account manufacturer's warranty and maintenance agreements. Security is taken into account when the equipment is being serviced and the devices are removed when used and recycled.</p>
    <p><strong>Software Security</strong></p>
    <p>Only reliable software and versions are available for your operating system, communications software, and applications. Program security features such as authentication and security features as well as control and logging procedures are utilized in accordance with the necessary level of protection.</p>
    <p>Attacks by viruses and malicious code are centrally prevented with managed security software, which is constantly updated. Devices are in continuous maintenance and security updates run for the operating system and applications, and the success of the security updates and the security of the devices is monitored. The software license and agreement management ensures that software is available and supported.</p>
    <p><strong>Data connections</strong></p>
    <p>The Service is generated and Client information is stored behind firewalls on a secure private network. All connections are protected by SSL / TLS encryption.</p>
    <p><strong>Continuity and extraordinary circumstances management</strong></p>
    <p>A plan is in place for restoring data back-ups and it is regularly tested. The operation and availability of the Service is monitored by monitoring software. Risks are regularly re-evaluated based on the information and experience gathered and proactive measures are taken to minimize risks as part of the service maintenance and development work.</p>
    <p><strong>Contact</strong></p>
    <p>All contact information on the use of the Service or agreements can be found at:</p>
    <p>livcy@ramboll.fi</p>
    <p>If you want to contact Ramboll Finland Oy regarding eg. exercising the rights of Data Subjects or accessing documents:</p>
    <p>info@ramboll.fi</p>
  </>
);

const serviceDescriptionFi = (
  <>
    <h1>LIVCY PALVELUKUVAUS</h1>
    <p>Tervetuloa ja kiitos kiinnostuksestasi Liveable City- eli LIVCY-palvelua kohtaan. Palvelun tuottaa Ramboll Finland Oy (myöh. "Palveluntarjoaja").</p>
    <p><strong>PALVELUN SISÄLTÖ </strong></p>
    <p>LIVCY-palvelussa (myöh. ”Palvelu”) Asiakas saa käyttöönsä verkkopohjaisen kaupunkidata-alustan, jonka kautta voi tarkastella elävyyttä tilatulla aluejaolla. Palvelun perusversio pohjautuu avoimeen dataan sekä Rambollin keräämään aineistoon. Palvelun räätälöity versio voidaan määritellä Asiakkaan kanssa sisältämään myös muuta dataa, kuten suunnitteludokumentteja, näihin liittyvää tietoa sekä muita projektissa käsiteltäviä aineistoja.</p>
    <p><strong>PALVELUN SAATAVUUS </strong></p>
    <p>Palvelu on lähtökohtaisesti käytettävissä jatkuvasti. Palveluntarjoajan pyrkimyksistä huolimatta Palvelussa saattaa olla väliaikaisia tai lyhytkestoisia keskeytyksiä johtuen mm. ylläpidosta, yleisistä järjestelmän tai tietoliikenneyhteyksien toimintahäiriöistä tai ns. ylivoimaisesta esteestä. Käyttäjälle ei taata häiriötöntä pääsyä Palveluun eikä jatkuvaa ja häiriötöntä saatavuutta.</p>
    <p><strong>KÄYTTÄJÄTUNNUKSET JA ASIAKASTILIT </strong></p>
    <p>Asiakkaalle luodaan tili Palveluun Palveluntarjoajan pääkäyttäjän toimesta. Asiakkaan käyttäjät, joilla on Asiakastilin hallintaoikeudet, hallinnoivat Asiakkaan tietokantaan liittyviä perusmäärittelyjä, käyttäjätilejä, alitilejä sekä asiakastilin tietoja. Palveluntarjoajan pääkäyttäjätunnuksilla voidaan tarvittaessa hallinnoida kaikkien käyttäjien ja tilejä sekä tileihin liittyviä perusmäärittelyjä, tietoja sekä, käyttöoikeuksia.</p>
    <p><strong>NEUVONTA JA TUKIPALVELUT </strong></p>
    <p>Neuvonnasta ja tukipalveluista sovitaan erikseen Asiakkaan kanssa. Käytettävyyteen liittyvissä asioissa Asiakas voi olla yhteydessä sähköpostitse. Konsultaatiota voidaan toimittaa erillishinnalla.</p>
    <p><strong>KOLMANSIEN OSAPUOLTEN TUOTTAMA SISÄLTÖ </strong></p>
    <p>Palvelu sisältää aineistoja, linkkejä ja yhteyksiä kolmansien osapuolten avoimiin aineistoihin, joiden lisenssiehdot on kuvattu metatietokuvauksessa. Tällaisiin kolmansien osapuolten tuottamiin sisältöihin, heidän tarjoamiinsa palveluihin tai kolmansien osapuolten välittämiin sovelluksiin sovelletaan kyseisten kolmansien osapuolten käyttö- ja muita ehtoja.</p>
    <p><strong>ASIAKASTIETOJEN KÄSITTELY JA HENKILÖTIETOJEN SUOJA </strong></p>
    <p><strong>Palveluntarjoaja:</strong></p>
    <p>Ramboll Finland Oy</p>
    <p>Espoo, pääkonttori</p>
    <p>PL 25, Itsehallintokuja 3</p>
    <p>FI-02601 Espoo</p>
    <p><a href="mailto:info@ramboll.fi">info@ramboll.fi</a></p>
    <p><strong>Tietosuoja-asioiden yhteyshenkilö:</strong></p>
    <p><strong>Seppo Mattila </strong></p>
    <p>TIETOHALLINTOJOHTAJA, TIETOSUOJA-ASIAT</p>
    <p>P: +358 40 734 7070</p>
    <p>seppo.mattila@ramboll.fi</p>
    <p>
      Lisätietoja henkilötietojesi käsittelystä ja suojaamisesta saat sähköpostitse info@ramboll.fi. Voit myös ottaa yhteyttä tarvittaessa Ramboll Group A/S Data Protection Manageriin
      <a href="mailto:privacy@ramboll.com">privacy@ramboll.com</a>
      .
    </p>
    <p><strong>ASIAKAS- JA HENKILÖTIEDOT </strong></p>
    <p>Asiakastiedoilla tarkoitetaan tässä palvelukuvauksessa kaikkia tietoja, jotka asiakas syöttää Palveluun sitä käyttäessään. Henkilötiedoilla tarkoitetaan kaikkia tunnistettuun tai tunnistettavissa olevaan luonnolliseen henkilöön liittyviä tietoja. Tunnistettavissa olevana pidetään luonnollista henkilöä, joka voidaan suoraan tai epäsuorasti tunnistaa erityisesti tunnistetietojen, kuten nimen, henkilötunnuksen, sijaintitiedon, verkkotunnistetietojen taikka yhden tai useamman hänelle tunnusomaisen fyysisen, fysiologisen, geneettisen, psyykkisen, taloudellisen, kulttuurillisen tai sosiaalisen tekijän perusteella.</p>
    <p>Asiakastiedot voivat sisältää Palvelun käyttäjiin tai muihin henkilöihin liittyviä henkilötietoja, mikäli Asiakas tällaisia tietoja Palveluun syöttää.</p>
    <p><strong>KÄSITELTÄVÄT HENKILÖTIEDOT </strong></p>
    <p>Asiakas määrittelee, mitä henkilötietoja se Palveluun syöttää, missä tarkoituksessa se niitä käsittelee ja mitä Palvelun toiminnallisuuksia se henkilötietojen käsittelyssä käyttää. Asiakkaan tulisi mahdollisuuksien mukaan välttää arkaluonteisten tai kyseessä olevien henkilöiden yksityisyyden suojaa muuten vaarantavien henkilötietojen syöttämistä Palveluun ja pyrkiä minimoimaan henkilötietojen käsittely Palvelussa tietosuojalainsäädännön edellyttämällä tavalla.</p>
    <p>Palvelussa on mahdollista käsitellä ainakin seuraavan tyyppisiä henkilötietoja:</p>
    <ul>
      <li><p>Henkilöiden yhteystietoja kuten nimi, sähköpostiosoite ja puhelin, työnantajan nimi</p></li>
      <li><p>Järjestelmän käyttäjän järjestelmään kirjaamat tiedot kuten viestit, kommentit, muistutukset ja muistiinpanot</p></li>
      <li><p>Käyttäjien sähköisiä tunniste- ja osoitetietoja kuten käyttäjätunnus, IP-osoite ja evästeet</p></li>
    </ul>
    <p>Henkilötiedot koskevat seuraavia rekisteröityjen henkilöiden ryhmiä:</p>
    <ul>
      <li><p>Järjestelmään rekisteröityneet käyttäjät kuten asiakkaan ja kumppaneiden työntekijät ja heidän yhteistyökumppaninsa</p></li>
      <li><p>Käyttäjiin tai muihin henkilöihin liittyviä henkilötietoja, mikäli Asiakas tällaisia tietoja Palveluun syöttää</p></li>
      <li><p>Palvelua kehittävät ja ylläpitävät henkilöt</p></li>
    </ul>
    <p><strong>HENKILÖTIETOJEN KÄSITTELY </strong></p>
    <p>Palveluntarjoaja käsittelee Asiakkaan Palveluun syöttämiä henkilötietoja voimassa olevan tietosuojalainsäädännön mukaisesti erityisesti ottaen huomioon EU:n yleisen tietosuoja-asetuksen (Asetus 679/2016/EU) vaatimukset.</p>
    <p>Asiakas toimii henkilötietojen käsittelyn osalta tietosuojalainsäädännön tarkoittamana rekisterinpitäjänä ja vastaa siten henkilötietojen keräämisen lainmukaisuudesta, rekisteröityjen asianmukaisesta informoinnista, rekisteröityjen oikeuksien toteuttamisesta ja muista rekisterinpitäjälle laissa säädetyistä velvollisuuksista.</p>
    <p>Palveluntarjoaja käsittelee henkilötietoja tietosuojalainsäädännön tarkoittamana käsittelijänä Asiakkaan toimesta ja lukuun, jotta sen on mahdollista toimittaa Palvelu Asiakkaalle Toimitussopimuksen ja sen liitteenä olevan Tietojenkäsittelysopimuksen mukaisesti. Palveluntarjoajan oikeus käsitellä Palveluun syötettyjä henkilötietoja perustuu aina Asiakkaan oikeuteen henkilötietojen käsitellä henkilötietoja.</p>
    <p>Mikäli Asiakkaan toimialaan kohdistuu tietojen käsittelyyn liittyviä erityissäännöksiä, Asiakkaan tulee informoida ja ohjeistaa Palveluntarjoajaa niiden asettamista erityisistä velvoitteista.</p>
    <p><strong>PÄÄSY ASIAKASTIETOIHIN </strong></p>
    <p>Pääsy tietoihin on Asiakkaalla sekä Asiakkaan valtuuttamilla käyttäjillä ja lisäksi Palveluntarjoajan järjestelmän ylläpitoa suorittavilla henkilöillä, sekä tuki- ja neuvontatilanteissa kyseisiä palveluita tuottavilla henkilöillä.</p>
    <p><strong>ASIAKKAAN AVUSTAMINEN </strong></p>
    <p>Palveluntarjoaja avustaa Asiakasta erikseen pyydettäessä seuraavissa asioissa sikäli, kun ne liittyvät Palveluun:</p>
    <ul>
      <li><p>rekisteröityjen oikeuksia koskevia pyyntöjä koskevissa asioissa</p></li>
      <li><p>käyttäjien lisäämistä/poistamista koskevissa asioissa</p></li>
      <li><p>tietosuojaloukkausten selvittämisessä, ilmoittamista koskevissa asioissa.</p></li>
    </ul>
    <p><strong>ALIHANKINTA </strong></p>
    <p>Palvelun tuottamiseksi Palveluntarjoaja hankkii sisällöntuotanto-, tietojenkäsittely- ja tietotekniikkapalveluita ulkopuolisilta sopimuskumppaneiltaan. Alihankkijoiksi hyväksytään ainoastaan luotettaviksi arvioituja tai todettuja palveluntarjoajia, jotka ovat sitoutuneet toimimaan luottamuksellisesti ja toteuttamaan tietosuoja-asetuksen mukaiset tekniset ja organisatoriset toimet henkilötietojen suojaamiseksi. Jos alihankkijat käsittelevät henkilötietoja alikäsittelijöinä heidän kanssaan solmitaan tietosuojalainsäädännön edellyttämä henkilötietojen käsittelyä koskeva sopimus. Käytettävien alihankkijoiden sopivuutta arvioidaan säännöllisesti uudelleen.</p>
    <p>Palveluun kuuluvat alihankitut palvelut voivat sisältää esim. neuvonta- ja tukipalveluita, yritys- ja loppukäyttäjäpalvelun käyttöönottoa ja hallintaa, tietoliikenne- ja konesalipalveluita, tietojen varmuuskopiointia, palvelinten hallintaa ja palveluiden ylläpitoa. Tiedot alihankituista palveluista ja niiden toimittajista ovat saatavissa pyydettäessä. Alihankkijoiden käyttö tapahtuu asiakkailta saadun ennakkoluvan puitteissa ja mahdollisista alihankkijoiden käytössä tapahtuvista muutoksista tiedotetaan asiakkaita.</p>
    <p>Asiakastietoja ei luovuteta kolmansille osapuolille tai viranomaisille, ellei Asiakas toisin ohjeista tai ellei laki toisin vaadi.</p>
    <p><strong>ASIAKASTIETOJEN FYYSINEN SIJAINTI </strong></p>
    <p>Konesali-, alusta- ja varmistuspalveluiden osalta laitetilat ja tietojen fyysinen sijainti on Euroopan Unionin alueella. Asiakastietoja ei siirretä näiden eikä muidenkaan palvelujen (huolto, tietojen palautus, tietojen tuhoaminen) yhteydessä Euroopan Unionin rajojen ulkopuolelle.</p>
    <p><strong>ASIAKASTIETOJEN SÄILYTYSAIKA </strong></p>
    <p>Asiakastietoja säilytetään 60 vuorokautta sopimuksen tai ilmaiskäyttöjakson päättymisen jälkeen, jonka jälkeen ne poistetaan automaattisesti palvelusta. Lisäksi varmuuskopiot sekä lokitiedot poistetaan pysyvästi järjestelmistä 2 kuukauden kuluessa siitä, kun tiedot on poistettu palvelusta. Poistettuja tietoja ei voida palauttaa. Palvelupyynnöt, sopimukset, tilaukset sekä muut tukipalveluun lähetetyt viestit säilytetään mahdollisten ristiriitatilanteiden selvittämistä varten sopimuksen tai ilmaiskäyttöjakson päättymisen jälkeen.</p>
    <p><strong>KÄSITTELIJÄN YLLÄPITÄMÄT ASIAKIRJAT </strong></p>
    <p>Palveluntarjoaja ylläpitää tietosuoja-asetuksen 30 artiklan 2 kohdan mukaista sisäistä selostetta käsittelytoimista ja asettaa sen tarvittaessa Asiakkaan sekä viranomaisen saataville. Asiakirja on saatavilla pyydettäessä.</p>
    <p><strong>MAHDOLLISET TIETOSUOJALOUKKAUKSET </strong></p>
    <p>Mahdollisista havaituista henkilötietoihin kohdistuneista tietoturvaloukkauksista lähetetään ilmoitus sähköpostitse Asiakkaan pääkäyttäjille tai Asiakkaan Palvelussa erikseen tähän tarkoitukseen ilmoittamaan sähköpostiosoitteeseen.</p>
    <p><strong>TIETOTURVA </strong></p>
    <p>Tietoturvalla tarkoitetaan tässä tietojen, tietojärjestelmien ja palveluiden suojaamista siten, että niihin kohdistuvat uhat eivät aiheuta vahinkoa liiketoiminnalle tai asiakkaille. Tavoitteena on suojata tiedot ja järjestelmät asiattomalta pääsyltä ja laittomasti tai vahingossa tapahtuvalta käsittelyltä, muuttamiselta, siirtämiseltä, luovuttamiselta tai poistamiselta, pyrkien säilyttämään tietojen:</p>
    <ul>
      <li><p>luottamuksellisuus - tiedot ovat vain niiden henkilöiden käytössä, joilla on siihen valtuudet</p></li>
      <li><p>eheys - tiedon oikeellisuus, luotettavuus ja ajantasaisuus eivät muutu vahingossa tai tahallisesti</p></li>
      <li><p>saatavuus (käytettävyys) - tieto on saatavilla ja käytettävissä silloin kun sitä tarvitaan.</p></li>
    </ul>
    <p>Näitä tietoturvaperiaatteita sovelletaan kaikkiin asiakastietoihin, sisälsivät ne henkilötietoja tai eivät. Tässä kuvattujen periaatteiden lisäksi sovelletaan Palveluntarjoajan yleisiä tietoturvakäytäntöjä, jotka ovat saatavilla pyydettäessä.</p>
    <p><strong>TIETOTURVAN ORGANISOINTI JA HALLINTO </strong></p>
    <p><strong>Tietoturva-asioiden yhteyshenkilö:</strong></p>
    <p><strong>Eero Salminen</strong></p>
    <p>Projektipäällikkö</p>
    <p>P: +358 (50) 3257830</p>
    <p><a href="mailto:eero.salminen@ramboll.fi">eero.salminen@ramboll.fi</a></p>
    <p>Palveluntarjoajalla on nimetty henkilö, joka on vastuussa tietosuojasääntöjen ja -menetelmien koordinoimisesta ja valvonnasta. Henkilötietoa ja asiakasdataa sisältävien laitteiden ja järjestelmien ylläpidon roolit ja vastuut on määritelty ja näitä tietoja käsittelevien henkilöiden tehtävämäärittelyissä on huomioitu tietoturvaan liittyvät oikeudet, velvollisuudet ja vastuut.</p>
    <p><strong>HENKILÖSTÖTURVALLISUUS </strong></p>
    <p>Tietojen luottamuksellisuus ja työtehtävien luonne otetaan huomioon henkilöiden valinnassa. Luottamuksellisuuden säilyttämisen tärkeyttä korostetaan työsopimuksen lausekkein tai erillisen salassapitosopimuksen avulla. Käsittelysäännöt ja tietoturvaohjeet ovat kaikkien saatavilla. Henkilöstöä perehdytetään ja ohjataan tietoturvallisiin toimintatapoihin ja henkilötietojen ja asiakasdatan turvalliseen käsittelyyn ja suojaamiseen sekä tietoturvaan. Henkilöstöä koulutetaan ja informoidaan tietoturvariskeistä (liittyen päätelaitteisiin, verkkoyhteyksiin, sähköpostiin, ohjelmistoihin, verkkopalveluihin).</p>
    <p><strong>FYYSINEN TURVALLISUUS </strong></p>
    <p>Henkilötietoa ja asiakasdataa sisältävien palvelinlaitteiden sijoittamiseen on kiinnitetty huomiota ja laitteet on sijoitettu asianmukaisiin ja tarkoitukseen suunniteltuihin tiloihin huomioiden pöly, ilman lämpötila ja kosteus, palo- ja vesivahinkojen ennaltaehkäisy sekä lukitus ja murtosuojaus. Laitteiden sähkönsyöttö on varmistettu ja suojattu virtalähdevikojen, virtapiikkien ja sähkökatkojen varalta.</p>
    <p>Pääsy toimitiloihin, joissa on henkilötietoa tai asiakasdataa sisältäviä tietojärjestelmiä, on rajoitettu nimettyihin valtuutettuihin henkilöihin.</p>
    <p><strong>LAITTEISTOTURVALLISUUS </strong></p>
    <p>Henkilö- ja asiakastietojen käsittelyyn käytetään ainoastaan yrityskäyttöön tarkoitettuja ja vaatimukset (mm. yhteensopivuuden, tietoturvan ja hallittavuuden osalta) täyttäviä laitteita, joiden turvallisuusominaisuudet on varmistettu. Palvelin-, verkko- ja päätelaitteiden käytön turvallisuus varmistetaan huolellisella asennuksella ja hallitulla käyttöönotolla sekä jatkuvalla ylläpidolla. Asiaton pääsy laitteille on estetty salasanasuojauksella.</p>
    <p>Kriittiset palvelin- ja verkkolaitteet on kahdennettu ja tarpeen vaatiessa käytettävissä on varapäätelaitteita. Kaikki käytössä olevat laitteet on luetteloitu ja niiden elinkaari käydään läpi aika ajoin, huomioiden valmistajien takuut ja ylläpitosopimukset. Tietoturva huomioidaan laitteiden huollossa ja laitteita poistettaessa käytöstä ja kierrätettäessä.</p>
    <p><strong>OHJELMISTOTURVALLISUUS </strong></p>
    <p>Ohjelmistojen osalta pyritään varmistamaan, että käytössä on ainoastaan luotettavia ohjelmistoja ja niiden versioita, koskien käyttöjärjestelmää, tietoliikenneohjelmistoja ja sovelluksia. Ohjelmien turvallisuusominaisuuksia, kuten tunnistamis- ja suojausominaisuuksia sekä valvonta- ja lokimenettelyjä, hyödynnetään suojaamistarpeen mukaisesti.</p>
    <p>Viruksilta ja haittakoodilta on suojauduttu keskitetysti hallitulla ja jatkuvasti päivitetyillä tietoturvaohjelmistolla. Laitteet ovat jatkuvassa ylläpidossa, jossa huolehditaan käyttöjärjestelmän ja sovellusten tietoturvapäivityksistä ja valvotaan päivitysten onnistumista ja laitteiden tietoturvan tilaa. Ohjelmistolisenssien ja –sopimusten hallinnalla varmistetaan, että ohjelmistot ovat käytettävissä ja tuettuja.</p>
    <p><strong>TIETOLIIKENNETURVALLISUUS</strong></p>
    <p>Palvelu tuotetaan ja Asiakastiedot säilytetään palomuurein suojatussa erillisverkossa. Kaikki yhteydet on suojattu SSL/TLS-salauksella.</p>
    <p><strong>JATKUVUUDEN JA ERITYISTILANTEIDEN HALLINTA</strong></p>
    <p>Tietojen varmuuskopioilta palauttamista varten on tehty suunnitelma ja palauttamista testataan säännöllisesti. Palvelun toimintaa ja saatavuutta seurataan valvontaohjelmistolla. Riskit uudelleenarvioidaan tarpeen mukaan kerätyn tiedon ja kokemuksien perusteella, ja ennakoivia toimenpiteitä tehdään riskien minimoimiseksi osana Palvelun ylläpito- ja kehitystyötä.</p>
    <p><strong>YHTEYSTIEDOT </strong></p>
    <ul>
      <li><p>Kaikki Palveluun käyttöön tai sopimuksiin liittyvät yhteydenotot: oma Livcy-kontakti</p></li>
      <li>
        <p>
          Ramboll Finland Oy liittyvät yhteydenotot kuten rekisteröityjen oikeuksien toteuttaminen, asiakirjoihin tutustuminen
          <a href="mailto:info@ramboll.fi">info@ramboll.fi</a>
          .
        </p>
      </li>
    </ul>
    <p><strong>PÄIVITYKSET </strong></p>
    <p>Tämä palvelukuvaus on viimeksi päivitetty 13.4.2021.</p>
  </>
);

const ServiceDescription = () => {
  const getLanguage = () => {
    const paramsString = window.location.search;
    const params = new URLSearchParams(paramsString);
    return params.get('lang');
  };

  const language = getLanguage();

  return (
    <Container className="mt-4">
      <Row>
        <Col>
          {language === 'en' && serviceDescriptionEn}
          {(language === 'fi' || language === null) && serviceDescriptionFi}
        </Col>
      </Row>
    </Container>
  );
};

export default ServiceDescription;
