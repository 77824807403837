import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Card,
  CardColumns,
} from 'react-bootstrap';

import { FaChartLine as FaChartBar } from 'react-icons/fa';
import Api from '../../services/api';

const MapWidgets = (props) => {
  const { t } = useTranslation();
  const { map } = props;
  const [initialized, setInitialized] = useState(false);
  const [widgets, setWidgets] = useState([]);

  const getWidgets = useCallback(async () => {
    const widgets = await Api().maps.widget(map.id).get({ projectId: map.projectId });
    setWidgets(widgets);
  }, [map]);

  useEffect(() => {
    if (!initialized) {
      getWidgets();
      setInitialized(true);
    }
  }, [initialized, getWidgets]);

  const handleDeleteWidget = async (id) => {
    await Api().maps.widget(map.id).delete({ id });
    getWidgets();
  };

  return (
    <CardColumns className="my-2">
      {widgets.map((widget) => (
        <Card key={widget.id} className="my-2">
          <Card.Header>
            <Card.Title>
              <FaChartBar />
&nbsp;
              {widget.name}
            </Card.Title>
            <Card.Text>
              {widget.description}
            </Card.Text>
          </Card.Header>
          <Card.Body>
            <Card.Text>
              {t('Widget type')}
              :
              {' '}
              {widget.type}
            </Card.Text>
            <Card.Text>
              {t('Target attribute group')}
              :
              {' '}
              {widget.attributeGroupId !== null && map.attributeGroups.find((ag) => ag.id === widget.attributeGroupId).name}
            </Card.Text>
            <Card.Text>
              {t('Target layer id')}
              :
              {' '}
              {widget.layerId !== null && map.layers.find((ag) => ag.id === widget.layerId).name}
            </Card.Text>
            <Card.Text>{widget.alwaysVisible && <span>{t('This widget is always visible')}</span>}</Card.Text>
          </Card.Body>
          <Card.Footer>
            <Button variant="danger" onClick={() => handleDeleteWidget(widget.id)}>{t('Delete')}</Button>
          </Card.Footer>
        </Card>
      ))}
    </CardColumns>
  );
};

export default MapWidgets;
