import React from 'react';
import PropTypes from 'prop-types';
import {
  IconHealth,
  IconExercise,
  IconCycling,
  IconFilm,
  IconCommerce,
  IconTree,
} from '../../assets/icons/LivcyIcons';

const livcyIcons = {
  kaup_liv: IconCommerce,
  sos_liv: IconHealth,
  liike_liv: IconCycling,
  liiku_liv: IconExercise,
  viher_liv: IconTree,
  kult_liv: IconFilm,
  kaup_min: IconCommerce,
  sos_min: IconHealth,
  liike_min: IconCycling,
  liiku_min: IconExercise,
  viher_min: IconTree,
  kult_min: IconFilm,
};

const propTypes = {
  icon: PropTypes.oneOfType(Object.keys(livcyIcons)).isRequired,
};

const LivcyIcon = ({ icon }) => {
  const Icon = livcyIcons[icon];

  if (Icon === undefined) return (null);

  return (
    <Icon />
  );
};

LivcyIcon.propTypes = propTypes;

export default LivcyIcon;
