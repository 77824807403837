import { color, math } from 'dda-helpers';

export const TryNumber = (string) => {
  if (typeof string === 'undefined') {
    return undefined;
  }
  if (typeof string === 'number') {
    return string;
  }

  // e.g. 00123 should not be converted into number
  if (string !== '0' && string.startsWith('0') && !string.includes('.') && !string.includes(',')) {
    return string;
  }

  const numberString = Number(string.replace(',', '.'));

  if (typeof numberString === 'number' && !isNaN(numberString)) {
    return numberString;
  }

  return string;
};

export const GetMaxExtent = (extent1, extent2) => {
  if (extent1 === null) {
    return extent2;
  }
  if (extent2 === null) {
    return extent1;
  }
  // If extent goes over coordinate system axis
  const offset = Math.min(...[...extent1, ...extent2]);

  const [lx1, ly1, lx2, ly2] = extent1.map((e) => e + offset);
  const [cx1, cy1, cx2, cy2] = extent2.map((e) => e + offset);

  const mx1 = Math.min(lx1, cx1);
  const my1 = Math.min(ly1, cy1);

  const mx2 = Math.max(lx2, cx2);
  const my2 = Math.max(ly2, cy2);

  return [mx1, my1, mx2, my2].map((e) => e - offset);
};

/**
 *
 * @param {Array} extent1 number n-tuple of rectangle [x1,y1,x2,y2]
 * @param {Array} extent2 number n-tuple of rectangle [x1,y1,x2,y2]
 * @returns true if extent1 is within extent2
 */
export const IsExtentWithin = (extent1, extent2) => {
  if (extent1 === null || extent2 === null) {
    return false;
  }
  // If extent goes over coordinate system axis
  const offset = Math.min(...[...extent1, ...extent2]);
  const offsetExtent1 = extent1.map((e) => e + offset);
  const offsetExtent2 = extent2.map((e) => e + offset);

  // If all points in extent1 are within extent2 this is true
  if (
    offsetExtent1[0] >= offsetExtent2[0] && offsetExtent1[0] <= offsetExtent2[2]
    && offsetExtent1[2] >= offsetExtent2[0] && offsetExtent1[2] <= offsetExtent2[2] // x
    && offsetExtent1[1] >= offsetExtent2[1] && offsetExtent1[1] <= offsetExtent2[3]
    && offsetExtent1[3] >= offsetExtent2[1] && offsetExtent1[3] <= offsetExtent2[3] // y
  ) {
    return true;
  }
  return false;
};

export const IsPointWithinExtent = (point, extent) => {
  if (point === null || extent === null) {
    return false;
  }
  const offset = Math.min(...extent);
  const offsetPoint = point.map((p) => p + offset);
  const offsetExtent = extent.map((e) => e + offset);

  if (offsetPoint[0] >= offsetExtent[0] && offsetPoint[0] <= offsetExtent[2] // x1
    && offsetPoint[1] >= offsetExtent[1] && offsetPoint[1] <= offsetExtent[3] // y1
  ) {
    return true;
  }
  return false;
};

/**
 *
 * @param {Array} offsetExtent1 number n-tuple of rectangle [x1,y1,x2,y2]
 * @param {Array} offsetExtent2 number n-tuple of rectangle [x1,y1,x2,y2]
 * @returns true if extent1 is within extent2
 */
export const IsExtentOverlapping = (extent1, extent2) => {
  if (extent1 === null || extent2 === null) {
    return false;
  }
  const offset = Math.min(...[...extent1, ...extent2]);
  const offsetExtent1 = extent1.map((e) => e + offset);
  const offsetExtent2 = extent2.map((e) => e + offset);
  // Test both coordinates if the are within the area defined by extent2
  if ((offsetExtent1[0] >= offsetExtent2[0] && offsetExtent1[0] <= offsetExtent2[2] // x1
    && offsetExtent1[1] >= offsetExtent2[1] && offsetExtent1[1] <= offsetExtent2[3]) // y1
    || (offsetExtent1[2] >= offsetExtent2[0] && offsetExtent1[2] <= offsetExtent2[2] // x2
      && offsetExtent1[3] >= offsetExtent2[1] && offsetExtent1[3] <= offsetExtent2[3]) // y2
  ) {
    return true;
  }
  return false;
};

// The extent is outside the current extent => need to expand
// Only load what is needed!

// Get extents that need to be loaded
// Let's use closure for that
export const GetExpandingExtents = (extent1, extent2, isForced) => {
  const extents = [];

  // If there is no loaded extent, use the current one!
  if (extent1 === null) {
    return [extent2];
  }

  if (isForced) {
    return [GetMaxExtent(extent1, extent2)];
  }

  // Check each axis and create extents if needed
  const [lx1, ly1, lx2, ly2] = extent1;
  const [cx1, cy1, cx2, cy2] = extent2;

  const mx1 = Math.min(lx1, cx1);
  // let my1 = Math.min(ly1, cy1);
  const mx2 = Math.max(lx2, cx2);
  // let my2 = Math.max(ly2, cy2);

  // Every axis change creates a new area to be loaded
  if (cx1 < lx1) {
    // X axis slice with the loaded y
    extents.push([cx1, ly1, lx1, ly2]);
  }
  if (cx2 > lx2) {
    // X axis slice with the loaded y
    extents.push([lx2, ly1, cx2, ly2]);
  }
  if (cy1 < ly1) {
    // Y axis slice with the current x
    extents.push([mx1, cy1, mx2, ly1]);
  }
  if (cy2 > ly2) {
    // Y axis slice with the current x
    extents.push([mx1, ly2, mx2, cy2]);
  }
  return extents;
};

export const CreateColorMapHex = (start, stop, options) => {
  const numSteps = options.numSteps !== undefined ? Number(options.numSteps) : 5;
  const hsl = options.hsl !== undefined ? !!options.hsl : false;

  const startColor = color.hexToRgba(start, true);
  const endColor = color.hexToRgba(stop, true);

  if (hsl) {
    const startColorHsl = color.rgbToHsl(startColor);
    const endColorHsl = color.rgbToHsl(endColor);
    const stepsHsl = math.interpolate(
      [startColorHsl.h, startColorHsl.s, startColorHsl.l],
      [endColorHsl.h, endColorHsl.s, endColorHsl.l], numSteps,
    );
    return stepsHsl
      .map((s) => color.rgbaToHex(color.hslToRgb({ h: s[0], s: s[1], l: s[2] })));
  }
  const stepsRgb = math.interpolate(
    [startColor.r, startColor.g, startColor.b],
    [endColor.r, endColor.g, endColor.b], numSteps,
  );

  return stepsRgb.map((s) => color.rgbaToHex(
    {
      r: Math.floor(s[0]),
      g: Math.floor(s[1]),
      b: Math.floor(s[2]),
    },
  ));
};

/**
 * Generate legend dynamically
 * @param {object} dynamic legend object
 * @returns
 */
export const CreateDynamicLegend = ({
  values, numSteps, stops, name,
}) => {
  values.sort((a, b) => a - b);
  // stops
  // const startValue = Math.min(...values);
  // const endValue = Math.max(...values);

  const stepSets = [];
  const numStepsPerSet = Math.ceil(numSteps / (stops.length - 1));

  for (let i = 0; i < stops.length - 1; i++) {
    stepSets.push(
      CreateColorMapHex(stops[i], stops[i + 1], {
        numSteps: numStepsPerSet + (i > 0 ? 1 : 0),
        hsl: false,
      }),
    );
  }

  let colors = [];

  for (let i = 0; i < stepSets.length; i++) {
    if (i > 0) {
      stepSets[i].splice(0, 1);
    }
    colors = [...colors, ...stepSets[i]];
  }
  // We need to select steps from this set
  // const thresholds = math.interpolate(startValue, endValue, numSteps)
  const stepLength = (values.length) / numSteps;
  const thresholds = [];

  for (let i = 0; i < numSteps; i++) {
    thresholds.push(values[Math.floor(stepLength * i)]);
  }

  return {
    name,
    steps: thresholds.map((t, idx) => ({
      id: idx,
      threshold: Math.floor(t),
      color: colors[idx],
    })),
  };
};
