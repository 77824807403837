import React, { useState, useEffect, useContext } from 'react';
import { Popover, Row, Col } from 'react-bootstrap';
import { HomeContext } from './HomeContext';
import { withSettingsStore } from '../common/SettingsContext';
import CircularChart from './CircularChart';
import Widget from './Widget';
import LivcyIcon from './LivcyIcon';
import CustomRadio from './CustomRadio';

// Livcy Data
const createLivcyData = (labels, data, colors, codes, order) => {
  const items = codes.map((code, idx) => ({
    code,
    label: labels[idx],
    color: colors[idx],
    data: data[idx],
    order: order[idx],
  }));
  return items;
};

// Popover
const helpKey = (
  <Popover id="popover-basic" style={{ maxWidth: '500px' }}>
    <Popover.Title as="h3">Livcy-indeksi</Popover.Title>
    <Popover.Content>
      <Row>
        <Col>
          LIVCY-yhdistelmäindeksi kuvaa alueen elävyyttä pistevälillä 0-100
          ja huomioi elävyyden kannalta keskeisimpiä muuttujia. Indeksi laskee
          elävyyden kokonaispistemäärää huomioiden valittujen elävyyskategorioiden
          saavutettavuutta ja intensiteettiä eli palvelutiheyttä suhteessa alueen
          asukasmäärään. LIVCY-indeksi on Rambollin kehittämä ja tutkimustietoon
          perustuva elävyyden indeksi.
        </Col>
      </Row>
    </Popover.Content>
  </Popover>
);

const WidgetLivcy = withSettingsStore(({ widget, data }) => {
  // Update Livcy Index Categories
  const [livcyData, setLivcyData] = useState([]);

  // Update data
  useEffect(() => {
    if (data !== undefined) {
      const labels = data.attributes.map((a) => a.name);
      const colors = data.attributes.map((a) => a.color);
      const codes = data.attributes.map((a) => a.code);
      const order = data.attributes.map((a) => a.order);

      // Create average for each attribute label
      const graphData = data.attributes.map((attribute) => {
        const attrData = data.data.map((row) => {
          let val = row[attribute.code];
          if (typeof val !== 'number') {
            val = 0;
          }
          return val;
        });
        return attrData.reduce((acc, cur) => acc + cur, 0) / attrData.length;
      });

      setLivcyData([
        ...createLivcyData(labels, graphData, colors, codes, order),
      ]);
    }
  }, [data]);

  // Get Home Context
  const {
    toggleAttribute,
  } = useContext(HomeContext);

  // Select Current Attribute
  const handleChange = (e) => {
    const attribute = data.attributes[e];
    toggleAttribute({ attributeId: attribute.id });
  };

  return (
    <Widget data={data} widget={widget} helpKey={helpKey}>
      <div className="LivcyList">
        {livcyData !== null && livcyData.map((item, idx) => (
          <div
            key={item.code}
            className={`livcyList__item ${item.code === 'livcy_i' ? 'livcyList__item--livcy' : ''}`}
            style={{ color: item.color }}
          >
            <CustomRadio
              name="livcy"
              item={item}
              attributeIdx={idx}
              handleChange={handleChange}
            >
              <span className="d-flex align-items-center flex-grow-1 mb-0">
                {item.code !== 'livcy_i' && <LivcyIcon icon={item.code} />}
                <span className="livcyList__name">{item.label}</span>
              </span>
            </CustomRadio>
            <CircularChart
              size={Number(item.code === 'livcy_i' ? 60 : 40)}
              index={Number(item.data.toFixed(0))}
              strokeWidth={Number(item.code === 'livcy_i' ? 4 : 2)}
              circleOneStroke="currentColor"
              circleTwoStroke="currentColor"
            />
          </div>
        ))}
      </div>
    </Widget>
  );
});

export default WidgetLivcy;
