import React from 'react';
import { useTranslation } from 'react-i18next';
import './Footer.css';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer id="footer" className="page-footer font-small bg-primary text-white">
      <div className="footer-copyright text-center py-3 text-small">
        <span>
          {t('footer.copyright')}
          {' '}
          &copy;
          {' '}
          {(new Date().getFullYear())}
&nbsp;
        </span>
        <a className="text-white" href="https://fi.ramboll.com/">Livcy by Ramboll</a>
        &nbsp;·&nbsp;
        {t('footer.all-rights-reserved')}
        &nbsp;·&nbsp;
        <a href="/privacy-policy" className="text-white">{t('footer.privacy-policy')}</a>
        &nbsp;·&nbsp;
        <a href="/terms-of-use" className="text-white">{t('footer.terms-of-use')}</a>
        &nbsp;·&nbsp;
        <a href="/service-description" className="text-white">{t('footer.service-description')}</a>
      </div>
    </footer>
  );
};

export default Footer;
