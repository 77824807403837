import React, { useState, useContext, useMemo } from 'react';
import {
  Container, Row, Col, ButtonToolbar, Button, Modal, Form, ButtonGroup,
} from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { clone } from 'dda-helpers';
import { withSettingsStore } from '../common/SettingsContext';
import WidgetForm from './WidgetForm';
import { CompareContext, CompareStore } from './CompareContext';
import loglevel from '../../services/loglevel';
import Api from '../../services/api';
import BarWidget from './widgets/BarWidget';
import CompareInlineEdit from './CompareInlineEdit';
// Encapsulate Compare page with compare store
const Compare = () => (
  <CompareStore>
    <CompareHome />
  </CompareStore>
);
const CompareHome = withSettingsStore(({ isInRole }) => {
  const { t } = useTranslation();
  const [showWidgetForm, setShowWidgetForm] = useState(false);
  const [selectedWidget, setSelectedWidget] = useState(null);

  const [showEditor, setShowEditor] = useState(false);

  const {
    projects,
    selectedProject, setSelectedProject,
    isLoadingProjects,
    widgets, setWidgets,
    isLoadingWidgets,
  } = useContext(CompareContext);

  const addDashboardWidget = (w) => {
    if (w.id === undefined || w.id === null || w.id <= 0) {
      loglevel.info('Adding new dashboard widget', w);
      Api().maps.compare({ mapId: w.mapId }).post(w);
      setWidgets((prev) => [...prev, w]);
    } else {
      loglevel.info('Updating dashboard widget', w);
      Api().projects.widgets({ projectId: selectedProject.id, widgetId: w.id }).update(w);
    }

    setShowWidgetForm(false);
  };

  const removeDashboardWidget = (w) => {
    Api().projects.widgets({ projectId: selectedProject.id, widgetId: w.id }).remove();
  };

  const editDashboardWidget = (w) => {
    loglevel.info('editing', w);
    setSelectedWidget(clone({ ...w, data: undefined }));
    setShowWidgetForm(true);
  };

  const populateGraphData = (widget) => {
    const baseColors = [
      '#F9CDE8',
      '#96CDFF',
      '#FFE083',
      '#8BF9E7',
      '#A1D49A',
      '#FF8996',
    ];

    const data = {
      graphType: widget.graphType,
      isDistribution: widget.isDistribution,
      attributeGroupId: widget.attributeGroupId,
      labels: widget.features.map((f) => f.name),
      datasets: widget.attributes.map((sa, idx) => ({
        label: sa.name,
        unit: sa.unitNameShort,
        data: widget.features.map((f) => f.values.find((fv) => fv.attributeCode === sa.code)).filter((fv) => fv !== undefined).map((fv) => Number(fv.value.replace(',', '.'))),
        backgroundColor: baseColors[idx],
        borderWidth: 1,
      })),
    };
    widget.data = data;
    return widget;
  };

  const inlineEditDashboardWidget = (widget) => {
    setShowEditor((prev) => ((prev === undefined || prev !== widget) ? widget : undefined));
  };

  const updateWidget = (widget) => (newWidget) => {
    setWidgets((prev) => {
      const existing = prev.find((w) => w === widget);
      Object.keys(existing).forEach((k) => {
        existing[k] = newWidget[k];
      });
      return [...prev];
    });
  };

  const isAdmin = useMemo(() => {
    if (selectedProject === null) {
      return false;
    }
    return isInRole(['Admin'], selectedProject.id);
  }, [isInRole, selectedProject]);

  return (
    <>
      <Modal show={showWidgetForm} onHide={() => setShowWidgetForm(false)} size="xl">
        <Modal.Header>
          <h3>
            {t('widget')}
          </h3>
        </Modal.Header>
        <Modal.Body>
          <WidgetForm widget={selectedWidget} onSubmit={(widget) => addDashboardWidget(widget)} />
        </Modal.Body>
      </Modal>
      {!isLoadingProjects && selectedProject !== null && (
      <>
        {isAdmin
                && (
                <ButtonToolbar className="p-3 bg-info">
                  <ButtonGroup>
                    {projects.length > 1 && (
                    <Form.Control as="select" defaultValue={selectedProject.id} custom style={{ width: 'auto' }} onChange={(e) => setSelectedProject(projects.find((p) => p.id === Number(e.target.value)))}>
                      {projects.map((p) => <option value={p.id}>{p.name}</option>)}
                    </Form.Control>
                    )}
                    {isAdmin && (
                    <Button onClick={() => { setSelectedWidget(null); setShowWidgetForm(true); }}>
                      <FaPlus className="mr-2" />
                      {' '}
                      {t('add-new-widget')}
                    </Button>
                    )}
                  </ButtonGroup>
                </ButtonToolbar>
                )}
        <Container fluid className="my-4">
          <h1 className="text-center">Vertailu</h1>
          {isLoadingWidgets && <div style={{ textAlign: 'center' }}><span style={{ margin: 'auto' }} className="spinner-border spinner-border-lg" /></div>}
          <Row className="m-4">
            {widgets.map((w) => populateGraphData(w)).map((w) => (
              <Col xl="6">
                <BarWidget
                  allowEdit={isAdmin}
                  title={w.title}
                  description={w.description}
                  data={w.data}
                  onRemove={() => removeDashboardWidget(w)}
                  onEdit={() => editDashboardWidget(w)}
                  onInlineEdit={() => inlineEditDashboardWidget(w)}
                  type={w.graphType}
                >
                  {showEditor === w && (
                    <>
                      <CompareInlineEdit
                        widget={w}
                        onChange={(newWidget) => { updateWidget(w)(newWidget); }}
                      />
                      <Button onClick={() => setShowEditor(false)}>{t('done')}</Button>
                    </>
                  )}
                </BarWidget>
              </Col>
            ))}
          </Row>
        </Container>
      </>
      )}
    </>
  );
});

export default Compare;
