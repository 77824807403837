import React, { useContext, useEffect, useState } from 'react';

import LayerTile from 'ol/layer/Tile';
import LayerVector from 'ol/layer/Vector';
import MapContext from './MapContext';
import logger from '../../services/loglevel';

const Layers = ({ children }) => <div>{children}</div>;

const TileLayer = ({ source, zIndex = 0, isGrayscale = false }) => {
  const { map } = useContext(MapContext);
  useEffect(() => {
    logger.info('TileLayer initialized');
    if (!map) {
      return;
    }

    const tileLayer = new LayerTile({ source, zIndex });

    if (isGrayscale) {
      tileLayer.on('postrender', (event) => {
        const { context } = event;
        // convolve(event.context, selectedKernel);
        const { canvas } = context;
        const { width } = canvas;
        const { height } = canvas;

        const inputData = context.getImageData(0, 0, width, height).data;
        const output = context.createImageData(width, height);
        const outputData = output.data;

        for (let i = 0; i < inputData.length; i += 4) {
          const r = inputData[i];
          const g = inputData[i + 1];
          const b = inputData[i + 2];
          const a = inputData[i + 3];
          const gray = r * 0.299 + g * 0.587 + b * 0.114;
          outputData[i] = gray;
          outputData[i + 1] = gray;
          outputData[i + 2] = gray;
          outputData[i + 3] = a;
        }

        context.putImageData(output, 0, 0);
      });
    }

    map.addLayer(tileLayer);
    tileLayer.setZIndex(zIndex);
    return () => { if (map) { map.removeLayer(tileLayer); } };
  }, [map]);

  return null;
};

const VectorLayer = ({
  source, style, zIndex = 0, extent,
}) => {
  const { map, initialExtent, setInitialExtent } = useContext(MapContext);
  const [layer, setLayer] = useState(null);

  useEffect(() => {
    logger.info('VectorLayer update');
    if (map === undefined || layer === null) {
      return;
    }
    const existingLayer = map.getLayers().getArray().find((l) => l === layer);

    if (existingLayer !== undefined && existingLayer.getSource() !== source) {
      logger.info('Source updated...');
      existingLayer.setSource(source);

      map.render();
    }

    if (existingLayer !== undefined && existingLayer.getStyle() !== style) {
      logger.info('Style updated...');
      existingLayer.setStyle(style);
      map.render();
    }
  }, [source, style, zIndex]);

  useEffect(() => {
    logger.info('VectorLayer init');
    if (map === undefined) {
      return;
    }

    const layerVector = new LayerVector({ source, style });
    map.addLayer(layerVector);
    layerVector.setZIndex(zIndex);

    // HACK If initial extent is not set, set it from this layer
    if (extent !== undefined && extent !== null && map !== null && initialExtent === null) {
      setInitialExtent(extent);
      map.getView().fit(extent);
    }

    setLayer(layerVector);

    return () => {
      if (map) {
        logger.info('VectorLayer remove');
        map.removeLayer(layerVector);
      }
    };
  }, [map]);

  return null;
};
export { Layers, TileLayer, VectorLayer };
