import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, OverlayTrigger } from 'react-bootstrap';
import { FiChevronDown, FiChevronUp, FiInfo } from 'react-icons/fi';
import { FaLock } from 'react-icons/fa';
import { HomeContext } from './HomeContext';
import { withSettingsStore } from '../common/SettingsContext';
import './Widget.scss';

const Widget = withSettingsStore(({
  widget, data, helpKey, children, displayLoginModal,
}) => {
  const { t } = useTranslation();
  const [showDetails, setShowDetails] = useState(false);
  const {
    currentAttributeGroup,
    toggleAttributeGroup,
  } = useContext(HomeContext);

  const Help = ({ helpKey }) => (
    <OverlayTrigger trigger={['hover', 'focus', 'click']} placement="right" overlay={helpKey}>
      <FiInfo className="mr-1" />
    </OverlayTrigger>
  );

  useEffect(() => {
    if (currentAttributeGroup !== null) {
      setShowDetails(currentAttributeGroup.id === widget.attributeGroupId);
    }
  }, [currentAttributeGroup, widget.attributeGroupId]);

  const toggleWidget = () => {
    if (currentAttributeGroup.id !== widget.attributeGroupId) {
      setShowDetails(false);
    } else {
      setShowDetails((details) => !details);
    }
    toggleAttributeGroup({ attributeGroup: data.attributeGroup });
  };

  return (
    <>
      {widget.enabled && (
        <div className="widget">
          <button
            type="button"
            className="widget__toggle"
            disabled={!widget.enabled}
            onClick={!widget.locked ? (() => toggleWidget()) : () => { /* Do nothing */ }}
          >
            <span>
              {widget.name}
              {' '}
              <Help helpKey={helpKey} />
            </span>
            {widget.locked && (
              <Button
                disabled={!widget.enabled}
                size="sm"
                variant="outline-primary"
                onClick={() => displayLoginModal(t('login.insufficient-permissions', { email: 'livcy@ramboll.fi' }))}
              >
                <FaLock />
              </Button>
            )}
            {!widget.locked && (
              showDetails ? <FiChevronUp /> : <FiChevronDown />
            )}
          </button>
          {!widget.locked && showDetails && currentAttributeGroup.id === widget.attributeGroupId && (
            <div className="widget__body">
              <small>{widget.description}</small>
              {children}
            </div>
          )}
        </div>
      )}
    </>
  );
});

export default Widget;
