import React, { useState, useEffect, useMemo } from 'react';
import Api from '../../services/api';
import { useTranslation } from 'react-i18next';
import { FaPlus, FaCaretRight } from 'react-icons/fa';
import {
  Container,
  Card,
  CardDeck,
  ButtonToolbar,
  Button,
  ButtonGroup,
  Modal,
} from 'react-bootstrap';
import ProjectForm from '../../components/ProjectForm';
import './Home.css';
import { LinkContainer } from 'react-router-bootstrap';
import ProjectPlaceholderImage from '../../assets/project-placeholder.jpg';
import { withSettingsStore } from '../common/SettingsContext';

const ProjectCard = withSettingsStore(({ project, isInRole }) => {
  // const { t } = useTranslation();
  const p = project;

  const isAdmin = useMemo(() => isInRole(['Admin'], p.id), [isInRole, p.id]);

  return (
    <Card className="project-card">
      <Card.Img variant="top" src={ProjectPlaceholderImage} />
      <Card.Body>
        <Card.Title>
          {p.description}
        </Card.Title>
      </Card.Body>
      {isAdmin && (
      <LinkContainer to={`/project/${p.id}`}>
        <Button variant="primary" className="no-border-radius">
          {p.name}
          <FaCaretRight />
        </Button>
      </LinkContainer>
      )}
    </Card>
  );
});

const Home = withSettingsStore((props) => {
  const { isAdmin } = props;
  const { t } = useTranslation();
  const [projects, setProjects] = useState([]);
  const [showProjectDialog, setShowProjectDialog] = useState(false);
  const [project, setProject] = useState({
    name: '',
    description: '',
    id: -1,
  });

  async function getProjects() {
    const projects = await Api().projects.get({});
    setProjects(projects);
  }

  useEffect(() => {
    getProjects();
  }, []);

  async function handleCreateProject() {
    // console.log(project)
    await Api().projects.post(project);
    setShowProjectDialog(false);
    getProjects();
  }

  return (
    <Container>
      <Modal show={showProjectDialog} onHide={() => { setShowProjectDialog(false); }}>
        <Modal.Header closeButton>
          <Modal.Title>{t('Create project')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ProjectForm create project={project} onChange={(project) => setProject(project)} />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => handleCreateProject()}>{t('Create project')}</Button>
        </Modal.Footer>
      </Modal>
      {isAdmin && (
      <ButtonToolbar className="my-2 align-right">
        <ButtonGroup className="ml-auto">
          <Button variant="outline-primary" onClick={() => setShowProjectDialog(true)}>
            <FaPlus />
            {' '}
            {t('New project')}
          </Button>
        </ButtonGroup>
      </ButtonToolbar>
      )}
      <CardDeck className="my-2">
        {projects.map((p) => (
          <ProjectCard key={p.id} project={p} />
        ))}
      </CardDeck>
    </Container>
  );
});

export default Home;
