import React, {
  Fragment, useContext, useEffect, useState,
} from 'react';
import {
  Button, Col, Row, Spinner,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FiChevronRight, FiChevronLeft } from 'react-icons/fi';
import { HomeContext } from './HomeContext';
import { TryNumber } from '../common/helpers';

import Widgets from './Widgets';
import CircularChart from './CircularChart';
import './Sidebar.scss';

const Sidebar = ({ mapId }) => {
  const { t } = useTranslation();
  const [isHidden, setIsHidden] = useState(false);
  const [livcyIndex, setLivcyIndex] = useState(0);
  const [importantAttributes, setImportantAttributes] = useState([]);
  const {
    currentLayer,
    currentFeatures,
    selectedFeatureIds,
  } = useContext(HomeContext);

  const getSelectedFeature = () => {
    if (selectedFeatureIds.length > 0 && currentFeatures.length > 0) {
      const feature = currentFeatures.find((f) => f.id === selectedFeatureIds[0]);
      if (feature !== undefined) {
        return feature;
      }
    }
    return null;
  };
  const getSelectedFeatureNameValuePair = (attr) => {
    let name = attr.code;
    let value = null;
    const f = getSelectedFeature();
    if (f !== null) {
      const v = f.values.find((v) => v.attributeCode === attr.code);
      if (v !== undefined) {
        name = v.attributeName;
        value = TryNumber(v.value);
      }
    }
    return {
      name,
      value,
    };
  };

  useEffect(() => {
    if (currentLayer !== null) {
      // Check that the selected feature actually exists in this new layer
      if (currentFeatures.find((f) => f.projectLayerId === currentLayer.id && f.id === selectedFeatureIds[0]) === undefined) {
        return;
      }

      // Livcy Index
      const livcyIndexAttribute = currentLayer.attributes.find((a) => a.code === 'livcy_i');
      setLivcyIndex(getSelectedFeatureNameValuePair(livcyIndexAttribute).value);

      // Important Attributes
      setImportantAttributes(currentLayer.attributes.filter((a) => a.isImportant && a.code !== 'livcy_i').map((a) => getSelectedFeatureNameValuePair(a)));
    }
  }, [currentFeatures, currentLayer, selectedFeatureIds]);

  const defaultStyle = { minHeight: '600px', height: '100%' };

  return (
    <div style={isHidden ? {
      ...defaultStyle, width: '0',
    } : { ...defaultStyle, width: '400px' }}
    >
      <div className="sidebar" style={{ height: '100%', pointerEvents: 'auto' }}>
        <Button className="sidebar__toggle" size="sm" variant="light" onClick={() => setIsHidden((h) => !h)}>
          {isHidden ? <FiChevronRight /> : <FiChevronLeft />}
        </Button>
        {!isHidden && (
          <Row className="mx-2">
            <Col xs={4} className="livcybox">
              <CircularChart
                size={80}
                index={livcyIndex !== null && Number(livcyIndex.toFixed(0))}
                strokeWidth={4}
                circleOneStroke="currentColor"
                circleTwoStroke="currentColor"
              />
              {t('Livcy-indeksi')}
            </Col>
            <Col xs={8} className="selected-area">
              <dl className="selected-list">
                {importantAttributes.map((a) => (
                  <Fragment key={a.name}>
                    <dt>{a.name}</dt>
                    <dd>
                      {a.value !== null ? a.value
                        : <Spinner animation="border" size="sm" />}
                    </dd>
                  </Fragment>
                ))}
              </dl>
            </Col>
          </Row>
        )}
        {!isHidden && <hr style={{ margin: '0' }} />}
        {!isHidden && <Widgets mapId={mapId} />}
      </div>
    </div>
  );
};

export default Sidebar;
