import React, { useState, useEffect, useCallback } from 'react';
import Api from '../../services/api';

const CompareContext = React.createContext();

const CompareStore = ({ children }) => {
  const [projects, setProjects] = useState([]);
  const [widgets, setWidgets] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedProjectMaps, setSelectedProjectMaps] = useState([]);
  const [isLoadingProjects, setIsLoadingProjects] = useState(false);
  const [isLoadingWidgets, setIsLoadingWidgets] = useState(false);

  const contextState = {
    projects,
    selectedProject,
    setSelectedProject,
    selectedProjectMaps,
    isLoadingProjects,
    setIsLoadingProjects,
    widgets,
    setWidgets,
    isLoadingWidgets,
  };

  useEffect(() => {
    if (selectedProject !== null) {
      setIsLoadingWidgets(true);
      Api().maps.get({ projectId: selectedProject.id }).then((maps) => setSelectedProjectMaps(maps));
      Api().projects.widgets({ projectId: selectedProject.id }).get().then((w) => { setWidgets(w); setIsLoadingWidgets(false); });
    }
  }, [selectedProject]);

  useEffect(() => {
    setIsLoadingProjects(true);
    Api().projects.get({}).then((p) => { setIsLoadingProjects(false); setProjects(p); setSelectedProject(p[0]); });
  }, []);

  return (
    <CompareContext.Provider value={contextState}>
      {children}
    </CompareContext.Provider>
  );
};

function withCompareStore(Component) {
  return function ConnectedComponent(props) {
    return (
      <CompareContext.Consumer>
        {({
          projects,
          selectedProject,
          setSelectedProject,
          isLoadingProjects,
          widgets,
          setWidgets,
        }) => (
          <Component
            {...props}
            projects={projects}
            selectedProject={selectedProject}
            setSelectedProject={setSelectedProject}
            isLoadingProjects={isLoadingProjects}
            widgets={widgets}
            setWidgets={setWidgets}
          />
        )}
      </CompareContext.Consumer>
    );
  };
}

export { CompareContext, CompareStore, withCompareStore };
