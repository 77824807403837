import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './LineChart.scss';

const LineChart = (props) => {
  const [percent, setPercent] = useState(0);
  const {
    value,
    max,
    color,
    strokeWidth,
  } = props;

  useEffect(() => {
    const p = (value / max) * 100;
    setPercent(p);
  }, [setPercent, value, max, percent]);

  return (
    <>
      <div
        className="lineChart"
        style={{ color, height: strokeWidth }}
      >
        <div
          className="lineChart__shadow"
          style={{ height: strokeWidth }}
        />
        <div
          className="lineChart__line"
          style={{ width: `${percent}%`, height: strokeWidth }}
        />
      </div>
    </>
  );
};

LineChart.propTypes = {
  value: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  strokeWidth: PropTypes.number.isRequired,
};

export default LineChart;
