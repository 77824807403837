import loglevel from './loglevel';

function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
  // todo get setTimeout returnvalue and use clearTimeOut later to cancel delay
}

class TokenManager {
  constructor(api, setToken) {
    this.api = api;
    this.setToken = setToken;
    this.status = null;
    this.isRunning = false;
  }

  storeToken({ token, expiration }) {
    loglevel.info('Storing token', token, expiration);

    // Save the token to local storage
    window.localStorage.setItem('token', token);
    window.localStorage.setItem('tokenExpires', expiration);

    // Save the token
    if (typeof this.setToken === 'function') {
      this.setToken(token);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  removeToken() {
    loglevel.info('Removing token');
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('tokenExpires');
  }

  // eslint-disable-next-line class-methods-use-this
  getToken() {
    const token = window.localStorage.getItem('token');
    const tokenExpires = window.localStorage.getItem('tokenExpires');

    return { token, tokenExpires };
  }

  start(onError) {
    if (this.isRunning) {
      loglevel.info('Refresh token loop is already running');
      return undefined;
    }
    loglevel.debug('Started token refresh loop');
    return new Promise((resolve, reject) => {
      try {
        this.isRunning = true;
        this.refreshApiTokenLoop(resolve).then(() => {
          this.isRunning = false;
        });
        loglevel.info('done');
      } catch (e) {
        this.isRunning = false;
        onError(e);
      }
    });
  }

  stop() {
    this.isRunning = false;
    loglevel.info('Stop requested');
  }

  // todo stop set stopped true & remove localstorage tokens

  async refreshApiTokenLoop(resolve) {
    // Refresh token
    try {
      loglevel.info('Refreshing api token...');
      const response = await this.refreshApiToken();
      if (response !== undefined && response !== null) {
        // Request is done and is successful
        loglevel.info('response', response);
        this.storeToken(response);
      }

      // Prepare the next update
      const currentDate = new Date();
      const { tokenExpires } = this.getToken();
      const expireDate = new Date(tokenExpires);
      let expiresIn = expireDate.getTime() - currentDate.getTime();
      if (expiresIn <= 10000 || Number.isNaN(expiresIn)) {
        expiresIn = 20000;
      }
      loglevel.info(`Refreshing in ${expiresIn - 10000} milliseconds`);
      await delay(expiresIn - 10000);
      // todo if is stopped true then return
      // Go to next iteration
      if (this.isRunning) {
        await this.refreshApiTokenLoop(resolve);
      }
      loglevel.info('Refresh loop ending...');
    } catch (error) {
      if (error) {
        throw new Error('Token does not exist');
        // return
      } else {
        // this.removeToken()
      }
    }
  }

  async refreshApiToken() {
    const { tokenExpires } = this.getToken();

    loglevel.info(`${new Date(tokenExpires)} vs ${new Date(new Date().getTime() + 10000)}`);

    if (tokenExpires === undefined) {
      throw new Error('Token is not set');
    }

    // We had a failure and the token is no longer valid. Time to refresh
    if (new Date(tokenExpires) <= new Date(new Date().getTime() + 10000)) {
      // Remove the interceptor to prevent a loop in case token refresh also causes the 401
      // Make sure refresh tokens exist and there are no existing queries trying to ret
      loglevel.info('refreshing');
      if (this.status === null) {
        // We are now attempting to refresh the token
        this.status = 'running';
        try {
          loglevel.info('login', this.api);
          const response = await this.api.accounts.refresh();
          this.status = null;
          return response;
        } catch (error) {
          this.status = null;
          throw error;
        }
      }
    }
    return undefined;
  }
}
export default TokenManager;
