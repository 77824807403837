import React, { useEffect, useMemo } from 'react';
import {
  Nav, Navbar, NavDropdown, Image, Button,
} from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useTranslation } from 'react-i18next';
import { FaUserCircle, FaDoorOpen, FaLock } from 'react-icons/fa';

import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { withSettingsStore } from './SettingsContext';
import './NavBar.css';

const NavBar = withSettingsStore(({
  isLoggedIn, isAdmin, currentRoles, user, displayRegisterModal, displayLoginModal, logout,
}) => {
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();
  useEffect(() => {
    const isLogin = pathname === '/loginmodal';
    if (typeof displayLoginModal === 'function' && isLogin) {
      displayLoginModal();
    }
  }, [displayLoginModal, pathname]);

  useEffect(() => {
    const isRegister = pathname === '/register';
    if (typeof displayRegisterModal === 'function' && isRegister) {
      displayRegisterModal();
    }
  }, [displayRegisterModal, pathname]);

  // If the user has any roles in any project, this memo will tell that
  const hasProjects = useMemo(() => currentRoles.length > 0, [currentRoles.length]);

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <Navbar bg="primary" variant="dark">
      <Navbar.Brand className="pt-0">
        <Link to="/home">
          <Image
            height={40}
            className="mr-3"
            src="/logo-white.svg"
            alt=""
          />
        </Link>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          {isLoggedIn && (
          <LinkContainer to="/home">
            <Nav.Link href="#home">{t('navbar.home')}</Nav.Link>
          </LinkContainer>
          )}
          {isLoggedIn && hasProjects && (
          <LinkContainer to="/compare">
            <Nav.Link>{t('navbar.compare')}</Nav.Link>
          </LinkContainer>
          )}
          {isLoggedIn && hasProjects && (
          <LinkContainer to="/report/1">
            <Nav.Link>{t('navbar.reports')}</Nav.Link>
          </LinkContainer>
          )}
          {isLoggedIn && !hasProjects && (
            <Nav.Link onClick={() => displayLoginModal(t('login.insufficient-permissions', { email: 'livcy@ramboll.fi' }))} href="#">
              <FaLock />
              {' '}
              {t('navbar.compare')}
            </Nav.Link>
          )}
          <LinkContainer to="/about">
            <Nav.Link href="#about">{t('navbar.about')}</Nav.Link>
          </LinkContainer>
          {isAdmin && hasProjects && (
          <LinkContainer to="/projects">
            <Nav.Link href="#projects">{t('navbar.projects')}</Nav.Link>
          </LinkContainer>
          )}
        </Nav>
        <NavDropdown className="navbar-nav open-left" title={<span className={`flag-icon flag-icon-${i18n.language}`} />}>
          <NavDropdown.Item onSelect={() => i18n.changeLanguage('fi')}>
            <span className="flag-icon flag-icon-fi" />
            <span>{t('navbar.language.finnish')}</span>
          </NavDropdown.Item>
          <NavDropdown.Item onSelect={() => i18n.changeLanguage('en')}>
            <span className="flag-icon flag-icon-en" />
            <span>{t('navbar.language.english')}</span>
          </NavDropdown.Item>
        </NavDropdown>
        {isAdmin && <Button href="/admin">{t('Administration')}</Button>}
        {isLoggedIn
          ? (
            <NavDropdown
              className="navbar-nav open-left"
              title={(
                <>
                  <FaUserCircle size={24} />
                  {' '}
                  {user.username.toLowerCase()}
                </>
              )}
            >
              <LinkContainer to="/me">
                <NavDropdown.Item href="#me">{t('navbar.account information')}</NavDropdown.Item>
              </LinkContainer>
              <NavDropdown.Item onSelect={() => { logout(); }}>{t('navbar.logout')}</NavDropdown.Item>
            </NavDropdown>
          ) : (
            <NavDropdown
              className="navbar-nav open-left"
              title={(
                <>
                  <FaDoorOpen size={24} />
                  {' '}
                  {t('navbar.user account')}
                </>
              )}
            >
              <NavDropdown.Item onClick={() => displayLoginModal()}>{t('navbar.login')}</NavDropdown.Item>
              <NavDropdown.Item onClick={() => displayRegisterModal()}>{t('navbar.register')}</NavDropdown.Item>
            </NavDropdown>
          )}
      </Navbar.Collapse>
    </Navbar>
  );
});

export default NavBar;
