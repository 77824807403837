import React, {
  useState, useEffect, useMemo,
} from 'react';

import GeoJSON from 'ol/format/GeoJSON';
import OSM from 'ol/source/OSM';
import BingMaps from 'ol/source/BingMaps';
import Vector from 'ol/source/Vector';
import * as proj from 'ol/proj';

import {
  Fill, Stroke, Style, Text,
} from 'ol/style';
import {
  Card,
  ListGroup,
  Spinner,
  Button,
  ButtonGroup,
  Badge,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import {
  FaPlus, FaMinus, FaLock, FaCircle,
} from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { withSettingsStore } from '../common/SettingsContext';
import {
  OpenLayersMap, TileLayer, VectorLayer, Layers,
} from '../../components/OpenLayersMap';
import loglevel from '../../services/loglevel';

import './ReportMapView.scss';
import { CreateDynamicLegend } from '../common/helpers';

const ReportMapView = withSettingsStore(({
  displayLoginModal,
  children,
  // Map related props
  map,
  layer,
  layerData,
  readonly,
  attributeCode,
  attributeGroup,
  legendPosition,
  extent,
  showZoom = true,
  showNumbers = true,
  useBingMaps = false,
}) => {
  const { t } = useTranslation();

  const padding = '10px';
  const [legend, setLegend] = useState(null);
  const [visibleLayers, setVisibleLayers] = useState([]);
  const [selectedMapFeatures, setSelectedMapFeatures] = useState([]);

  const [initialExtent, setInitialExtent] = useState(null);
  const [zoom, setZoom] = useState(9);
  const [currentLayerData, setCurrentLayerData] = useState(null);

  const isLoadingFeatures = useMemo(() => layerData === null, [layerData]);
  // Available legends based on the layer and attribute group
  const availableLegends = useMemo(() => {
    if (map === null || layerData === null) {
      return [];
    }
    return map.legends.filter((l) => l.layerId === layerData.id);
  }, [map, layerData]);

  const selectedLayer = useMemo(() => layer, [layer]);
  const selectedExtent = useMemo(() => extent, [extent]);
  const selectedAttributeGroup = useMemo(() => attributeGroup, [attributeGroup]);
  const selectedAttribute = useMemo(() => {
    if (layer !== null && attributeGroup !== null) {
      const attr = layer.attributes.filter((a) => a.code === attributeCode && a.attributeGroupId === selectedAttributeGroup.id);
      if (attr.length === 1) {
        return attr[0];
      }
    }
    return null;
  }, [layer, attributeGroup]);

  // Initial extent
  useEffect(() => {
    if (extent !== null && initialExtent === null) {
      setInitialExtent(extent);
    }
  }, [extent, initialExtent]);

  const selectedLegend = useMemo(() => {
    if (selectedAttribute === null) {
      return null;
    }
    loglevel.info('Selected attribute', selectedAttribute, availableLegends);
    const found = availableLegends.find((l) => l.attributeGroupId === selectedAttribute.attributeGroupId);
    loglevel.info('found', found);
    return found !== undefined ? found : null;
  }, [selectedAttribute, availableLegends]);

  const layerDataToGeoJSON = (data, attribute) => ({
    type: 'FeatureCollection',
    features: data.features.map((g) => ({
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [...g.coordinates],
      },
      properties: { [attribute.code]: g.values[attribute.code], featureId: g.id },
    })),
  });

  useEffect(async () => {
    // Retrieve feature data
    if (selectedLayer === null || selectedAttribute === null) {
      loglevel.info('No loading triggered', selectedLayer, selectedAttribute);
      return;
    }

    try {
      if (layerData === undefined) {
        loglevel.warn('Layerdata is not set');
        return;
      }

      /* if (currentLayerData !== null && currentLayerData.timestamp === layerData.timestamp) {
        // Nothing changed
        loglevel.info('Timestamp not changed. No need to do anything');
        return;
      } */

      setCurrentLayerData(layerData);

      if (selectedAttribute === undefined) {
        loglevel.warn('No selected attribute');
        return;
      }

      const vectorSource = new Vector({
        features: new GeoJSON().readFeatures(layerDataToGeoJSON(layerData, selectedAttribute), {
          dataProjection: proj.get('EPSG:3067'),
          featureProjection: proj.get('EPSG:3067'),
        }),
      });

      setVisibleLayers([
        { ...selectedLayer, vectorSource },
      ]);
    } catch (e) {
      loglevel.info('queries cancelled', e);
    }
  }, [selectedAttribute, selectedLayer, selectedExtent]);

  const getNextThreshold = (legend, step) => {
    const nextStep = legend.steps.find((s) => s.threshold > step.threshold);
    if (nextStep === undefined) {
      return '';
    }
    return nextStep.threshold;
  };
  const isDynamicLegend = (layerLegend) => layerLegend.steps.length >= 2
    && layerLegend.steps.map((s) => s.threshold).every((s, i, a) => a[0] === s);

  useEffect(() => {
    loglevel.info('Updating legend values...', selectedLegend);
    if (selectedLegend !== null) {
      if (isDynamicLegend(selectedLegend)) {
        if (currentLayerData === null) {
          loglevel.warn('No data');
          return;
        }
        loglevel.warn('Dynamic legends are unsupported!', currentLayerData, selectedAttribute);
        const legend = CreateDynamicLegend({
          values: currentLayerData.features.map((f) => f.values[selectedAttribute.code]),
          numSteps: selectedLegend.steps[0].threshold,
          stops: selectedLegend.steps.map((s) => s.color),
          name: selectedLegend.name,
        });
        loglevel.info(selectedLegend);
        loglevel.info({
          ...selectedLegend,
          ...legend,
        });
        setLegend({
          ...selectedLegend,
          ...legend,
        });
      } else {
        setLegend(selectedLegend);
      }
    }
  }, [selectedLegend, currentLayerData]);

  const currentVisibleLayers = useMemo(() => {
    // Without legend, there is no reason to list layers
    // Legends are used to colorize the layer data
    if (legend === null) {
      loglevel.warn('Visible layers: no legend yet');
      return [];
    }
    const legendSteps = legend.steps.sort((a, b) => a.threshold - b.threshold);
    const emptyStyle = new Style({
      stroke: new Stroke({
        color: '#00000000',
        width: 0,
      }),
      fill: new Fill({
        color: '#00000000',
      }),
    });
    const selectionStyle = new Style({
      stroke: new Stroke({
        color: '#05326E',
        width: 4,
      }),
      /* fill: new Fill({
        color: '#FF006677',
      }), */
    });
    selectionStyle.setZIndex(1);

    const legendStyles = legendSteps.map((s) => ({
      step: s,
      stroke: new Stroke({
        // color: s.color,
        color: '#FFFFFF',
        width: 1,
      }),
      fill: new Fill({
        color: `${s.color}99`,
      }),
    }));

    const isSelected = (featureId) => selectedMapFeatures.includes(Number(featureId));
    const getFeatureId = (olProperties) => ('featureId' in olProperties ? Number(olProperties.featureId) : 0);
    const getFeatureValue = (olProperties, code) => (code in olProperties ? Number(olProperties[code]) : -1);

    loglevel.info('updating visible layers...', visibleLayers);
    return visibleLayers.map((l) => ({
      ...l,
      key: l.id,
      vectorSource: l.vectorSource,
      style: (feature, resolution) => {
        // TypeError: can't access property "code", currentAttribute is null
        if (selectedAttribute === null) {
          loglevel.warn('empty style');
          return emptyStyle;
        }

        const properties = feature.getProperties();
        const value = getFeatureValue(properties, selectedAttribute.code);
        const featureId = getFeatureId(properties);

        let currentStepIdx = -1;
        // TODO retrieve / update view with data provided outside

        for (let i = 0; i < legendSteps.length; i++) {
          const step = legendSteps[i];
          if (value >= step.threshold) {
            currentStepIdx = i;
          }
        }

        if (currentStepIdx === -1) {
          // loglevel.warn('No step found!', value, properties, currentAttribute)
          return emptyStyle;
        }
        const { stroke, fill } = legendStyles[currentStepIdx];

        const text = new Text({
          text: value.toFixed(0),
          font: '12pt sans-serif',
          color: '#05326E',
        });

        if (isSelected(featureId)) {
          if (l.name !== 'Ruudut') {
            selectionStyle.setText(text);
          }
          selectionStyle.setFill(fill);
          return selectionStyle;
        }

        // HACK Ruudut layer uses fill and stroke only
        if (l.name === 'Ruudut') {
          return new Style({
            fill,
            stroke,
          });
        }

        return new Style({
          stroke,
          text: (showNumbers && resolution < 500) && text,
          fill,
        });
      },
    }));
  }, [legend, selectedMapFeatures, visibleLayers, selectedAttribute]);

  // Bing Maps options
  const key = process.env.REACT_APP_BING_API_KEY;
  const customStyle = 'water|fillColor:EEEEEE;labelColor:999999_road|fillColor:EEEEEE_global|landColor:FFFFFF';
  const bingSource = {
    key: `${key}&st=${customStyle}`,
    imagerySet: 'CanvasGray',
  };

  return (
    <div className="mapview-container">
      {legend !== null && legendPosition === 'top' && (
        <div className="legend-header">
          <div className="title">
            <strong>{legend.name}</strong>
            {selectedAttribute !== null && <div>{selectedAttribute.name}</div>}
          </div>
          <div className="steps">
            {legend.steps.sort((a, b) => a.threshold - b.threshold).map((s) => (
              <span className="legend-inline" key={s.threshold}>
                <FaCircle style={{ color: s.color }} className="mr-2" />
                {s.threshold}
                –
                {getNextThreshold(legend, s)}
                {s.description}
              </span>
            ))}
          </div>
        </div>
      )}
      <div className="map-container position-relative">
        <OpenLayersMap
          zoom={zoom}
          extent={initialExtent}
          onClick={(fs) => setSelectedMapFeatures(
            [...fs]
              .map((feature) => Number(feature.values_.featureId)),
          )}
          multi={false}
        >
          <Layers>
            {useBingMaps
              ? <TileLayer source={new BingMaps(bingSource)} zIndex={0} />
              : <TileLayer source={new OSM()} zIndex={0} isGrayscale /> }
            {currentVisibleLayers.map((layer, idx) => (
              <VectorLayer
                key={layer.name}
                source={layer.vectorSource}
                style={layer.style}
                zIndex={idx + 1}
              />
            ))}
          </Layers>
        </OpenLayersMap>
        {!readonly && (
          <div
            className="position-absolute d-flex justify-content-start"
            style={{
              top: 0, left: 0, pointerEvents: 'none', height: '100%', paddingBottom: 0,
            }}
          >
            {children !== undefined && (
              <div className="mr-2">
                {children}
              </div>
            )}
            <div className="mt-2 mr-2 position-relative">
              {isLoadingFeatures && (
                <Card className="loading-indicator">
                  <div className="d-flex">
                    <div className="flex-shrink-1 mr-2">
                      <Spinner animation="border" size="sm" />
                    </div>
                    <div>
                      <span>Ladataan...</span>
                    </div>
                  </div>
                </Card>
              )}
              <div className="position-absolute" style={{ bottom: 0, left: 0 }}>
                {selectedAttributeGroup !== null && (
                  <Badge className="mr-1" variant="primary">
                    {selectedAttributeGroup.name}
                  </Badge>
                )}
                {selectedAttribute !== null && (
                  <Badge variant="primary">
                    {selectedAttribute.name}
                  </Badge>
                )}
              </div>
            </div>
          </div>
        )}
        {showZoom && (
        <ButtonGroup vertical className="position-absolute" style={{ top: padding, right: padding }} size="sm">
          <Button onClick={() => setZoom((z) => z + 1)} variant="primary"><FaPlus /></Button>
          <Button onClick={() => setZoom((z) => z - 1)} variant="primary"><FaMinus /></Button>
        </ButtonGroup>
        )}
        {legend !== null && legendPosition === undefined && (
        <Card className="position-absolute" style={{ bottom: padding, right: padding }}>
          <ListGroup>
            <ListGroup.Item className="dense">
              {legend.name}
              {selectedAttribute !== null && <div>{selectedAttribute.name}</div>}
            </ListGroup.Item>
            {legend.steps.sort((a, b) => a.threshold - b.threshold).map((s) => (
              <ListGroup.Item className="dense" key={s.id + s.color + s.threshold}>
                <FaCircle style={{ color: s.color }} className="mr-2" />
                {s.threshold}
                {' '}
                –
                {' '}
                {getNextThreshold(legend, s)}
                {' '}
                {s.description}

              </ListGroup.Item>
            ))}
          </ListGroup>
        </Card>
        )}
      </div>
    </div>
  );
});

export default ReportMapView;
