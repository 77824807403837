import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import loglevel from '../services/loglevel'
import {
  Form,
} from 'react-bootstrap';

const InviteForm = ({ onSubmit, onChange }) => {
  const { t } = useTranslation();

  const [invite, setInvite] = useState({
    email: '',
    projectRole: null,
    roleId: 3, // user
  });

  useEffect(() => {
    onChange(invite);
  }, [invite]);

  const handleChange = (event) => {
    const { value } = event.target;
    const { name } = event.target;
    setInvite((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <Form>
      <Form.Group controlId="formEmail">
        <Form.Label>{t('Email address')}</Form.Label>
        <Form.Control name="email" value={invite.email} type="email" placeholder={t('Enter email')} onChange={handleChange} />
      </Form.Group>
      <Form.Group controlId="formRole">
        <Form.Label>{t('Role')}</Form.Label>
        <Form.Control as="select" defaultValue={invite.roleId} custom onChange={handleChange} name="roleId">
          <option value="3">User</option>
          <option value="2">Admin</option>
        </Form.Control>
      </Form.Group>
    </Form>
  );
};
export default InviteForm;
