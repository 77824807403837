// Base react
import React, {
  useState, useEffect, useRef, useCallback,
} from 'react';

// Open Layers
import Map from 'ol/Map.js';
import View from 'ol/View.js';
import './OpenLayersMap.css';
import Overlay from 'ol/Overlay';

// Default projections stuff
import * as proj from 'ol/proj';
import proj4 from 'proj4';
import { register } from 'ol/proj/proj4';
import {
  Circle as CircleStyle, Fill, Stroke, Style, Text,
} from 'ol/style';
import { useTranslation } from 'react-i18next';
import logger from '../../services/loglevel';
import MapContext from './MapContext';
import loglevel from '../../services/loglevel';

const OpenLayersMap = ({
  children, zoom, center, className, onClick, onZoom, onMove, multi = false, readonly, extent,
}) => {
  const isReadOnly = !!readonly;
  const { t } = useTranslation();
  const mapRef = useRef(); // ref used by map
  const [map, setMap] = useState(null);
  const [initialExtent, setInitialExtent] = useState(null);
  const [selected, setSelected] = useState([]);

  const highlightStyle = new Style({
    fill: new Fill({
      color: 'rgba(255,255,255,0.7)',
    }),
    stroke: new Stroke({
      color: '#3399CC',
      width: 3,
    }),
  });

  // on component mount
  useEffect(() => {
    logger.info('Initializing OpenLayers map');
    proj4.defs('EPSG:3879', '+proj=tmerc +lat_0=0 +lon_0=25 +k=1 +x_0=25500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs');
    proj4.defs('EPSG:3067', '+proj=utm +zone=35 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs');
    register(proj4);
    // Default options
    const options = {
      view: new View({
        projection: 'EPSG:3067'/* 'EPSG:3857' */,
        zoom,
        center,
        extent: extent === null ? undefined : extent,
      }),
      layers: [],
      controls: [],
      overlays: [],
      projection: 'EPSG:3067',
    };

    // Create map
    const mapObject = new Map(options);
    mapObject.setTarget(mapRef.current);
    if (!isReadOnly) {
      mapObject.on('singleclick', (e) => {
        const features = [];
        mapObject.forEachFeatureAtPixel(e.pixel, (f) => {
          features.push(f);
        });

        onClick(features);
      });

      mapObject.on('moveend', (e) => {
        console.log('move end', e);
        const { map } = e;
        const newZoom = mapObject.getView().getZoom();
        // const extent = proj.transformExtent(map.getView().calculateExtent(), 'EPSG:3857', 'EPSG:3067');
        loglevel.info('exte', extent);
        if (typeof onZoom === 'function' && zoom !== newZoom) {
          onZoom(newZoom);
        }
        if (typeof onMove === 'function' && map !== null) {
          onMove({
            zoom: map.getView().getZoom(),
            extent: map.getView().calculateExtent(),
          });
        }
      });
    }

    // Store it
    setMap(mapObject);

    // Tooltip
    const tooltip = document.getElementById('tooltip');
    const overlay = new Overlay({
      element: tooltip,
      offset: [10, 0],
      positioning: 'bottom-left',
    });
    mapObject.addOverlay(overlay);
    const displayTooltip = (e) => {
      const { pixel } = e;
      const feature = mapObject.forEachFeatureAtPixel(pixel, (feature) => feature);

      // Set cursor as pointer for clickable features
      mapObject.getViewport().style.cursor = feature ? 'pointer' : '';

      // Set tooltip for empty areas
      !feature && tooltip ? overlay.setPosition(e.coordinate) : overlay.setPosition(0, 0);
    };
    mapObject.on('pointermove', displayTooltip);

    return () => mapObject.setTarget(undefined);
  }, []);

  useEffect(() => {
    if (map !== null && Array.isArray(extent) && extent.length === 4) {
      setInitialExtent(extent);
      map.getView().fit(extent);
    }
  }, [extent, map, initialExtent]);

  // zoom change handler
  useEffect(() => {
    logger.info('Map or zoom updated...');
    if (!map) {
      return;
    }
    map.getView().setZoom(zoom);
  }, [map, zoom]);

  // center change handler
  useEffect(() => {
    logger.info('Map or center updated...');
    if (!map) {
      return;
    }
    // const localCenter = proj4('EPSG:3067', 'EPSG:3857', [center[0], center[1]]);
    map.getView().setCenter(center);
  }, [map, center]);

  return (
    <MapContext.Provider value={{ map, initialExtent, setInitialExtent }}>
      <div ref={mapRef} className={`ol-map ${className}`}>
        {children}
      </div>
      <div id="tooltip">{t('There is no data for this area')}</div>
    </MapContext.Provider>
  );
};

export default OpenLayersMap;
