import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Col,
  Button,
  Table,
  Row,
  OverlayTrigger,
  Popover,
  Container,
} from 'react-bootstrap';
import { FaTrash, FaPaperPlane, FaInfoCircle } from 'react-icons/fa';
import Api from '../../services/api';

const ProjectUsers = ({ projectId }) => {
  const { t } = useTranslation();
  const [users, setUsers] = useState([]);

  // Asynchronous retrievers
  const getUsersAndRoles = useCallback(async () => ({
    users: await Api().projects.users({ projectId }).get(),
  }), [projectId]);

  const removeUserFromProject = async (user) => {
    await Api().projects.users({ projectId }).delete(user.id);
    const { users } = await getUsersAndRoles();
    setUsers(users);
  };

  const popover = (
    <Popover id="popover-basic">
      <Popover.Title as="h3">
        {' '}
        {t('element-info-title')}
      </Popover.Title>
      <Popover.Content>
        <Container>
          <Row>
            {t('Voit kutsua käyttäjiä muokkaamaan tai tarkastelemaan laskentaa.')}
          </Row>

          <Row>
            {t('Project Admin Ylläpito-oikeudet.')}
          </Row>
          <Row>
            {t('Project User Oikeus muokata laskentaa.')}
          </Row>
          <Row>
            {t('Project Reader Oikeus tarkastella laskentaa.')}
          </Row>

        </Container>
      </Popover.Content>
    </Popover>
  );

  // Side-effects
  useEffect(() => {
    let isMounted = true;
    if (projectId > 0) {
      getUsersAndRoles().then(({ users, roles }) => isMounted && setUsers(users));
    }
    return () => { isMounted = false; }; // cleanup
  }, [getUsersAndRoles, projectId]);

  return (
    <div className="card-background">
      <Row>
        <Col>
          <h2>
            {t('Users')}
            <OverlayTrigger trigger="click" placement="right" overlay={popover}>
              <FaInfoCircle />
            </OverlayTrigger>
          </h2>
        </Col>
      </Row>
      <Table>
        <thead>
          <tr>
            <th>{t('Name')}</th>
            <th>{t('Email')}</th>
            <th>{t('Role')}</th>
            <th>{t('')}</th>
          </tr>
        </thead>
        <tbody>
          {users.map((u) => (
            <tr key={u.id}>
              <td>{u.name}</td>
              <td>{u.email}</td>
              <td>{u.roles.map((r) => t(r.name)).join(', ')}</td>
              <td className="text-right">
                <Button variant="outline-danger" onClick={() => { removeUserFromProject(u); }}>
                  <FaTrash />
                  {' '}
                  {t('Remove')}
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};
export default ProjectUsers;
