import React, {
  Fragment, useState, useEffect, useCallback, useContext, useMemo,
} from 'react';
import { Card } from 'react-bootstrap';
import { TryNumber } from '../common/helpers';
import loglevel from '../../services/loglevel';
import Api from '../../services/api';
import { HomeContext } from './HomeContext';
import { withSettingsStore } from '../common/SettingsContext';
import WidgetAccessibility from './WidgetAccessbility';
import WidgetLivcy from './WidgetLivcy';
import WidgetIntensity from './WidgetIntensity';

const Widgets = withSettingsStore(({ mapId, isLoggedIn, isInRole }) => {
  const [widgets, setWidgets] = useState([]);
  const {
    currentMap,
    currentLayer,
    currentFeatures,
    currentAttributeGroup,
    isLoadingFeatures,
    availableAttributeGroups,
    selectedFeatureIds,
  } = useContext(HomeContext);

  const widgetsWithData = useMemo(() => {
    if (currentMap === null || currentLayer === null || currentFeatures.length === 0 || currentAttributeGroup === null) {
      return [];
    }

    loglevel.info('Widget data update', currentFeatures, isLoadingFeatures, currentMap.attributeGroups);

    const dataPerWidget = widgets.map((widget) => {
      const attributeGroups = currentMap.attributeGroups.filter((ag) => ag.id === widget.attributeGroupId);
      if (attributeGroups.length === 0) {
        return undefined;
      }

      const attributeGroup = attributeGroups[0];

      if (attributeGroup !== undefined) {
        const attributes = currentLayer.attributes.sort((a, b) => a.order - b.order).filter((a) => a.attributeGroupId === currentAttributeGroup.id);
        // const indices = attributes.map(a => features[0].findIndex(f => f === a.code))

        const attributesWithChildren = attributes.filter((a) => a.parentId === null).reduce((acc, cur) => ([...acc, cur, ...attributes.filter((a) => a.parentId === cur.id)]), []);

        let data = [];
        if (!isLoadingFeatures) {
          data = currentFeatures.filter((f) => selectedFeatureIds.includes(f.id)).map((f) => f.values.reduce((acc, cur) => ({ ...acc, [cur.attributeCode]: TryNumber(cur.value) }), {}));
        }

        return {
          ...widget,
          enabled: availableAttributeGroups.map((ag) => ag.id).includes(widget.attributeGroupId),
          locked: !isLoggedIn || !isInRole(['User', 'Admin'], currentMap.projectId),
          data: {
            attributes,
            attributesWithChildren,
            attributeGroup,
            data,
            updated: new Date(),
          },
        };
      }
      return undefined;
    }).filter((w) => w !== undefined);

    return dataPerWidget;
  }, [availableAttributeGroups, currentAttributeGroup, currentFeatures, currentLayer, currentMap, isLoadingFeatures, isLoggedIn, widgets, isInRole, selectedFeatureIds]);

  const retrieveWidgets = useCallback(async () => Api().maps.widget(mapId).get({}), [mapId]);

  useEffect(() => {
    let isMounted = true;
    retrieveWidgets().then((w) => isMounted && setWidgets(w));
    return () => { isMounted = false; };
  }, [mapId, retrieveWidgets]);

  return (
    <div style={{ overflowY: 'auto' }}>
      {widgetsWithData.sort((a, b) => a.type - b.type).map((widget) => (
        <Fragment key={widget.id}>
          {widget.type === 1 && widget.data !== undefined && <WidgetLivcy key={widget.updated} widget={widget} data={widget.data} />}
          {widget.type === 2 && widget.data !== undefined && <WidgetAccessibility key={widget.updated} widget={widget} data={widget.data} />}
          {widget.type === 3 && widget.data !== undefined && <WidgetIntensity key={widget.updated} widget={widget} data={widget.data} />}
          {widget.type > 3 && (
          <Card className="mb-2">
            <Card.Title>{widget.name}</Card.Title>
            <Card.Body>
              Type:
              {widget.type}
            </Card.Body>
          </Card>
          )}
        </Fragment>
      ))}
    </div>
  );
});
export default Widgets;
