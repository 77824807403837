import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { HomeContext } from './HomeContext';

import './CustomRadio.scss';

const CustomRadio = (props) => {
  const {
    currentAttribute,
  } = useContext(HomeContext);

  const {
    name,
    item,
    attributeIdx,
    handleChange,
    children,
  } = props;

  return (
    <>
      <input
        type="radio"
        className="customRadio"
        name={name}
        id={item.code}
        checked={currentAttribute?.code === item.code}
        onChange={() => handleChange(attributeIdx)}
      />
      <label
        htmlFor={item.code}
        className="customRadio__label"
      >
        {children}
      </label>
    </>
  );
};

CustomRadio.propTypes = {
  name: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
  attributeIdx: PropTypes.any.isRequired,
  handleChange: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default CustomRadio;
