import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Button, Alert } from 'react-bootstrap';
import Api from '../../services/api';
import TokenManager from '../../services/token-manager';
import loglevel from '../../services/loglevel';
import RegisterModal from '../../components/RegisterModal';
import LoginModal from '../../components/LoginModal';
import ForgotPasswordModal from '../../components/ForgotPasswordModal';
import EulaModal from '../../components/EulaModal';

const SettingsContext = React.createContext();
const tokenManager = new TokenManager(Api());

const SettingsStore = ({ children }) => {
  const { t } = useTranslation();
  const [error, setError] = useState({
    title: t('Error'),
    text: '',
  });
  const [showError, setShowError] = useState(false);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showEulaModal, setShowEulaModal] = useState(false);
  const [loginModalMessage, setLoginModalMessage] = useState('');

  const displayError = useCallback((text, title) => {
    if (title === undefined) {
      title = t('Error');
    }
    setError({ title, text });
    setShowError(true);
  }, [t]);

  const displayForgotPasswordModal = useCallback(() => {
    setShowForgotPasswordModal(true);
  }, [setShowForgotPasswordModal]);

  const displayRegisterModal = useCallback(() => {
    setShowRegisterModal(true);
  }, [setShowRegisterModal]);

  const displayLoginModal = useCallback((msg) => {
    setLoginModalMessage(msg === undefined ? '' : msg);
    setShowLoginModal(true);
  }, [setShowLoginModal]);

  const isInRole = useCallback((roles) => (role, projectId = 0) => {
    let found = false;
    if (Array.isArray(role)) { // if roles are given as array => any of the roles is success
      found = roles.find((r) => role.includes(r.name) && r.projectId === Number(projectId));
    } else {
      found = roles.find((r) => r.name === role && r.projectId === Number(projectId));
    }

    if (found === undefined) {
      return false;
    }
    return true;
  }, []);

  const [settings, setSettings] = useState({
    user: {},
    currentRoles: [],
    isInRole: () => false,
    displayLoginModal,
    displayRegisterModal,
    displayError,
    displayForgotPasswordModal,
    refreshSettings: () => false,
    login: () => false,
    logout: () => false,
    isAdmin: () => false,
  });

  const getSettings = useCallback(async () => {
    loglevel.info('retrieving settings...');
    const app = await Api().settings.app();
    const user = await Api().accounts.me.get();
    const currentRoles = await Api().accounts.me.roles();

    return {
      app,
      user,
      currentRoles,
      isLoggedIn: user.id > 0,
      isInRole: isInRole(currentRoles),
      displayRegisterModal,
      displayLoginModal,
      displayError,
      displayForgotPasswordModal,
      isAdmin: user.isAdmin,
    };
  }, [displayError, displayForgotPasswordModal, displayLoginModal, displayRegisterModal, isInRole]);

  const refreshSettings = useCallback(async () => {
    getSettings().then((s) => setSettings((prev) => ({ ...prev, ...s })));
  }, [getSettings]);

  const handleLogin = async (username, password) => {
    await login(username, password);
    setShowLoginModal(false);
    refreshSettings();
  };

  const handleRegister = async (user) => {
    await login(user.username, user.password);
    setShowRegisterModal(false);
    refreshSettings();
  };

  const login = useCallback(async (username, password, accessToken, returnUrl) => {
    console.log('login');
    try {
      const response = await Api().accounts.login({ email: username, password, accessToken });
      const { token, expiration } = response;
      localStorage.setItem('token', token);
      localStorage.setItem('tokenExpires', expiration);
      tokenManager.start((/* error */) => {
        console.log('token manager failed to start');
      });
      refreshSettings();
      if (returnUrl !== undefined) {
        window.location = `/${returnUrl}`;
      } else {
        window.location = '/home';
      }
    } catch (e) {
      const resMessage = (e.response
                    && e.response.data
                    && e.response.data.message)
                || e.message
                || e.toString();
      throw new Error(resMessage);
    }
  }, [refreshSettings]);

  const acceptEula = async () => {
    await Api().accounts.me.update({ ...settings.user, eulaAcceptedVersion: 1 });
    setShowEulaModal(false);
  };

  const logout = useCallback(async () => {
    console.log('logout');
    await Api().accounts.logout();
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('tokenExpires');
    refreshSettings();
    window.location = '/login';
  }, [refreshSettings]);

  useEffect(() => {
    // units/codes/user
    let isMounted = true;
    getSettings().then((s) => {
      if (isMounted) {
        setSettings((prev) => ({
          ...prev, ...s, refreshSettings, logout, login,
        }));
        if (s.user.id > 0) {
          tokenManager.start(() => {
            loglevel.info('Failed to start');
          });
          if (s.user.eulaAcceptedVersion < 1) {
            setShowEulaModal(true);
          }
        }
      }
    });
    return () => { isMounted = false; tokenManager.stop(); };
  }, [getSettings, login, logout, refreshSettings]);

  return (
    <>
      <Modal show={showError} onHide={() => { setShowError(false); }}>
        <Modal.Header closeButton>
          <Modal.Title>{error.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error.text}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowError(false)}>{t('Close')}</Button>
        </Modal.Footer>
      </Modal>

      <RegisterModal show={showRegisterModal} onRegister={handleRegister} onHide={() => { setShowRegisterModal(false); }} />
      <LoginModal message={loginModalMessage} show={showLoginModal} onLogin={handleLogin} onHide={() => { setShowLoginModal(false); }} />
      <ForgotPasswordModal show={showForgotPasswordModal} onHide={() => { setShowForgotPasswordModal(false); }} />
      <EulaModal show={showEulaModal} onReject={() => logout()} onAccept={() => { acceptEula(); }} />

      <SettingsContext.Provider value={settings}>
        {children}
      </SettingsContext.Provider>
    </>
  );
};

function withSettingsStore(Component) {
  return function ConnectedComponent(props) {
    return (
      <SettingsContext.Consumer>
        {({
          app,
          user,
          isInRole,
          isLoggedIn,
          currentRoles,
          displayError,
          displayRegisterModal,
          displayLoginModal,
          displayForgotPasswordModal,
          refreshSettings,
          login,
          logout,
          isAdmin,
        }) => (
          <Component
            {...props}
            app={app}
            user={user}
            currentRoles={currentRoles}
            isInRole={isInRole}
            isLoggedIn={isLoggedIn}
            displayError={displayError}
            displayRegisterModal={displayRegisterModal}
            displayLoginModal={displayLoginModal}
            displayForgotPasswordModal={displayForgotPasswordModal}
            refreshSettings={refreshSettings}
            login={login}
            logout={logout}
            isAdmin={isAdmin}
          />
        )}
      </SettingsContext.Consumer>
    );
  };
}

export { SettingsContext, SettingsStore, withSettingsStore };
