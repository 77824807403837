import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Form,
} from 'react-bootstrap';

const AccountDialog = (props) => {
  const { t } = useTranslation();

  const [user, setUser] = useState({
    ...props,
    name: '',
    id: -1,
    username: '',
    telephone: '',
    company: '',
    address: '',
    isAdmin: false,
    roles: [],
    email: '',
  });

  useEffect(() => {
    setUser(props.user, [props.user]);
  }, [props.user]);

  const handleChange = (event) => {
    user[event.target.name] = event.target.value;
    setUser(user);

    props.onChange(user);
  };

  return (
    <Form>
      <Form.Group controlId="formName">
        <Form.Label>{t('user.name')}</Form.Label>
        <Form.Control name="name" value={user.name} type="text" placeholder={t('Enter name')} onChange={handleChange} />
      </Form.Group>
      <Form.Group controlId="formEmail">
        <Form.Label>{t('user.e-mail')}</Form.Label>
        <Form.Control name="email" value={user.email} type="email" placeholder={t('Enter email')} onChange={handleChange} />
      </Form.Group>
      <Form.Group controlId="formCompany">
        <Form.Label>{t('user.company')}</Form.Label>
        <Form.Control name="company" value={user.company} type="text" placeholder={t('Enter company')} onChange={handleChange} />
      </Form.Group>
      <Form.Group controlId="formAddress">
        <Form.Label>{t('user.address')}</Form.Label>
        <Form.Control name="address" value={user.address} type="text" placeholder={t('Enter address')} onChange={handleChange} />
      </Form.Group>
      <Form.Group controlId="formPhone">
        <Form.Label>{t('user.phone')}</Form.Label>
        <Form.Control name="phone" value={user.telephone} type="text" placeholder={t('Enter phone number')} onChange={handleChange} />
      </Form.Group>
    </Form>
  );
};

export default AccountDialog;
