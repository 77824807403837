import React from 'react';
import {
  Container,
  Row,
  Col,
} from 'react-bootstrap';

const About = () => (
  <Container className="mt-5 mb-5">
    <Row>
      <Col md={{ offset: 2, span: 8 }}>
        <h1>LIVCY by Ramboll -data-alusta alueen elävyyden analysointiin </h1>

        <p className="lead">LIVCY on Rambollin monialaisen asiantuntijaryhmän kehittämä elävyysindeksi, palvelu sekä digitaalinen sovellus rakennetun ympäristön elävyyden mittaamiseen, kehitykseen ja seuraamiseen. LIVCY tarkastelee elävyyttä liikkumisen, kaupunkirakenteen sekä toimintojen kautta.</p>

        <h2 className="h4">Keskiössä LIVCY-elävyysindeksi</h2>
        <p>Keskiössä on LIVCY-elävyysindeksi, joka kokoaa yhteen elävyyden eri ominaisuudet kuuden kategorian mukaan</p>

        <ul>
          <li>Kauppa ja palvelut</li>
          <li>Koulutus- ja terveyspalvelut</li>
          <li>Liikenne ja liikkuminen</li>
          <li>Viheralueet</li>
          <li>Liikuntapalvelut</li>
          <li>Kulttuuri</li>
        </ul>

        <p>Elävyysindeksi perustuu palveluiden ja toimintojen saavutettavuuden ja intensiteetin (palvelutiheyden) yhteisvaikutukseen suhteessa alueen väkilukuun. LIVCY-elävyysindeksistä näet alueen kokonaispisteet. Saavutettavuutta (kävelymatka palveluihin minuuteissa tunnettua tieverkkoa pitkin) voit tarkastella myös yksityiskohtaisemmin eri kategorioissa. Lisäksi voit tarkastella väestö- ja työpaikkapotentiaalia alueella, jotta voit analysoida, kuinka moni tavoittaa alueen palvelut tai työpaikat.</p>

        <h2 className="h4">LIVCY-sovellus kuvaa seuraavia asioita</h2>
        <ul>
          <li>LIVCY-elävyysindeksi; Rambollin asiantuntijoiden kehittämä ja tutkimustietoon perustuva elävyyden koonti-indeksi, joka mittaa elävyyttä kuuden eri kategorian mukaan (arvot 0-100, jossa 100 on paras mahdollinen elävyysarvo).</li>
          <li>Palveluiden ja toimintojen saavutettavuutta kävellen minuutteina </li>
          <li>Palveluiden ja toimintojen sekä tapahtumien intensiteettiä eli tiheyttä (mukana LIVCY-indeksin laskennassa).</li>
          <li>Perusdemografia-tietoja alueesta (väestö- ja työpaikkapotentiaali).</li>
        </ul>

        <p>LIVCY-sovelluksesta voit tarkastella alueiden elävyyttä kunta-, postinumeroalue- tai ruututasolla (ruudut 250 m x 250 m).</p>

        <p>LIVCY-sovellus on osa LIVCY by Ramboll -työkalupakkia, jonka sisältö määritellään ja räätälöidään asiakkaan tarpeiden mukaan. Palveluun voidaan yhdistää myös asukkaiden käyttäytymisen ja kokemuksien taso tai laadukkaan rakennetun ympäristön mittareita erilaisin lisämoduulein. Työkalupakki voi pitää sisällään elävyysraportin, työpajoja, kyselyitä, haastatteluja tai muita elävyydestä lisätietoja antavia työkaluja ja prosesseja. Lue lisää LIVCY by Ramboll -verkkosivuilla.</p>
      </Col>
    </Row>
  </Container>
);

export default About;
