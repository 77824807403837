import React, {
  useState, useEffect, useContext, useMemo, useCallback,
} from 'react';

import GeoJSON from 'ol/format/GeoJSON';
import OSM from 'ol/source/OSM';
import BingMaps from 'ol/source/BingMaps';
import Vector from 'ol/source/Vector';
import * as proj from 'ol/proj';
import proj4 from 'proj4';

import {
  Fill, Stroke, Style, Text,
} from 'ol/style';
import {
  Card,
  ListGroup,
  Spinner,
  Button,
  ButtonGroup,
  Badge,
  OverlayTrigger,
  Tooltip,
  Row, Col,
} from 'react-bootstrap';
import {
  FaPlus, FaMinus, FaLock, FaCircle,
} from 'react-icons/fa';
import { withSettingsStore } from '../common/SettingsContext';
import {
  OpenLayersMap, TileLayer, VectorLayer, Layers,
} from '../../components/OpenLayersMap';
import loglevel from '../../services/loglevel';

import './MapView.scss';
import { MapContext } from './MapContext';
import { CreateDynamicLegend, IsPointWithinExtent } from '../common/helpers';
import { useTranslation } from 'react-i18next';

const DEFAULT_ATTRIBUTECODE = 'livcy_i';

const MapView = withSettingsStore(({
  isLoggedIn, displayLoginModal, children, readonly, attributeCode, legendPosition, showZoom = true, showNumbers = true, automaticLayerSelection: _automaticLayerSelection = false,
  onSelectFeatures, onSelectLayer, onSelectAttribute, selectFeatureByDefault, useBingMaps = false,
}) => {
  const { t } = useTranslation();
  const {
    map,
    availableLayers,
    availableAttributes,
    availableLegends,
    toggleLayer,
    selectedLayer,
    selectedAttributeGroup,
    selectedCenter,
    selectedExtent,
    setSelectedExtent,
    getLayerData,
    isLoadingFeatures,
    filters,
  } = useContext(MapContext);

  const padding = '10px';
  const [legend, setLegend] = useState(null);
  const [visibleLayers, setVisibleLayers] = useState([]);
  const [selectedMapFeatures, setSelectedMapFeatures] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [initialExtent, setInitialExtent] = useState(null);
  const [isLoadingLayer, setIsLoadingLayer] = useState(0);
  const [zoom, setZoom] = useState(9);
  const [center, setCenter] = useState([0, 0]);
  const [selectedAttribute, setSelectedAttribute] = useState(null);
  const [currentLayerData, setCurrentLayerData] = useState(null);
  const [automaticLayerSelection, setAutomaticLayerSelection] = useState(_automaticLayerSelection);

  useEffect(() => {
    // Center changes
    if (selectedCenter[0] === 0) {
      return;
    }
    // const coords = proj.transform(selectedCenter, 'EPSG:3067', 'EPSG:3857');
    setCenter(selectedCenter);
  }, [selectedCenter]);

  useEffect(() => {
    if (map !== null && initialExtent === null) {
      loglevel.info('Setting initial extent from map', map);
      setInitialExtent(map.extent);
    }
  }, [map, initialExtent]);

  const selectedLegend = useMemo(() => {
    if (selectedAttribute === null) {
      return null;
    }
    loglevel.info('Selected attribute', selectedAttribute, availableLegends);
    const found = availableLegends.find((l) => l.attributeGroupId === selectedAttribute.attributeGroupId);
    loglevel.info('found', found);
    return found !== undefined ? found : null;
  }, [selectedAttribute]);

  useEffect(() => {
    if (selectedAttributeGroup === null) {
      return;
    }
    const isAttributeValid = availableAttributes.find((a) => a.code === attributeCode && a.attributeGroupId === selectedAttributeGroup.id) !== undefined;
    if (attributeCode === undefined || !isAttributeValid) {
      if (selectedAttributeGroup !== null && selectedAttributeGroup.defaultAttributeCode !== null && selectedAttributeGroup.defaultAttributeCode !== '') {
        attributeCode = selectedAttributeGroup.defaultAttributeCode;
      } else {
        attributeCode = DEFAULT_ATTRIBUTECODE;
      }
    }
    const attribute = availableAttributes.find((a) => a.code === attributeCode);
    if (attribute !== undefined) {
      setSelectedAttribute({ ...attribute });
      loglevel.info('selected attribute', attribute);
    }
  }, [availableAttributes, attributeCode, selectedAttributeGroup]);

  useEffect(() => {
    if (typeof onSelectAttribute === 'function') {
      onSelectAttribute(selectedAttribute);
    }
  }, [selectedAttribute]);

  const layerDataToGeoJSON = (layerData, attribute) => ({
    type: 'FeatureCollection',
    features: layerData.features.map((g) => ({
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [...g.coordinates],
      },
      properties: { [attribute.code]: g.values[attribute.code], featureId: g.id },
    })),
  });

  const getCurrentLayer = useCallback(() => selectedLayer, [selectedLayer]);

  useEffect(async () => {
    // Retrieve feature data
    if (selectedLayer === null || selectedAttribute === null) {
      loglevel.info('No loading triggered', selectedLayer, selectedAttribute);
      return;
    }

    try {
      const layerData = await getLayerData({
        layerId: selectedLayer.id,
        attribute: selectedAttribute,
      });

      if (layerData === undefined) {
        loglevel.warn('Layerdata is not set');
        return;
      }
      if (getCurrentLayer().id !== layerData.id) {
        loglevel.warn('Layer has changed during load!');
        return;
      }

      /* if (currentLayerData !== null && currentLayerData.timestamp === layerData.timestamp) {
        // Nothing changed
        loglevel.info('Timestamp not changed. No need to do anything');
        return;
      } */

      setCurrentLayerData(layerData);

      // Select default feature if not selected
      const isSelectedMapFeatureValid = selectedMapFeatures.length > 0 && layerData.features.find((f) => f.id === selectedMapFeatures[0]) !== undefined;
      if (selectedExtent !== null && (selectedMapFeatures.length === 0 && selectedAttribute !== undefined && selectFeatureByDefault !== undefined)) {
        const features = layerData.features.filter((f) => f.coordinates.find((cs) => cs.find((c) => IsPointWithinExtent(c, selectedExtent)) !== undefined));
        //     layerData.features.filter((f) => f.coordinates.find((cs) => loglevel.info(c, IsPointWithinExtent(c, selectedExtent))));
        const featureValues = features.map((f) => f.values.livcy_i);
        const maxValue = Math.max(...featureValues);
        const featureIdx = featureValues.findIndex((v) => v === maxValue);
        const feature = features[featureIdx];
        loglevel.info('Selecting feature by default:', feature);
        if (feature !== undefined) {
          setSelectedMapFeatures([feature.id]);
        }
      }

      if (selectedAttribute === undefined) {
        loglevel.warn('No selected attribute');
        return;
      }

      const vectorSource = new Vector({
        features: new GeoJSON().readFeatures(layerDataToGeoJSON(layerData, selectedAttribute), {
          dataProjection: proj.get('EPSG:3067'),
          featureProjection: proj.get('EPSG:3067'),
        }),
      });

      setVisibleLayers([
        { ...selectedLayer, vectorSource },
      ]);
    } catch (e) {
      loglevel.info('queries cancelled', e);
    }
  }, [selectedAttribute, selectedLayer, selectedExtent]);

  const getNextThreshold = (legend, step) => {
    const nextStep = legend.steps.find((s) => s.threshold > step.threshold);
    if (nextStep === undefined) {
      return '';
    }
    return nextStep.threshold;
  };
  const isDynamicLegend = (layerLegend) => layerLegend.steps.length >= 2
    && layerLegend.steps.map((s) => s.threshold).every((s, i, a) => a[0] === s);

  useEffect(() => {
    loglevel.info('Updating legend values...', selectedLegend);
    if (selectedLegend !== null) {
      if (isDynamicLegend(selectedLegend)) {
        if (currentLayerData === null) {
          loglevel.warn('No data');
          return;
        }
        loglevel.warn('Dynamic legends are unsupported!', currentLayerData, selectedAttribute);
        const legend = CreateDynamicLegend({
          values: currentLayerData.features.map((f) => f.values[selectedAttribute.code]),
          numSteps: selectedLegend.steps[0].threshold,
          stops: selectedLegend.steps.map((s) => s.color),
          name: selectedLegend.name,
        });
        loglevel.info(selectedLegend);
        loglevel.info({
          ...selectedLegend,
          ...legend,
        });
        setLegend({
          ...selectedLegend,
          ...legend,
        });
      } else {
        setLegend(selectedLegend);
      }
    }
  }, [selectedLegend, currentLayerData]);

  const mapId = useMemo(() => {
    if (map === null) {
      return null;
    }
    return map.id;
  }, [map]);

  const isLayerVisible = (layerId) => {
    if (visibleLayers.find((l) => l.id === layerId) !== undefined) {
      return true;
    }
    return false;
  };
  const selectLayer = async (layerId) => {
    await toggleLayer({ layerId, enabled: true });
  };
  useEffect(() => {
    if (selectedLayer !== null) {
      if (typeof onSelectLayer === 'function') {
        onSelectLayer(selectedLayer.id);
      }
    }
  }, [selectedLayer]);

  useEffect(() => {
    if (selectedMapFeatures.length > 0 && typeof onSelectFeatures === 'function' && selectedLayer !== null) {
      onSelectFeatures({ ids: selectedMapFeatures, layerId: selectedLayer.id });
    }
  }, [selectedMapFeatures]);

  const currentVisibleLayers = useMemo(() => {
    // Without legend, there is no reason to list layers
    // Legends are used to colorize the layer data
    if (legend === null) {
      loglevel.warn('Visible layers: no legend yet');
      return [];
    }
    const legendSteps = legend.steps.sort((a, b) => a.threshold - b.threshold);
    const emptyStyle = new Style({
      stroke: new Stroke({
        color: '#00000000',
        width: 0,
      }),
      fill: new Fill({
        color: '#00000000',
      }),
    });
    const selectionStyle = new Style({
      stroke: new Stroke({
        color: '#05326E',
        width: 4,
      }),
      /* fill: new Fill({
        color: '#FF006677',
      }), */
    });
    selectionStyle.setZIndex(1);

    const legendStyles = legendSteps.map((s) => ({
      step: s,
      stroke: new Stroke({
        // color: s.color,
        color: '#FFFFFF',
        width: 1,
      }),
      fill: new Fill({
        color: `${s.color}99`,
      }),
    }));

    const isSelected = (featureId) => selectedMapFeatures.includes(Number(featureId));
    const getFeatureId = (olProperties) => ('featureId' in olProperties ? Number(olProperties.featureId) : 0);
    const getFeatureValue = (olProperties, code) => (code in olProperties ? Number(olProperties[code]) : -1);

    loglevel.info('updating visible layers...');
    return visibleLayers.map((l) => ({
      ...l,
      // extent: filters ? null : [...proj4('EPSG:3067', 'EPSG:3857', [l.extent[0], l.extent[1]]), ...proj4('EPSG:3067', 'EPSG:3857', [l.extent[2], l.extent[3]])],
      /* extent: filters ? null : [
        ...proj4('EPSG:3067', 'EPSG:3067', [map.extent[0], map.extent[1]]),
        ...proj4('EPSG:3067', 'EPSG:3067', [map.extent[2], map.extent[3]]),
      ], */
      key: l.id,
      vectorSource: l.vectorSource,
      style: (feature, resolution) => {
        // TypeError: can't access property "code", currentAttribute is null
        if (selectedAttribute === null) {
          return emptyStyle;
        }

        const properties = feature.getProperties();
        const value = getFeatureValue(properties, selectedAttribute.code);
        const featureId = getFeatureId(properties);

        let currentStepIdx = -1;
        // TODO retrieve / update view with data provided outside

        for (let i = 0; i < legendSteps.length; i++) {
          const step = legendSteps[i];
          if (value >= step.threshold) {
            currentStepIdx = i;
          }
        }

        if (currentStepIdx === -1) {
          // loglevel.warn('No step found!', value, properties, currentAttribute)
          return emptyStyle;
        }
        const { stroke, fill } = legendStyles[currentStepIdx];

        const text = new Text({
          text: value.toFixed(0),
          font: '12pt sans-serif',
          color: '#05326E',
        });

        if (isSelected(featureId)) {
          if (l.name !== 'Ruudut') {
            selectionStyle.setText(text);
          }
          selectionStyle.setFill(fill);
          return selectionStyle;
        }

        // HACK Ruudut layer uses fill and stroke only
        if (l.name === 'Ruudut') {
          return new Style({
            fill,
            stroke,
          });
        }

        return new Style({
          stroke,
          text: (showNumbers && resolution < 500) && text,
          fill,
        });
      },
    }));
  }, [legend, selectedMapFeatures, visibleLayers, selectedAttribute]);

  const handleMapMove = (e) => {
    const { zoom, extent } = e;
    loglevel.info(extent);
    if (extent !== null && extent.length > 0) {
      // project points back to original
      // const projectedExtent = proj.transformExtent(extent, 'EPSG:3857', 'EPSG:3067');
      // Expand Y axis

      /* const offset = Math.min(...projectedExtent);
      const yMargin = Math.abs(projectedExtent[3] - projectedExtent[1]) * 0.20;
      projectedExtent = projectedExtent.map((p) => p + offset);
      projectedExtent[1] -= yMargin;
      projectedExtent[3] += yMargin;
      projectedExtent = projectedExtent.map((p) => p - offset); */

      // Expand X axis
      // projectedExtent[2] = projectedExtent[2] - 5000;
      // projectedExtent[4] = projectedExtent[4] + 5000;

      const projectedExtent = extent;

      loglevel.info('projected', projectedExtent);
      setSelectedExtent(projectedExtent);
    }
  };

  const selectedExtentInLocalCoordinateSystem = useMemo(() => {
    if (selectedExtent === null) {
      return null;
    }
    const extent = selectedExtent;
    // return [...proj4('EPSG:3067', 'EPSG:3857', [extent[0], extent[1]]), ...proj4('EPSG:3067', 'EPSG:3857', [extent[2], extent[3]])];
    return extent;
  }, [selectedExtent]);

  const handleLegendChange = (legend, step) => (e) => {
    let { value } = e.currentTarget;
    value = Number(value);

    if (!isNaN(value)) {
      if (legend !== undefined) {
        const st = legend.steps.find((s) => s === step);
        if (st !== undefined) {
          st.threshold = value;
          setLegend({ ...legend });
        }
      }
    }
  };
  useEffect(() => {
    if (!isLoggedIn || selectedLayer === null) {
      return;
    }

    // Regardless if automatic layer selection is enabled.
    // Make sure Ruudut layer is not selected if zoom level is less than 13

    if (automaticLayerSelection) {
      if (zoom > 12) {
        const layer = availableLayers.find((l) => l.name === 'Ruudut' && l.available);
        if (layer !== undefined) {
          selectLayer(layer.id);
        }
      }
      if (zoom > 10 && zoom < 12) {
        const layer = availableLayers.find((l) => l.name === 'Postinumerot' && l.available);

        if (layer !== undefined) {
          selectLayer(layer.id);
        }
      }
      if (zoom < 10) {
        const layer = availableLayers.find((l) => l.name === 'Kunnat' && l.available);

        if (layer !== undefined) {
          selectLayer(layer.id);
        }
      }
    } else {
      const layer = availableLayers.find((l) => l.name === 'Ruudut' && l.available);
      if (selectedLayer.id === layer.id && zoom < 12) {
        const targetLayer = availableLayers.find((l) => l.name === 'Postinumerot' && l.available);
        if (targetLayer !== undefined) {
          selectLayer(targetLayer.id);
        }
      }
    }
  }, [zoom, automaticLayerSelection]);
  // Bing Maps options
  const key = process.env.REACT_APP_BING_API_KEY;
  const customStyle = 'water|fillColor:EEEEEE;labelColor:999999_road|fillColor:EEEEEE_global|landColor:FFFFFF';
  const bingSource = {
    key: `${key}&st=${customStyle}`,
    imagerySet: 'CanvasGray',
  };

  return (
    <div className="mapview-container">
      {legend !== null && legendPosition === 'top' && (
        <div className="legend-header">
          <div className="title">
            <strong>{legend.name}</strong>
            {selectedAttribute !== null && <div>{selectedAttribute.name}</div>}
          </div>
          <div className="steps">
            {legend.steps.sort((a, b) => a.threshold - b.threshold).map((s, idx) => (
              <span className="legend-inline">
                <FaCircle style={{ color: s.color }} className="mr-2" />
                {s.threshold}
                –
                {getNextThreshold(legend, s)}
                {s.description}
              </span>
            ))}
          </div>
        </div>
      )}
      <div className="map-container position-relative">
        <OpenLayersMap
          zoom={zoom}
          center={center}
          extent={initialExtent}
          onZoom={(z) => setZoom(z)}
          onMove={handleMapMove}
          onClick={(fs) => setSelectedMapFeatures(
            [...fs]
              .map((feature) => Number(feature.values_.featureId)),
          )}
          multi={false}
        >
          <Layers>
            {useBingMaps
              ? <TileLayer source={new BingMaps(bingSource)} zIndex={0} />
              : <TileLayer source={new OSM()} zIndex={0} isGrayscale /> }
            {currentVisibleLayers.map((layer, idx) => (
              <VectorLayer
                key={layer.name}
                source={layer.vectorSource}
                style={layer.style}
                zIndex={idx + 1}
              />
            ))}
          </Layers>
        </OpenLayersMap>
        {!readonly && (
          <div
            className="position-absolute d-flex justify-content-start"
            style={{
              top: 0, left: 0, pointerEvents: 'none', height: '100%', paddingBottom: 0,
            }}
          >
            {children !== undefined && (
              <div className="mr-2">
                {children}
              </div>
            )}
            <div className="mt-2 mr-2 position-relative">
              <Card style={{ pointerEvents: 'auto' }}>
                <ListGroup>
                  {availableLayers.sort((a, b) => a.id - b.id).map((l) => (
                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Zoomaa lähemmäs, että voit näyttää ruututason.</Tooltip>} key={l.id} placement="right">
                      <ListGroup.Item className="dense">
                        {l.available
                          ? (
                            <input
                              type="radio"
                              name="layer"
                              className="mr-2"
                              disabled={!l.selectable || (l.name === 'Ruudut' && zoom <= 12)}
                              checked={selectedLayer !== null && selectedLayer.id === l.id}
                              onChange={() => { /* Nothing to do */ }}
                              onClick={() => {
                                setAutomaticLayerSelection(false);
                                selectLayer(l.id);
                              }}
                            />
                          )
                          : <FaLock size="16" className="mr-1" onClick={() => displayLoginModal(t('login.insufficient-permissions', { email: 'livcy@ramboll.fi' }))} />}
                        {l.name}
                        {isLoadingLayer === l.id && (
                          <Spinner animation="border" role="status" size="sm" className="ml-2">
                            <span className="sr-only">Ladataan...</span>
                          </Spinner>
                        )}
                      </ListGroup.Item>
                    </OverlayTrigger>
                  ))}
                  {
                  /*
                  <ListGroup.Item className="dense">
                    <input type="checkbox" checked={automaticLayerSelection} onClick={() => setAutomaticLayerSelection((s) => !s)} className="mr-2" />
                    Automaattinen
                  </ListGroup.Item>
                  */
                  }
                </ListGroup>
              </Card>
              {isLoadingFeatures && (
                <Card className="loading-indicator">
                  <div className="d-flex">
                    <div className="flex-shrink-1 mr-2">
                      <Spinner animation="border" size="sm" />
                    </div>
                    <div>
                      <span>Ladataan...</span>
                    </div>
                  </div>
                </Card>
              )}
              <div className="position-absolute" style={{ bottom: 0, left: 0 }}>
                {selectedAttributeGroup !== null && (
                  <Badge className="mr-1" variant="primary">
                    {selectedAttributeGroup.name}
                  </Badge>
                )}
                {selectedAttribute !== null && (
                  <Badge variant="primary">
                    {selectedAttribute.name}
                  </Badge>
                )}
              </div>
            </div>
          </div>
        )}
        {showZoom && (
        <ButtonGroup vertical className="position-absolute" style={{ top: padding, right: padding }} size="sm">
          <Button onClick={() => setZoom((z) => z + 1)} variant="primary"><FaPlus /></Button>
          <Button onClick={() => setZoom((z) => z - 1)} variant="primary"><FaMinus /></Button>
        </ButtonGroup>
        )}
        {legend !== null && legendPosition === undefined && (
        <Card className="position-absolute" style={{ bottom: padding, right: padding }}>
          <ListGroup>
            <ListGroup.Item className="dense">
              {legend.name}
              {selectedAttribute !== null && <div>{selectedAttribute.name}</div>}
            </ListGroup.Item>
            {legend.steps.sort((a, b) => a.threshold - b.threshold).map((s, idx) => (
              <ListGroup.Item className="dense" key={s.id + s.color + s.threshold}>
                <FaCircle style={{ color: s.color }} className="mr-2" />
                <input className="editable-legend" defaultValue={s.threshold} onBlur={handleLegendChange(legend, s)} />
                {' '}
                –
                {' '}
                {getNextThreshold(legend, s)}
                {' '}
                {s.description}

              </ListGroup.Item>
            ))}
          </ListGroup>
        </Card>
        )}
      </div>
    </div>
  );
});
export default MapView;
