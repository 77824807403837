import React, { useContext, useMemo } from 'react';
import {
  Container,
  Row,
  Col,
} from 'react-bootstrap';
import loglevel from 'loglevel';
import Sidebar from './Sidebar';
import MapView from './MapView';
import { withSettingsStore } from '../common/SettingsContext';
import { HomeContext, HomeStore } from './HomeContext';
import { MapStore } from './MapContext';

const HomeMap = ({ mapId }) => {
  const {
    currentAttribute,
    currentAttributeGroup,
    toggleAttributeGroup,
    isLoadingFeature,
    setSelectedFeatureIds,
    selectFeature,
    toggleLayer,
    toggleAttribute,
  } = useContext(HomeContext);

  const attributeGroupId = useMemo(() => {
    if (currentAttributeGroup !== null) {
      return currentAttributeGroup.id;
    }
    return null;
  }, [currentAttributeGroup]);

  const attributeCode = useMemo(() => {
    if (currentAttribute !== null) {
      return currentAttribute.code;
    }
  }, [currentAttribute]);

  return (
    <Row style={{ height: '100%' }}>
      <Col className="m-0 p-0">
        {/* Map store provides data for set of maps */}
        <MapStore
          mapId={mapId}
          attributeGroupId={attributeGroupId}
        >
          {/* MapView displays data from map store but also triggers data loading */}
          <MapView
            useBingMaps
            automaticLayerSelection
            selectFeatureByDefault
            attributeCode={attributeCode}
            onSelectAttribute={(a) => { a !== null && toggleAttribute({ attributeCode: a.code }); }}
            onSelectFeatures={(f) => { selectFeature({ featureId: f.ids[0], layerId: f.layerId }); }}
            onSelectLayer={(l) => { toggleLayer({ layerId: l }); }}
          >
            {/* MapView children are appended to the left side */}
            <Sidebar mapId={mapId} />
          </MapView>
        </MapStore>
      </Col>
    </Row>
  );
};

const Home = withSettingsStore(({ app }) => {
  const getMapId = () => {
    const paramsString = window.location.search;
    const params = new URLSearchParams(paramsString);
    return params.get('mapid');
  };

  const mapId = useMemo(() => {
    const id = getMapId();
    if (id !== undefined && id !== null) {
      return id;
    }
    if (app === undefined) {
      return undefined;
    }
    return app.defaultMap.id;
  }, [app]);
  return (
    <HomeStore mapId={mapId}>
      <Container fluid style={{ height: '100%' }}>
        {mapId !== undefined && (
        <HomeMap mapId={mapId} />
        )}
      </Container>
    </HomeStore>
  );
});
export default Home;
