import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Form,
  ButtonGroup,
  ToggleButton,
  Button,
  Col,
} from 'react-bootstrap';
import loglevel from '../services/loglevel';

export default (props) => {
  const { t } = useTranslation();

  const [projectMap, setProjectMap] = useState({
    name: '',
    description: '',
    attributeGroups: [],
    arcgisOnlineMapAppId: '',
    id: -1,
    isPublic: false,
    ...props.projectMap,
  });

  /* useEffect(() => {
        setProjectMap(props.projectMap, [props.projectMap])
    }, [props])
    */

  useEffect(() => {
    loglevel.info('project changed');
    props.onChange(projectMap);
  }, [props, projectMap]);

  const handleChange = (event) => {
    let { value } = event.target;
    const { name } = event.target;

    if (value === 'true') {
      value = true;
    }
    if (value === 'false') {
      value = false;
    }

    setProjectMap((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAttributeGroupChange = (attributeGroup) => (event) => {
    const { value } = event.target;
    const { name } = event.target;
    setProjectMap((map) => {
      const attrGroup = map.attributeGroups.find((ag) => ag === attributeGroup);
      attrGroup[name] = value;
      return map;
    });
  };

  const handleAddGroup = () => {
    setProjectMap((map) => ({
      ...map,
      attributeGroups: [...map.attributeGroups, { name: '', description: '' }],
    }));
  };

  return (
    <Form>
      <Form.Group controlId="formName">
        <Form.Label>{t('Name')}</Form.Label>
        <Form.Control name="name" defaultValue={projectMap.name} type="text" placeholder={t('Enter name')} onChange={handleChange} />
      </Form.Group>
      <Form.Group controlId="formDescription">
        <Form.Label>{t('Description')}</Form.Label>
        <Form.Control name="description" defaultValue={projectMap.description} type="text" placeholder={t('Enter description')} onChange={handleChange} />
      </Form.Group>
      {/* <Form.Group controlId="formArcGIS">
                <Form.Label>{t('ArcGIS Online Application Id')}</Form.Label>
                <Form.Control name="arcgisOnlineMapAppId" defaultValue={projectMap.arcgisOnlineMapAppId} type="text" placeholder={t('Enter Application Id')} onChange={handleChange} />
            </Form.Group> */}
      <Form.Group>
        <Form.Label>{t('Groups')}</Form.Label>
        {projectMap.attributeGroups.map((ag) => (
          <div key={ag.id}>
            <Form.Row className="my-2">
              <Col>
                <Form.Control name="name" defaultValue={ag.name} type="text" placeholder={t('Group name')} onChange={handleAttributeGroupChange(ag)} />
              </Col>
              <Col>
                <Form.Control name="description" defaultValue={ag.description} type="text" placeholder={t('Group description')} onChange={handleAttributeGroupChange(ag)} />
              </Col>
              <Col>
                <Form.Control name="defaultAttributeCode" defaultValue={ag.defaultAttributeCode} type="text" placeholder={t('Default attribute')} onChange={handleAttributeGroupChange(ag)} />
              </Col>
            </Form.Row>
          </div>
        ))}
        <br />
        <Button onClick={() => handleAddGroup()}>{t('Add group')}</Button>
      </Form.Group>

      <Form.Group controlId="formIsPublic">
        <Form.Label>{t('Allow public access?')}</Form.Label>

        <ButtonGroup toggle>
          <ToggleButton key="true" type="radio" name="isPublic" value checked={projectMap.isPublic} onChange={handleChange}>{t('Yes')}</ToggleButton>
          <ToggleButton key="false" type="radio" name="isPublic" value={false} checked={!projectMap.isPublic} onChange={handleChange}>{t('No')}</ToggleButton>
        </ButtonGroup>
      </Form.Group>
    </Form>
  );
};
