import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';

import {
  Container, Row, Col, Card, ButtonGroup, Button, Dropdown, Form,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { CompareContext } from './CompareContext';
import Api from '../../services/api';

const CompareInlineEdit = ({ widget, onChange }) => {
  const { t } = useTranslation();
  const [attributes, setAttributes] = useState([]);
  const { selectedProjectMaps } = useContext(CompareContext);
  const selectedMap = useMemo(() => selectedProjectMaps.find((m) => m.id === widget.mapId), [selectedProjectMaps, widget.mapId]);

  useEffect(() => {
    if (widget.mapId > 0) {
      // const features = Api().maps.layer(currentMap.id).features({ layerId: currentLayer.id, attributes, extent: currentExtent }, newCancelTokenSource.token)
      Api().maps.layer(widget.mapId).get({}).then((layers) => {
        // setAttributes(attrs.attributes)
        let allAttributes = layers.reduce((acc, cur) => [...acc, ...cur.attributes], []);
        allAttributes = allAttributes.filter((a) => a.attributeGroupId === widget.attributeGroupId).filter((obj, idx, self) => self.map((s) => s.name).indexOf(obj.name) === idx);

        setAttributes(allAttributes);
      });
      // Api().maps.layer(selectedMapId).features({ layerId: selectedLayerId }).then(features => setFeatures(features))
    }
  }, [widget, widget.attributeGroupId, widget.mapId]);

  const handleChangeAttributeGroupId = (id) => {
    onChange({ ...widget, attributeGroupId: id });
  };

  const toggleSelectedAttributeName = (attribute, status) => {
    if (!status) {
      onChange({
        ...widget,
        attributes: widget.attributes.filter((n) => n.code !== attribute.code),
      });
    } else {
      onChange({
        ...widget,
        attributes: [...widget.attributes, { name: attribute.name, code: attribute.code }],
      });
    }
  };

  return (
    <>
      {selectedMap.name}
      <Card.Body>
        <Form.Row>
          <Col>
            <Form.Group controlId="formType">
              <Form.Label>{t('widget-form.group')}</Form.Label>
              <Form.Control as="select" value={widget.attributeGroupId} onChange={(e) => { handleChangeAttributeGroupId(Number(e.target.value)); }}>
                <option value="0">{t('widget-form.select')}</option>
                {selectedMap.attributeGroups.map((ag) => <option key={ag.id} value={ag.id}>{ag.name}</option>)}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col>
            {attributes.length > 0 && (
            <Form.Group controlId="formType">
              <Form.Label>{t('widget-form.attribute')}</Form.Label>
              <br />
              {attributes.map((a) => <Form.Check label={(<div style={{ fontWeight: a.parentId === null && 'bold' }}>{a.name}</div>)} inline type="checkbox" checked={widget.attributes.map((sa) => sa.code).includes(a.code)} onChange={(e) => toggleSelectedAttributeName(a, e.target.checked)} />)}
            </Form.Group>
            )}
          </Col>
        </Form.Row>
      </Card.Body>
    </>
  );
};

export default CompareInlineEdit;
