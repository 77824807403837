import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Form,
  Table,
} from 'react-bootstrap';
import { FaStar, FaRegStar } from 'react-icons/fa';
import Api from '../../services/api';

const AttributeGroupForm = (props) => {
  const { t } = useTranslation();

  const [layer, setLayer] = useState(null);
  const [map, setMap] = useState(null);

  const getLayer = async (mapId, layerId) => {
    setLayer(await Api().maps.layer(mapId).get({ layerId }));
  };
  const getMap = async (mapId) => {
    setMap(await Api().maps.get({ id: mapId }));
  };

  useEffect(() => {
    getLayer(props.layer.projectMapId, props.layer.id);
    getMap(props.layer.projectMapId);
  }, [props.layer.id, props.layer.projectMapId]);

  useEffect(() => {
    if (layer !== null && layer.id !== props.layer.id) {
      getLayer(props.layer.projectMapId, props.layer.id);
    }
  }, [layer, props.layer]);

  const handleAttributeInputChange = (attribute, name, value) => async (e) => {
    if (name === undefined || value === undefined) {
      value = e.currentTarget.value;
      name = e.currentTarget.name;
    }
    if (name === 'order') {
      value = Number(value);
    }
    setLayer((prev) => {
      const attr = layer.attributes.find((a) => a === attribute);
      attr[name] = value;
      return prev;
    });
    await Api().maps.layer(layer.projectMapId).update(layer);
  };
  const handleAttributeGroupChange = (layer, attribute) => async (e) => {
    const { value } = e.target;
    setLayer((prev) => {
      const attr = layer.attributes.find((a) => a === attribute);
      attr.attributeGroupId = Number(value);
      return prev;
    });
    await Api().maps.layer(layer.projectMapId).update(layer);
  };
  const handleParentChange = (layer, attribute) => async (e) => {
    const { value } = e.target;
    setLayer((prev) => {
      const attr = layer.attributes.find((a) => a === attribute);
      attr.parentId = Number(value);
      return { ...prev };
    });
    await Api().maps.layer(layer.projectMapId).update(layer);
  };

  useEffect(() => {
    if (layer !== null) {
      console.log(layer.attributes);
    }
  }, [layer]);
  return (
    <div>
      {layer !== null && map !== null && (
        <Table>
          <thead>
            <tr>
              <th>{t('#')}</th>
              <th>{t('Name')}</th>
              <th>{t('Group')}</th>
              <th>{t('Parent')}</th>
              <th>{t('Color')}</th>
              <th>{t('Description')}</th>
              <th>{t('Order')}</th>
              <th>{t('project.unit-long')}</th>
              <th>{t('project.unit-short')}</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {layer.attributes.sort((a, b) => a.id - b.id).map((a) => (
              <tr key={a.id}>
                <td>{a.code}</td>
                <td><Form.Control as="input" name="name" defaultValue={a.name} onBlur={handleAttributeInputChange(a)} /></td>
                <td>
                  <Form.Control as="select" custom onChange={handleAttributeGroupChange(layer, a)}>
                    <option value="null" />
                    {map.attributeGroups.map((ag) => (
                      <option selected={ag.id === a.attributeGroupId} name={ag.id} value={ag.id}>
                        {ag.name}
                        {' '}
                        (
                        {ag.code}
                        )
                      </option>
                    ))}
                  </Form.Control>
                </td>
                <td>
                  <Form.Control as="select" custom name="parentId" onChange={handleParentChange(layer, a)}>
                    <option value="null" />
                    {layer.attributes.map((la) => (
                      <option selected={la.id === a.parentId} name={la.id} value={la.id}>
                        {la.name}
                        {' '}
                        (
                        {la.code}
                        )
                      </option>
                    ))}
                  </Form.Control>
                </td>
                <td><Form.Control as="input" name="color" style={{ background: a.color }} defaultValue={a.color} onBlur={handleAttributeInputChange(a)} /></td>
                <td><Form.Control as="input" name="description" defaultValue={a.description} onBlur={handleAttributeInputChange(a)} /></td>
                <td><Form.Control as="input" name="order" defaultValue={a.order} onBlur={handleAttributeInputChange(a)} /></td>
                <td><Form.Control as="input" name="unitNameLong" defaultValue={a.unitNameLong} onBlur={handleAttributeInputChange(a)} /></td>
                <td><Form.Control as="input" name="unitNameShort" defaultValue={a.unitNameShort} onBlur={handleAttributeInputChange(a)} /></td>
                <td>
                  {a.isImportant ? <FaStar onClick={handleAttributeInputChange(a, 'isImportant', false)} size="32" />
                    : <FaRegStar onClick={handleAttributeInputChange(a, 'isImportant', true)} size="32" />}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
};
export default AttributeGroupForm;
