import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import LoginForm from './LoginForm';

const LoginModal = (props) => {
  const { t } = useTranslation();
  const [showLoginModal, setShowLoginModal] = useState(false);

  useEffect(() => {
    setShowLoginModal(props.show);
  }, [props.show]);

  return (
    <Modal show={showLoginModal} onHide={() => props.onHide()}>
      <Modal.Header closeButton>
        <Modal.Title>{t('login.login')}</Modal.Title>
      </Modal.Header>
      {props.message !== '' && (
      <Modal.Body>
        {props.message}
      </Modal.Body>
      )}
      <Modal.Body>
        <LoginForm onLogin={props.onLogin} />
      </Modal.Body>
    </Modal>
  );
};

export default LoginModal;
