import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';

const termsOfUseEn = (
  <>
    <p>
      <strong>
        <span className="underline">
          LIVCY BY RAMBOLL WEBSITE
          <br />
          disclaimers, terms OF USE and PRIVACY LANDING PAGE
        </span>
      </strong>
    </p>
    <p>
      <span className="underline">Date Last Updated</span>
      : 14 April 2021
    </p>
    <p>
      <em><strong>IF YOU NEED OR WANT TO RECEIVE A REPRODUCTION OF THE BELOW IN AN ALTERNATE FORMAT, PLEASE CONTACT</strong></em>
      {' '}
      <em>
        <strong>
          EERO SALMINEN AT
          <a href="mailto:eero.salminen@ramboll.fi">eero.salminen@ramboll.fi</a>
        </strong>
      </em>
      .
    </p>
    <ol>
      <li>
        <p>
          <strong><span className="underline">LIMITED/RESTRICTED LICENSE TO ACCESS/USE PLATFORM, ETC.</span></strong>
          {' '}
          Subject to (a) the below definitions, (b) the remaining terms of this Platform-Terms Landing Page, (c) the Terms of Use and (d) the Platform Privacy Policy, Ramboll grants to you a limited and restricted license to access certain parts of the Platform and to access and use certain of the Platform Functionality/Services as discussed in the Platform Service Description.
          {' '}
          <strong>You may not access or use any part of the Platform or any of the Platform Functionality/Services unless and until you have read and agree to be bound by the terms of this Platform-Terms Landing Page and the Terms of Use, and unless and until you have read and acknowledge the Platform Service Description and Platform Privacy Policy</strong>
          .
        </p>
      </li>
      <li>
        <p>
          <strong><span className="underline">DEFINITIONS</span></strong>
          . For purposes hereof and of the below-incorporated Terms of Use and Platform Privacy Policy, the below defined terms shall have the meanings set forth below. Additional defined terms and their respective definitions for purposes hereof and of the below-incorporated Terms of Use and Platform Privacy Policy may be set forth elsewhere herein, in the Terms of Use and/or in the Platform Privacy Policy.
        </p>
        <ol>
          <li>
            <p>
              “
              <strong>Affiliate</strong>
              ” means a direct or indirect parent company of Ramboll or a direct or indirect wholly or partially owned subsidiary of any such parent company.
            </p>
          </li>
          <li>
            <p>
              “
              <strong>Dispute Resolution Forum/Rules</strong>
              ” means the District Court of Helsinki as the court of first instance, and the rules thereof.
            </p>
          </li>
          <li>
            <p>
              “
              <strong>Dispute Resolution Jurisdiction</strong>
              ” means Finland.
            </p>
          </li>
          <li>
            <p>
              “
              <strong>Platform</strong>
              ” means the LIVCY by Ramboll Website, together with any and all other applications, websites or similar platforms that are linked to the LIVCY by Ramboll Website or to this Platform-Terms Landing Page, or that incorporate either by reference.
            </p>
          </li>
          <li>
            <p>
              “
              <strong>Platform Data Processing Agreement</strong>
              ” means the data processing agreement located at
              <a href="https://ramboll.com/-/media/files/rgr/documents/legal/LIVCY-By-Ramboll-Platform-DPA">https://ramboll.com/-/media/files/rgr/documents/legal/LIVCY-By-Ramboll-Platform-DPA</a>
            </p>
          </li>
          <li>
            <p>
              “
              <strong>Platform Functionality/Services</strong>
              ” means the Platform’s functionality and the content, data, information, products and/or services available through, or otherwise related to, the Platform.
            </p>
          </li>
          <li>
            <p>
              “
              <strong>Platform-Terms Landing Page</strong>
              ” means this LIVCY by Ramboll Website Disclaimers, Terms of Use and Privacy Landing Page.
            </p>
          </li>
          <li>
            <p>
              “
              <strong>Platform Privacy Policy</strong>
              ” means the privacy terms, disclosures, etc., located at
              <a href="https://ramboll.com/-/media/files/rgr/documents/legal/LIVCY-By-Ramboll-Platform-Privacy-Policy">https://ramboll.com/-/media/files/rgr/documents/legal/LIVCY-By-Ramboll-Platform-Privacy-Policy</a>
              , as well as those incorporated therein by reference, as may be modified by this Platform-Terms Landing Page.
            </p>
          </li>
          <li>
            <p>
              “
              <strong>Platform Service Description</strong>
              ” means the LIVCY by Ramboll Service Description located at
              <a href="https://ramboll.com/-/media/files/rgr/documents/legal/LIVCY-By-Ramboll-Platform-Service-Description">https://ramboll.com/-/media/files/rgr/documents/legal/LIVCY-By-Ramboll-Platform-Service-Description</a>
            </p>
          </li>
          <li>
            <p>
              “
              <strong>Ramboll</strong>
              ” means Ramboll Finland Oy, a limited company formed, organized and existing under the laws of Finland, with Company Registration No. 0101197-5.
            </p>
          </li>
          <li>
            <p>
              “
              <strong>Terms of Use</strong>
              ” means the terms of use located at
              <a href="https://ramboll.com/legal-information/standard-online-no-fee-platform-terms">https://ramboll.com/legal-information/standard-online-no-fee-platform-terms</a>
              , as may be modified by this Platform-Terms Landing Page.
            </p>
          </li>
        </ol>
      </li>
      <li>
        <p>
          <strong><span className="underline">DISCLAIMERS</span></strong>
          .
        </p>
      </li>
    </ol>
    <p>Any and all access to/use of the Platform and/or any Platform Functionality/Services by you or any other person or entity (other than Ramboll) is for informational purposes only. Except as and to the extent expressly and unequivocally assumed, respectively, by Ramboll and/or a Ramboll Affiliate in this Platform-Terms Landing Page, the Terms of Use or the Platform Privacy Policy, neither Ramboll, nor any of its Affiliates, assumes any duty whatsoever to you or to any other person or entity arising from or related to your or any other person’s or entity’s access to or use of, or otherwise related to, the Platform or Platform Functionality/Services. Ramboll and its Affiliates expressly disclaim any and all duties beyond those, if any, expressly and unequivocally assumed by them, respectively, in this Platform-Terms Landing Page, the Terms of Use or the Platform Privacy Policy.</p>
    <p>Without limiting any disclaimer or other term or condition stated in this Platform-Terms Landing Page, the Terms of Use or the Platform Privacy Policy, by accessing and/or using any part/aspect of the Platform or Platform Functionality/Services, you acknowledge the following:</p>
    <ol>
      <li><p>The Platform and Platform Functionality/Services are tools designed to assist (authorized) users to assess the sustainability of a particular remediation approach/process (selected, identified and described by the user) as applied to a particular remediation project/site (also selected, identified and described by the user). In that context, the Platform and Platform Functionality/Services rely heavily on information supplied by the user (and the accuracy and completeness thereof) regarding the subject remediation approach/process, the subject remediation project/site, and otherwise. Ramboll and its Affiliates disclaim any and all responsibility whatsoever related to user-supplied information. In addition, it is the responsibility of the user, and not the responsibility of Ramboll or of any Ramboll Affiliate, to determine whether and the extent to which (a) the Platform and Platform Functionality/Services are appropriate tools to assist the user under the circumstances and (b) the user should use other tools (whether originating from Ramboll, the user or a third party) in conjunction with the Platform and Platform Functionality/Services. It also is the responsibility of the user, and not the responsibility of Ramboll or of any Ramboll Affiliate, to determine whether and the extent to which sustainability is a material factor, and it’s weight compared to other factors, in the user’s selection of one remediation/approach/process over another or in any other user decision.</p></li>
      <li><p>The Platform and Platform Functionality/Services does not incorporate all regulatory, legal or other requirements related to the remediation project/site at issue, and, in turn, cannot be relied upon in that regard, and neither Ramboll nor any of Ramboll’s Affiliates make any related representation or warranty.</p></li>
      <li><p>Neither Ramboll nor any of Ramboll’s Affiliates represent or warrant that your access to or use of the Platform or Platform Functionality/Services will be uninterrupted or free from third-party hacking or other similar third-party conduct intended to disrupt access to and/or use of the Platform or Platform Functionality/Services and/or to gain unauthorized access to the Platform or other platforms/systems, including, without limitation, access to sensitive personal information, proprietary business information, or other information that you may input to or otherwise share on or through the Platform or Platform Functionality/Services.</p></li>
      <li><p>Ramboll reserves the right to discontinue your access to/use of the Platform and Platform Functionality/Services, in whole or in part, and without notice, at any time in its sole and absolute discretion – and may do so without liability or responsibility whatsoever to you or any third party. Similarly, and although, for the time being, the Platform and certain Platform Functionality/Services may be provided to you free of charge, Ramboll reserves the right, at any time in its sole and absolute discretion, to condition your access to/use of the Platform or Platform Functionality/Services, in whole or in part, upon your agreement to related fee/payment terms and receipt of payment by Ramboll in accordance with those terms.</p></li>
      <li><p>Ramboll reserves the right to update the Platform and Platform Functionality/Services, in whole or in part, and without notice, at any time in its sole and absolute discretion – and may do so without liability or responsibility whatsoever to you or any third party.</p></li>
    </ol>
    <p>In addition to the foregoing disclaimers, any and all access to/use of the Platform and/or any Platform Functionality/Services by you or any other person or entity (other than Ramboll) also is subject to any and all disclaimers set forth elsewhere herein or in the Terms of Use or Platform Privacy Policy.</p>
    <ol start="4">
      <li>
        <strong><span className="underline">TERMS AND CONDITIONS</span></strong>
        .
      </li>
    </ol>
    <p>By accessing and/or using any part/aspect of the Platform or Platform Functionality/Services, you affirm that you are over eighteen (18) years old, and you acknowledge and agree that, by accessing and/or using any of the foregoing, you, individually/personally, and on behalf of any and all other persons and entities on behalf of whom/which you access/use, or purport to access and/or use, the Platform or the Platform Functionality/Services, are entering into a legally binding agreement/contract with Ramboll consisting of all terms and conditions set forth in or incorporated into this Platform-Terms Landing Page (including specifically, but without limitation, the Terms of Use), and that you and any such other persons or entities understand and agree to comply with and be legally bound by those terms and conditions. The Platform and the Platform Functionality/Services are not intended for consumer use.</p>
    <p>For purposes of the Platform and Platform Functionality/Services, the referenced Terms of Use are modified as follows:</p>
    <ol>
      <li>
        <p>The text of paragraph 22 following the heading “Governing Law, Choice of Forum, Disputes Resolution” is stricken in its entirety and replaced with the following:</p>
        <p>These Terms of Use, any and all other terms and conditions governing your access to or use of the Platform and/or Platform Functionality/Services and your access to and use of the Platform and/or Platform Functionality/Services in general, shall be construed under/governed by the laws of the Dispute Resolution Jurisdiction (as defined/identified in the Platform-Terms Landing Page), without regard to the conflict of laws rules of that jurisdiction or any other jurisdiction. Any dispute or controversy relating to these Terms of Use, any and all other terms and conditions governing your access to or use of the Platform and/or Platform Functionality/Services or your access to and use of the Platform and/or Platform Functionality/Services in general, that cannot be resolved amicably shall be resolved by the District Court of Helsinki as the court of first instance, under the rules thereof.</p>
        <p>In furtherance of the foregoing, for the avoidance of doubt, you hereby consent to the personal jurisdiction of any court or other tribunal of competent subject matter jurisdiction sitting in the Dispute Resolution Jurisdiction.</p>
      </li>
      <li>
        <p>The following paragraphs 23 and 24 are added after paragraph 22:</p>
        <p>
          <strong><span className="underline">Reproduction of Materials Generated from the Platform or Platform Functionality/Services</span></strong>
          . To the extent that any report or other document or material is generated via the Platform or Platform Functionality/Services, you shall include all pages thereof, including, without limitation, any cover-page, in their entireties in any and all reproductions of such report or other document or material.
        </p>
        <p>
          <strong><span className="underline">Copyright and Trademarks</span></strong>
          . For the avoidance of doubt, and unless otherwise noted, all data, images, materials, reports, screenshots, software, text, and other documents and information contained within or generated by the Platform or Platform Functionality/Services (hereinafter “
          <strong>Copyrighted Materials</strong>
          ”) are copyrighted by Ramboll, a Ramboll Affiliate and/or a third party (hereinafter each a “
          <strong>Copyright Holder</strong>
          ”), and title to the copyright in Copyrighted Materials will at all times remain with the Copyright Holder. To the extent that any report or other document or material generated via the Platform or Platform Functionality/Services includes any logo or trademark of, or other reference to, Ramboll (whether Livcy by Ramboll or otherwise) or a Ramboll Affiliate, you shall include such logo or trademark, or other reference, on, and not remove same from, any reproduction of such report or other document or material.
        </p>
      </li>
    </ol>
    <p>In addition, the Platform Data Processing Agreement is hereby incorporated into the Terms of Use.</p>
    <ol start="5">
      <li>
        <strong><span className="underline">PRIVACY</span></strong>
        .
      </li>
    </ol>
    <p>
      IN ADDITION, by accessing and/or using any part of the Platform or Platform Functionality/Services, you, individually/personally, and on behalf of any and all other persons and entities on behalf of whom/which you access/use, or purport to access and/or use, the Platform or the Platform Functionality/Services, acknowledge that you have read and understand the Platform Privacy Policy, including, any and all policies, statements, terms, disclosures, etc., that may be incorporated therein by reference, whether related to privacy or otherwise, such as, for example, any general privacy policies, statements, terms, disclosures, etc., of Ramboll Group A/S and its affiliates (like those set forth at
      <a href="https://ramboll.com/legal-information">https://ramboll.com/legal-information</a>
      ) and any jurisdiction-specific privacy policies, statements, terms, disclosures, etc., (like those made pursuant to or otherwise related to the European Union General Data Protection Regulation or the California Consumer Privacy Act).
    </p>
    <p>Please note, however, that:</p>
    <ol>
      <li><p>where general privacy policies, statements, terms, disclosures, etc., that are incorporated by reference into the Platform Privacy Policy conflict with information set forth elsewhere that is specific to the Platform, such as, for example, within the text of the Platform Privacy Policy page itself, the latter shall govern;</p></li>
      <li><p>where jurisdiction-specific privacy policies, statements, terms, disclosures, etc., that are set forth within or incorporated by reference into the Platform Privacy Policy conflict with other information set forth within or incorporated into the Platform Privacy Policy, then, as to citizens/residents/subjects of a given jurisdiction, and those whose relevant rights otherwise are governed by the privacy laws of the subject jurisdiction, the subject jurisdiction-specific privacy policies, statements, terms, disclosures, etc., shall govern; and</p></li>
      <li><p>information set forth within or incorporated by reference into the Platform Privacy Policy may not apply to other applications, websites and platforms of Ramboll and its Affiliates.</p></li>
    </ol>
    <p>In addition, please note that, to the extent the Platform or Platform Functionality/Services link to third-party applications, websites or other platforms, Ramboll and its Affiliates are not responsible for the content, privacy practices, etc., of such third-party applications, websites or other platforms. Ramboll encourages users to be aware when they leave the Platform or Platform Functionality/Services and to read the terms of use, privacy statements, etc., of any other application, website or other platform that collects information.</p>

  </>
);

const TermsOfUse = () => (
  <Container className="mt-4">
    <Row>
      <Col>
        {termsOfUseEn}
      </Col>
    </Row>
  </Container>
);

export default TermsOfUse;
