import React, {
  useState, useEffect, useCallback, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  Modal,
  ButtonGroup,
  ButtonToolbar,
} from 'react-bootstrap';
import { FaPen, FaTrash, FaPaperPlane } from 'react-icons/fa';
import Api from '../../services/api';
import ProjectForm from '../../components/ProjectForm';
import InviteForm from '../../components/InviteForm';

import './Project.css';
import { useHistory } from 'react-router-dom';
import Maps from './Maps';
import ProjectUsers from './ProjectUsers';
import { withSettingsStore } from '../common/SettingsContext';

const Project = withSettingsStore((props) => {
  const { t } = useTranslation();
  const projectId = props.match.params.id;
  const history = useHistory();

  const [initialized, setInitialized] = useState(false);
  const [showProjectDialog, setShowProjectDialog] = useState(false);
  const [showUsersDialog, setShowUsersDialog] = useState(false);
  const [project, setProject] = useState({});

  const [showInviteDialog, setShowInviteDialog] = useState(false);
  const [invite, setInvite] = useState({
    email: '',
    projectRole: null,
  });

  const getProject = useCallback(async () => {
    const project = await Api().projects.get({ projectId });
    setProject(project);
  }, [projectId]);

  async function removeProject() {
    await Api().projects.delete({ projectId });
    history.push('/home');
  }

  useEffect(() => {
    if (!initialized) {
      getProject();
      setInitialized(true);
    }
  }, [initialized, getProject]);

  const processInvite = async (invite) => {
    await Api().projects.inviteUser({ projectId: Number(projectId), email: invite.email, roleId: Number(invite.roleId) });
    setShowInviteDialog(false);
  };

  const handleEditProject = async () => {
    await Api().projects.update({ projectId: project.id, ...project });
    setShowProjectDialog(false);
    getProject();
  };

  const isAdmin = useMemo(() => props.isInRole(['Admin'], project.id), [project.id, props]);

  return (
    <Container className="my-2">
      <Modal size="xl" show={showProjectDialog} onHide={() => { setShowProjectDialog(false); }}>
        <Modal.Header closeButton>
          <Modal.Title>{t('Edit project')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ProjectForm
            project={project}
            onChange={(project) => setProject(project)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => handleEditProject()}>{t('Edit project')}</Button>
        </Modal.Footer>
      </Modal>
      <Modal size="xl" show={showUsersDialog} onHide={() => { setShowUsersDialog(false); }}>
        <Modal.Header closeButton>
          <Modal.Title>{t('Users')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ProjectUsers projectId={project.id} />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowUsersDialog(false)}>{t('Ok')}</Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showInviteDialog} onHide={() => { setShowInviteDialog(false); }}>
        <Modal.Header>
          <Modal.Title>{t('Invite to project')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InviteForm onChange={(invite) => { setInvite(invite); }} />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => processInvite(invite)}>{t('Send invite')}</Button>
        </Modal.Footer>
      </Modal>

      <Card>
        <Card.Header>
          {project && (
            <div>
              <h2>{project.name}</h2>
              <h5>{project.description}</h5>
            </div>
          )}
        </Card.Header>
        {isAdmin && (
          <ButtonToolbar style={{
            background: '#EFEFEF', borderBottom: '1px solid #CCC', padding: '0.2em', paddingLeft: '1.25rem', paddingRight: '1.25rem',
          }}
          >
            <ButtonGroup>
              <Button onClick={() => { setShowProjectDialog(true); }} variant="outline-primary">
                <FaPen />
                {' '}
                {t('Edit project')}
              </Button>
              <Button variant="danger" onClick={removeProject}>
                <FaTrash />
                {' '}
                {t('Remove project')}
              </Button>
            </ButtonGroup>
            <div style={{ flexGrow: 1 }} />
            <ButtonGroup>
              <Button onClick={() => { setShowInviteDialog(true); }}>
                <FaPaperPlane />
                {' '}
                {t('Invite user')}
              </Button>
              <Button onClick={() => { setShowUsersDialog(true); }}>{t('Users')}</Button>
            </ButtonGroup>
          </ButtonToolbar>
        )}
        <Card.Body>
          <Row>
            <Col>
              <Maps projectId={projectId} />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
});

export default Project;
