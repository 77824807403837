import React, { useState, useMemo } from 'react';
import { Form, Alert, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { validate } from 'dda-helpers';
import Api from '../services/api';
import loglevel from '../services/loglevel';

const RegisterForm = (props) => {
  const { t } = useTranslation();

  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showCreateAccountError, setShowCreateAccountError] = useState(false);
  const [createAccountMessage, setCreateAccountMessage] = useState('');
  const [user, setUser] = useState({
    email: '',
    password: '',
    password2: '',
    company: '',
    telephone: '',
    name: '',
  });

  const isPasswordStrong = useMemo(() => validate.isStrongPassword(user.password), [user.password]);
  const isPasswordValid = useMemo(() => user.password === user.password2 && isPasswordStrong, [isPasswordStrong, user.password, user.password2]);
  const isPasswordSame = useMemo(() => user.password === user.password2, [user.password, user.password2]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUser((prev) => ({ ...prev, [name]: value }));
  };

  const handleCreateAccount = async (e) => {
    const form = e.currentTarget;

    // initialize
    setCreateAccountMessage('');

    // By default we prevent the default form action
    e.preventDefault();
    e.stopPropagation();

    // Show validation results
    setValidated(true);

    // If everything looks fine then go on
    if (form.checkValidity()) {
      setLoading(true);
      Api().accounts.register({ ...user, username: user.email }).then((res) => {
        loglevel.info('login automatically');
        typeof props.onRegister === 'function' && props.onRegister({ ...user, username: user.email });
      }).catch((error) => {
        let message = t('Something went wrong');
        if (error.response.data && error.response.data.errors) {
          const errors = Object.keys(error.response.data.errors);
          message = errors.map((field) => error.response.data.errors[field]).join(', ');
        } else if (error.response.data) {
          if (Array.isArray(error.response.data)) {
            message = error.response.data.map((d) => t(d.code)).join(', ');
          } else {
            message = error.response.data;
          }
        }

        setCreateAccountMessage(message);
        setShowCreateAccountError(true);
        setLoading(false);
      });
    }
  };

  return (
    <Form validated={validated} onSubmit={handleCreateAccount}>
      <Alert show={showCreateAccountError} variant="danger" onClose={() => setShowCreateAccountError(false)} dismissible>{t(`error.${createAccountMessage}`)}</Alert>
      <Form.Group controlId="registerFormEmail">
        <Form.Label>{t('register.email')}</Form.Label>
        <Form.Control required name="email" defaultValue={props.email} type="email" placeholder={t('register.enter email')} onChange={handleChange} />
        <Form.Text className="text-muted">
          {t('register.disclaimer')}
        </Form.Text>
      </Form.Group>
      <Form.Group controlId="registerFormName">
        <Form.Label>{t('register.name')}</Form.Label>
        <Form.Control required name="name" defaultValue={user.name} type="text" placeholder={t('register.enter name')} onChange={handleChange} />
      </Form.Group>

      <Form.Group controlId="registerFormCompany">
        <Form.Label>{t('register.company')}</Form.Label>
        <Form.Control name="company" defaultValue={user.company} type="text" placeholder={t('register.enter company')} onChange={handleChange} />
      </Form.Group>
      <Form.Group controlId="registerFormTelephone">
        <Form.Label>{t('register.phone')}</Form.Label>
        <Form.Control name="telephone" defaultValue={user.telephone} type="text" placeholder={t('register.enter phone')} onChange={handleChange} />
      </Form.Group>

      <Form.Group controlId="registerFormPassword">
        <Form.Label>{t('Password')}</Form.Label>
        <Form.Control isInvalid={!isPasswordStrong} isValid={isPasswordStrong} required name="password" defaultValue={props.password} type="password" placeholder={t('register.enter password')} onChange={handleChange} />
        <Form.Control isInvalid={!isPasswordValid || !isPasswordSame} isValid={isPasswordValid && isPasswordSame} required name="password2" defaultValue={props.password2} type="password" placeholder={t('register.enter password again')} onChange={handleChange} />
        <Form.Text className="text-muted">
          {t('register.password-requirements')}
        </Form.Text>
      </Form.Group>

      <Form.Group className="form-group text-right">
        <Button disabled={loading || (user.password !== user.password2 || user.username === '')} type="submit">
          {loading && (
            <span className="spinner-border spinner-border-sm" />
          )}
          {!loading && (<span>{t('register.create an account')}</span>)}
        </Button>
      </Form.Group>
    </Form>
  );
};

export default RegisterForm;
