import axios from 'axios';

async function ExtractData(axiosPromise) {
  const results = await axiosPromise;
  if (typeof results.headers['x-total-count'] !== 'undefined') {
    return {
      items: results.data,
      count: results.headers['x-total-count'],
    };
  }

  return results.data;
}

function getTokenHeader() {
  const token = window.localStorage.getItem('token');
  if (token !== undefined && token !== null) {
    return { Authorization: `Bearer ${token}` };
  }
  return {
    Authorization: undefined,
  };
}

const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
  headers: {
    'Content-type': 'application/json',
    ...getTokenHeader(),
  },
});

const getClient = () => {
  client.defaults.headers = {
    ...client.defaults.headers,
    ...getTokenHeader(),
  };
  return client;
};

export default {
  get() {
    return ExtractData(getClient().get.apply(null, arguments));
  },
  post() {
    return ExtractData(getClient().post.apply(null, arguments));
  },
  put() {
    return getClient().put.apply(null, arguments);
  },
  delete() {
    return getClient().delete.apply(null, arguments);
  },
  get CancelToken() {
    return axios.CancelToken;
  },
};
