import React, { useState, useEffect, useContext } from 'react';
import { Popover, Row, Col } from 'react-bootstrap';
import { withSettingsStore } from '../common/SettingsContext';
import { HomeContext } from './HomeContext';
import Widget from './Widget';
import ImgIntensityGridTxt from '../../assets/animations/intensity-grid-txt.gif';
import CustomRadio from './CustomRadio';

const WidgetIntensity = withSettingsStore(({ widget, data, displayLoginModal }) => {
  const [listData, setListData] = useState([]);
  const {
    setCurrentAttribute,
  } = useContext(HomeContext);

  // Popover
  const helpKey = (
    <Popover id="popover-basic" style={{ maxWidth: '500px' }}>
      <Popover.Title as="h3">Intensiteetti</Popover.Title>
      <Popover.Content>
        <Row>
          <Col>
            Potentiaalit kuvaavat kuinka paljon väestöä tai työpaikkoja
            on ruudun keskipisteestä 15 minuutin kävelymatkan etäisyydellä.
          </Col>
          <Col>
            <img src={ImgIntensityGridTxt} alt="" width="220px" />
          </Col>
        </Row>
      </Popover.Content>
    </Popover>
  );

  useEffect(() => {
    if (data !== undefined) {
      const attributesWithValue = data.attributes.map((a, idx) => {
        const value = data.data.map((row) => {
          let val = row[a.code];
          if (typeof val !== 'number') {
            val = 0;
          }
          return val;
        }).reduce((acc, cur) => acc + cur, 0) / data.data.length;
        return { ...a, idx, value };
      });
      setListData(attributesWithValue);
    }
  }, [data]);

  const selectRow = (attr) => {
    setCurrentAttribute(data.attributes.find((a) => a.id === attr.id));
  };

  return (
    <Widget data={data} widget={widget} displayLoginModal={displayLoginModal} helpKey={helpKey}>
      <div className="widgetList pb-2">
        {listData.sort((a, b) => a.order - b.order).map((row) => (
          <div key={row.id} className="d-flex justify-content-between align-items-baseline pt-2">
            <CustomRadio
              name="intensity"
              item={row}
              attributeIdx={row}
              handleChange={selectRow}
            >
              <span className="widgetList__name">{row.name}</span>
            </CustomRadio>
          </div>
        ))}
      </div>
    </Widget>
  );
});

export default WidgetIntensity;
