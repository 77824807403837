import React, {
  useState, useEffect, useCallback, useMemo,
} from 'react';
import GeoJSON from 'ol/format/GeoJSON';
import WKT from 'ol/format/WKT';
import Api from '../../services/api';
import loglevel from '../../services/loglevel';

import {
  TryNumber, GetMaxExtent, IsExtentWithin, GetExpandingExtents,
} from '../common/helpers';

const HomeContext = React.createContext();

/**
 * @param {children} child dom elements
 * @summary
 * This context stores all data related to the view, including:
 * - maps,
 * - layers,
 * - features,
 * - zoom level
 * etc.
 * @returns The provider for this react context
 */
const HomeStore = ({ children, mapId }) => {
  // Map contains list of attribute groups, layer names with ids, and legends
  const [currentMap, setCurrentMap] = useState(null);

  // Layer contains detailed layer features
  const [currentLayer, setCurrentLayer] = useState(null);

  // Currently selected attribute
  const [currentAttribute, setCurrentAttribute] = useState(null);

  // Currently selected attribute group (links attributes to a group)
  const [currentAttributeGroup, setCurrentAttributeGroup] = useState(null);

  // Selected feature ids
  const [selectedFeatureIds, setSelectedFeatureIds] = useState([]);

  // Currently loaded features
  const [currentFeatures, setCurrentFeatures] = useState([]);

  // Current extent (might not be loaded)
  const [currentExtent, setCurrentExtent] = useState(null);

  // Loading status
  const [isLoadingFeatures, setIsLoadingFeatures] = useState(false);

  const retrieveFeatures = useCallback(async ({ map, layerId, featureIds }) => await Promise.all(featureIds.map((featureId) => Api().maps.layer(map.id).feature({ layerId, featureId }))), []);

  const toggleLayer = async ({ layerId, mapId, attributeGroupName }) => {
    loglevel.debug(`Changing to ${mapId} with layer ${layerId}`);
    loglevel.debug(currentMap, currentAttributeGroup);

    const layer = await Api().maps.layer(mapId).get({ layerId });

    // Clearing
    // setSelectedFeatureIds([]);

    // Set layer
    setCurrentLayer(layer);
  };

  const toggleMap = async ({ mapId }) => {
    loglevel.debug(`Changing to ${mapId}`);

    const map = await Api().maps.get({ id: mapId });

    // selecte default attribute group
    if (currentAttributeGroup === null) {
      const defaultAttributeGroup = map.attributeGroups.find((ag) => ag.name.toLowerCase().includes('livcy'));
      setCurrentAttributeGroup(defaultAttributeGroup);
    }
    setCurrentMap({ ...map });
  };
  useEffect(() => {
    if (mapId !== undefined) {
      toggleMap({ mapId });
    }
  }, []);

  const toggleAttributeGroup = async ({ attributeGroup }) => {
    if (attributeGroup === currentAttributeGroup) {
      return;
    }
    setCurrentAttributeGroup(attributeGroup);
  };

  const availableAttributes = useMemo(() => {
    if (currentLayer === null || currentAttributeGroup === null) {
      return [];
    }
    return currentLayer.attributes.filter((a) => a.attributeGroupId === currentAttributeGroup.id);
  }, [currentLayer, currentAttributeGroup]);

  const toggleAttribute = async ({ attributeId, attributeCode }) => {
    if (attributeId !== undefined) {
      setCurrentAttribute(availableAttributes.find((a) => a.id === attributeId));
    }
    if (attributeCode !== undefined) {
      const found = availableAttributes.find((a) => a.code === attributeCode);
      loglevel.info('Toggling', availableAttributes, found, attributeCode);
      if (found !== undefined && currentAttribute !== found) {
        setCurrentAttribute(found);
      }
    }
  };

  const selectFeature = async ({ featureId, layerId }) => {
    const existing = currentFeatures.find((f) => f.id === featureId);
    if (existing !== undefined) {
      setSelectedFeatureIds([existing.id]);
    } else {
      loglevel.info(currentMap, currentLayer, layerId);
      retrieveFeatures({
        map: currentMap,
        layerId,
        featureIds: [featureId],
      }).then((features) => {
        setCurrentFeatures((f) => [...f, ...features]);
        setSelectedFeatureIds([featureId]);
      });
    }
  };

  const availableAttributeGroups = useMemo(() => {
    // For attribute groups to be of any use, it needs to have:
    // - working legend

    if (currentLayer === null || currentMap === null) {
      return [];
    }
    const legends = currentMap.legends.filter((l) => l.layerId === currentLayer.id);
    return currentMap.attributeGroups
      .filter((ag) => legends.map((l) => l.attributeGroupId).includes(ag.id));
  }, [currentLayer, currentMap]);

  const contextState = {
    // Current state
    currentMap,
    setCurrentMap,
    currentLayer,
    currentAttribute,
    setCurrentAttribute,
    currentFeatures, // Current selection with values for widgets
    selectedFeatureIds,
    setSelectedFeatureIds,
    currentAttributeGroup,
    isLoadingFeatures,
    currentExtent,
    setCurrentExtent,
    availableAttributeGroups,

    // Actions
    toggleLayer,
    toggleAttributeGroup,
    toggleAttribute,
    toggleMap,
    selectFeature, // select feature on map
  };
  return (
    <HomeContext.Provider value={contextState}>
      {children}
    </HomeContext.Provider>
  );
};

export { HomeContext, HomeStore };
