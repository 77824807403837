import React, {
  Fragment,
  useState,
  useEffect,
  useContext,
} from 'react';
import {
  Accordion,
  AccordionContext,
  useAccordionToggle,
  Popover,
  Row,
  Col,
} from 'react-bootstrap';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { HomeContext } from './HomeContext';
import { withSettingsStore } from '../common/SettingsContext';
import ImgAccessibilityTxt from '../../assets/animations/accessibility-txt.gif';
import Widget from './Widget';
import LineChart from './LineChart';
import LivcyIcon from './LivcyIcon';
import CustomRadio from './CustomRadio';

// Accessibility Data
const createAccessibilityData = (labels, data, colors, codes, order, id, parentId) => {
  const items = codes.map((code, idx) => ({
    code,
    label: labels[idx],
    color: colors[idx],
    data: data[idx],
    order: order[idx],
    id: id[idx],
    parentId: parentId[idx],
  }));
  return items;
};

// Popover
const helpKey = (
  <Popover id="popover-basic" style={{ maxWidth: '500px' }}>
    <Popover.Title as="h3">Saavutettavuus</Popover.Title>
    <Popover.Content>
      <Row>
        <Col>
          Kävelyetäisyys minuutteina lähimpään palveluun tieverkostoa pitkin.
        </Col>
        <Col>
          <img src={ImgAccessibilityTxt} alt="" width="220px" />
        </Col>
      </Row>
    </Popover.Content>
  </Popover>
);

const WidgetAccessibility = withSettingsStore(({ widget, data, displayLoginModal }) => {
  const [accessibilityData, setAccessibilityData] = useState(null);
  const [maxValue, setMaxValue] = useState(null);
  const {
    setCurrentAttribute,
  } = useContext(HomeContext);

  // Update data
  useEffect(() => {
    if (data !== undefined) {
      const { attributesWithChildren } = data;

      const graphData = attributesWithChildren.map((attr) => {
        const attrData = data.data.map((row) => {
          let val = row[attr.code];
          if (typeof val !== 'number') {
            val = 0;
          }
          return val;
        });
        return attrData.reduce((acc, cur) => acc + cur, 0) / attrData.length;
      });
      setMaxValue(Math.max(...graphData));

      setAccessibilityData([
        ...createAccessibilityData(
          attributesWithChildren.map(a => a.name),
          graphData,
          attributesWithChildren.map(a => a.color),
          attributesWithChildren.map(a => a.code),
          attributesWithChildren.map(a => a.order),
          attributesWithChildren.map(a => a.id),
          attributesWithChildren.map(a => a.parentId),
        ),
      ]);
    }
  }, [data, maxValue]);

  // Select Current Attribute
  const handleChange = (e) => {
    const attribute = data.attributesWithChildren[e];
    setCurrentAttribute(attribute);
  };

  // Accordion Toggle
  function AccordionToggle({ children, eventKey, callback }) {
    const currentEventKey = useContext(AccordionContext);
    const toggleOnClick = useAccordionToggle(
      eventKey,
      () => callback && callback(eventKey),
    );
    const isCurrentEventKey = currentEventKey === eventKey;
    return (
      <button type="button" onClick={toggleOnClick}>
        {children}
        <span className="widgetList__toggle">{ isCurrentEventKey ? <FiChevronUp /> : <FiChevronDown /> }</span>
      </button>
    );
  }

  return (
    <Widget data={data} widget={widget} displayLoginModal={displayLoginModal} helpKey={helpKey}>
      <ul className="widgetList">
        {accessibilityData !== null && accessibilityData.map((item, idx) => {
          const mainCode = 'kaikki_mi';
          const currentId = item.id;

          return (
            <Fragment key={item.id}>
              {item.parentId === null && (
                <Accordion
                  as="li"
                  key={item.id}
                  defaultActiveKey="0"
                  className={`widgetList__item ${item.code === mainCode ? 'widgetList__item--all' : ''}`}
                  style={{ color: item.color }}
                >
                  <div className="d-flex justify-content-between align-items-baseline">
                    <div className="d-flex justify-content-between align-items-baseline flex-grow-1 mr-2">
                      <CustomRadio
                        name="accessibility"
                        item={item}
                        attributeIdx={idx}
                        handleChange={handleChange}
                      >
                        <span>
                          <LivcyIcon icon={item.code} />
                          <span className="widgetList__name">{item.label}</span>
                        </span>
                        <span className="widgetList__value">{`${item.data.toFixed(0)} min`}</span>
                        <LineChart
                          value={Number(item.data.toFixed(0))}
                          max={Number(maxValue)}
                          color="currentColor"
                          strokeWidth={Number(4)}
                        />
                      </CustomRadio>
                    </div>
                    {item.code !== mainCode && <AccordionToggle eventKey={item.code} />}
                  </div>
                  <Accordion.Collapse className="widgetList widgetList--sub" eventKey={item.code}>
                    <ul>
                      {accessibilityData
                        .filter((subItem) => subItem.parentId === currentId)
                        .map((subItem, i) => (
                          <li key={subItem.id} className="widgetList__item">
                            <div className="d-flex justify-content-between align-items-baseline flex-grow-1 mr-2">
                              <CustomRadio
                                name="accessibility"
                                item={subItem}
                                attributeIdx={idx + i + 1}
                                handleChange={handleChange}
                              >
                                <span className="widgetList__name">{subItem.label}</span>
                                <span className="widgetList__value">{`${subItem.data.toFixed(0)} min`}</span>
                                <LineChart
                                  value={Number(subItem.data.toFixed(0))}
                                  max={Number(maxValue)}
                                  color="currentColor"
                                  strokeWidth={Number(2)}
                                />
                              </CustomRadio>
                            </div>
                          </li>
                        ))}
                    </ul>
                  </Accordion.Collapse>
                </Accordion>
              )}
            </Fragment>
          );
        })}
      </ul>
    </Widget>
  );
});

export default WidgetAccessibility;
