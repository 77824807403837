import React, {
  useState, useEffect, Fragment, useContext, useMemo,
} from 'react';
import {
  Container, Row, Col, Card, Help, ButtonGroup, Form, Button, Nav, Table, Badge,
  FormControl,
  InputGroup,
} from 'react-bootstrap';
import { Bar, HorizontalBar } from 'react-chartjs-2';
import {
  FaPlus, FaRegSadTear, FaTrash, FaSearch, FaUser, FaDatabase,
} from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { clone } from 'dda-helpers';
import loglevel from 'loglevel';
import { withSettingsStore } from '../common/SettingsContext';
import Api from '../../services/api';
import MapView from '../map/MapView';
import SelectionMap from './SelectionMap';

import { CompareContext } from './CompareContext';
import { TryNumber } from '../common/helpers';

const processTemplate = (text, r) => {
  // If there is no template syntax. use text as key
  if (!text.includes('{')) {
    return r[text];
  }
  const mapping = Object.keys(r).map((k) => {
    const key = new RegExp(`{${k}}`, 'gi');
    const value = r[k];
    return {
      key, value,
    };
  });

  // replace tags
  mapping.forEach((m) => text = text.replace(m.key, m.value));
  return text;
};

const processIcon = (fn, r) => {
  if (typeof fn === 'function') {
    return fn(r);
  }
  return undefined;
};

const processBackgroundColor = (fn, r) => {
  if (typeof fn === 'function') {
    const val = fn(r);
    return val === undefined ? 'white' : val;
  }
  return 'white';
};
const Typeahead = ({
  value, name, source, onSelect, resultText, resultIcon, resultBackgroundColor, placeholder, onChange, onBlur,
}) => {
  const timeout = 300; // ms
  const { t } = useTranslation();
  const [results, setResults] = useState([]);
  const [resultsVisible, setResultsVisible] = useState(false);
  const [searching, setSearching] = useState(false);
  const [currentTimeout, setCurrentTimeout] = useState(null);
  const [currentValue, setCurrentValue] = useState({});

  const handleSearch = async (event) => {
    const { value } = event.currentTarget;

    onChange !== undefined && onChange(event);
    setCurrentValue(value);
    clearTimeout(currentTimeout);
    setResultsVisible(false);

    if (value !== '') {
      setCurrentTimeout(setTimeout(async () => {
        setSearching(true);
        const results = await source({ search: value });

        if (results.length > 0) {
          setResults(results.map((r) => {
            // create mapping
            const text = processTemplate(resultText, r);
            const icon = processIcon(resultIcon, r);
            const backgroundColor = processBackgroundColor(resultBackgroundColor, r);

            return {
              value: r,
              text,
              icon,
              backgroundColor,
            };
          }));
        } else {
          setResults([]);
        }
        setSearching(false);
        setResultsVisible(true);
      }, timeout));
    }
  };
  const selectFromResults = (result) => {
    setResults([]);
    setResultsVisible(false);
    onSelect(result.value);
  };

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);
  return (
    <InputGroup className="mb-2 typeahead">
      <InputGroup.Prepend>
        <InputGroup.Text>
          {!searching && <FaSearch />}
          {searching && <span className="spinner-border spinner-border-sm" />}
        </InputGroup.Text>
      </InputGroup.Prepend>
      <FormControl name={name} placeholder={placeholder || t('Search')} onChange={handleSearch} value={currentValue} onBlur={(e) => onBlur !== undefined && onBlur(e)} />
      {results.length > 0 && (
      <div className="dropdown-menu" style={{ display: resultsVisible ? 'inline' : 'none' }}>
        {results.map((r, idx) => (
          <button style={{ backgroundColor: r.backgroundColor }} key={idx} className="dropdown-item" onClick={() => selectFromResults(r)}>
            <span className="mr-2">
              {r.icon === 'user' && <FaUser />}
              {r.icon === 'database' && <FaDatabase />}
            </span>
            {r.text}
          </button>
        ))}
      </div>
      )}
    </InputGroup>
  );
};

const TextField = ({
  defaultValue, onChange, disabled, isValid, size, name,
}) => {
  const [localValue, setLocalValue] = useState(defaultValue === null ? '' : defaultValue);

  const handleChange = (event) => {
    setLocalValue(event.currentTarget.value);
  };
  if (size === undefined) {
    size = 'md';
  }
  if (isValid === undefined) {
    isValid = true;
  }

  useEffect(() => {
    let value = defaultValue;
    if (disabled && !isNaN(Number(value))) {
      value = Number(value);
      if (!Number.isInteger(value)) {
        value = value.toFixed(2);
      } else {
        value = value.toFixed(0);
      }
    }
    setLocalValue(defaultValue === null ? '' : value);
  }, [disabled, defaultValue]);

  return (<Form.Control size={size} type="text" disabled={disabled} value={localValue} onChange={handleChange} name={name} onBlur={onChange(localValue)} isInvalid={!isValid} />);
};

const WidgetForm = ({ onSubmit, widget }) => {
  const { t } = useTranslation();
  const options = {
    legend: {
      display: true,
    },
    scales: {

      x: {
        stacked: false,
      },
      y: {
        stacked: false,
      },
      xAxes: [{
        gridLines: {
          display: true,
        },
        ticks: {
          beginAtZero: true,
        },
      }],
      yAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            mirror: false, z: 1, padding: -4, fontColor: '#000', beginAtZero: true,
          },
        },
      ],
    },
    maintainAspectRatio: false,
  };

  const baseColors = [
    '#F9CDE8',
    '#96CDFF',
    '#FFE083',
    '#8BF9E7',
    '#A1D49A',
    '#FF8996',
  ];
  const emptyDataSource = () => ({
    title: 'Test',
    layerId: 0,
    attributeGroupId: 0,
  });

  const { selectedProjectMaps } = useContext(CompareContext);
  const [dataSources, setDataSources] = useState([]);
  const [barTypeId, setBarTypeId] = useState(0);
  const [features, setFeatures] = useState([]);
  const [attributes, setAttributes] = useState([]);
  const [selectedMapId, setSelectedMapId] = useState(0);
  const [selectedLayerId, setSelectedLayerId] = useState(0);
  const [selectedAttributeGroupId, setSelectedAttributeGroupId] = useState(0);
  const [dataSourceForm, setDataSourceForm] = useState(emptyDataSource());
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [selectedAttributes, setSelectedAttributes] = useState([]);
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    graphType: 0,
    mapId: 0,
    attributeGroupId: 0,
    attributeNames: [],
    isDistribution: false,
    features: [],
  });

  const addDataSource = () => {
    setDataSources((prev) => [...prev, clone(dataSourceForm)]);
  };

  const selectedMap = useMemo(() => selectedProjectMaps.find((m) => m.id === selectedMapId), [selectedMapId, selectedProjectMaps]);

  const selectedLayer = useMemo(() => {
    if (selectedMap !== undefined) {
      return selectedMap.layers.find((l) => l.id === selectedLayerId);
    }
  }, [selectedLayerId, selectedMap]);

  const selectedAttributeGroup = useMemo(() => {
    if (selectedMap !== undefined) {
      return selectedMap.attributeGroups.find((ag) => ag.id === selectedAttributeGroupId);
    }
  }, [selectedAttributeGroupId, selectedMap]);

  const rawFeaturesToObjects = (arr, keys) => keys.reduce((acc, cur, idx) => {
    acc[cur] = TryNumber(arr[idx]);
    return acc;
  }, {});

  useEffect(() => {
    if (widget !== undefined && widget !== null) {
      setSelectedMapId(widget.mapId);
      setBarTypeId(widget.graphType);
      setSelectedAttributeGroupId(widget.attributeGroupId);
      setSelectedAttributes(widget.attributes);
      setSelectedFeatures(widget.features);
      setSelectedLayerId(0);
      setFormData(widget);
    }
  }, [widget]);

  useEffect(() => {
    // reset everything on map changes
    /* setSelectedAttributes([])
        setSelectedAttributeGroupId(0)
        setSelectedLayerId(0) */
  }, [selectedMapId]);

  useEffect(() => {
    if (selectedLayerId === 0) {
      return;
    }
    if (Array.isArray(attributes) && attributes.length > 0) {
      const attributeCodes = ['kunta', 'postinumer', 'pnro_alue', ...attributes.map((a) => a.code)];
      Api().maps.layer(selectedMapId).value({
        layerId: selectedLayerId,
        attributes: attributeCodes,
        extent: [-10000000, -10000000, 10000000, 10000000],
      }).then((feats) => {
        const items = Object.keys(feats.items).map((featureId) => {
          loglevel.info(feats.items[featureId]);
          const row = rawFeaturesToObjects(feats.items[featureId], attributeCodes);
          return { ...row, Id: featureId };
        });
        console.log(items);
        // console.log(rawFeaturesToObjects(feats.items, attributeCodes));
        // setFeatures(rawFeaturesToObjects(feats.items, attributeCodes));
        setFeatures(items);
      });
    }
  }, [attributes, selectedLayerId, selectedMapId]);

  useEffect(() => {
    console.log(selectedMapId);
  }, [selectedMapId]);
  useEffect(() => {
    if (selectedMapId > 0) {
      // const features = Api().maps.layer(currentMap.id).features({ layerId: currentLayer.id, attributes, extent: currentExtent }, newCancelTokenSource.token)
      Api().maps.layer(selectedMapId).get({}).then((layers) => {
        // setAttributes(attrs.attributes)
        let allAttributes = layers.reduce((acc, cur) => [...acc, ...cur.attributes], []);
        allAttributes = allAttributes.filter((a) => a.attributeGroupId === selectedAttributeGroupId).filter((obj, idx, self) => self.map((s) => s.name).indexOf(obj.name) === idx);

        setAttributes(allAttributes);
      });
      // Api().maps.layer(selectedMapId).features({ layerId: selectedLayerId }).then(features => setFeatures(features))
    }
  }, [selectedAttributeGroupId, selectedMapId]);

  const barData = useMemo(() => {
    if (selectedAttributeGroup && selectedFeatures.length > 0) {
      const datasets = {
        labels: selectedFeatures.map((f) => f.name /* f.kunta + ' ' + f.postinumer */), // [selectedAttributeGroup.name],
        datasets: selectedAttributes.map((sa, idx) => ({
          label: sa.name, // f.kunta + ' ' + f.postinumer,
          // data: selectedFeatures.map(f => Number(f[sa.code].replace(',', '.'))),
          data: selectedFeatures.map((f) => f.values.find((fv) => fv.attributeCode === sa.code)).filter((fv) => fv !== undefined).map((fv) => fv.value),
          backgroundColor: baseColors[idx],
          borderWidth: 1,
        })),
      };
      return datasets;
    }
    return {};
  });

  const handleMapSelection = (feature) => {
    console.log(feature, features);
    // features.find()
    if (selectedLayer !== undefined && selectedLayer !== null) {
      setSelectedFeatures((prev) => [...prev, {
        id: feature.Id,
        Id: feature.Id,
        name: `${selectedLayer.name} - ${feature.kunta} ${feature.postinumer}`,
        values: Object.keys(feature).map((k) => ({ attributeCode: k, value: feature[k] })),
        layerFeatureId: feature.Id,
      }]);
    }
    console.log(Object.keys(feature).map((k) => ({ code: k, value: feature[k] })));
  };

  const handleRemoveFeature = (feature) => (e) => {
    setSelectedFeatures((prev) => prev.filter((f) => f !== feature));
  };
  const handleChangeFeature = (feature) => (e) => {
    const { name, value } = e.currentTarget;
    setSelectedFeatures((prev) => {
      const existing = prev.find((f) => f === feature);
      existing.name = value;
      return [...prev];
    });
  };
  const toggleSelectedFeature = (feature, status) => {
    if (!status) {
      setSelectedFeatures((prev) => prev.filter((f) => f.Id !== feature.Id));
    } else {
      setSelectedFeatures((prev) => [...prev, {
        id: feature.Id,
        Id: feature.Id,
        name: `${selectedLayer.name} - ${feature.kunta} ${feature.postinumer}`,
        values: Object.keys(feature).map((k) => ({ attributeCode: k, value: feature[k] })),
        layerFeatureId: feature.Id,
      }]);
    }
  };
  const toggleSelectedAttributeName = (attribute, status) => {
    if (!status) {
      setSelectedAttributes((prev) => prev.filter((n) => n.code !== attribute.code));
    } else {
      setSelectedAttributes((prev) => [...prev, { name: attribute.name, code: attribute.code }]);
    }
  };

  const [selectedFeature, setSelectedFeature] = useState(null);

  const queryFeatures = async ({ search }) => {
    console.log(features);
    return features.filter((f) => f.kunta.toLowerCase().includes(search.toLowerCase()));
  };

  const [selectedDataSourceTab, setSelectedDataSourceTab] = useState('map');
  const [currentStep, setCurrentStep] = useState('1');

  const handleChange = (e) => {
    let { value, name } = e.target;
    if (e.target.type === 'checkbox') {
      value = e.target.checked;
    }

    console.log(`${name}=${value}`);
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleCreate = () => {
    console.log(formData);
    onSubmit(formData);
  };

  useEffect(() => {
    console.log('form', formData);
  }, [formData]);

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      title: prev.title,
      description: prev.description,
      graphType: barTypeId,
      mapId: selectedMapId,
      attributeGroupId: selectedAttributeGroupId,
      attributes: selectedAttributes,
      features: selectedFeatures,
      isDistribution: prev.isDistribution,
    }));
  }, [barTypeId, selectedAttributeGroupId, selectedAttributes, selectedFeatures, selectedMapId]);

  return (
    <Form>
      <Nav variant="pills" className="mb-2 justify-content-center" activeKey={currentStep} onSelect={(e) => setCurrentStep(e)}>
        <Nav.Item className="mr-1" key="1">
          <Nav.Link eventKey="1">
            1.
            {t('widget-form.general')}
          </Nav.Link>
        </Nav.Item>
        <Nav.Item className="mr-1" key="2">
          <Nav.Link eventKey="2">
            2.
            {t('widget-form.data-sources')}
          </Nav.Link>
        </Nav.Item>
      </Nav>
      {currentStep === '1' && (
      <>
        <Form.Row>
          <Col>
            <Form.Group controlId="formName">
              <Form.Label>{t('widget-form.title')}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t('widget-form.enter-title')}
                name="title"
                value={formData.title}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formDescription">
              <Form.Label>{t('widget-form.description')}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t('widget-form.enter-description')}
                name="description"
                value={formData.description}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Group controlId="formType">
          <Form.Label>{t('widget-form.type')}</Form.Label>
          <Form.Control as="select" defaultValue="Horizontal Bar" value={barTypeId} onChange={(e) => { setBarTypeId(Number(e.target.value)); }}>
            <option value="0">{t('widget-form.horizontal-bar')}</option>
            <option value="1">{t('widget-form.vertical-bar')}</option>
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="formType">
          <Form.Label>{t('widget-form.map')}</Form.Label>
          <Form.Control as="select" value={selectedMapId} onChange={(e) => { setSelectedMapId(Number(e.target.value)); }}>
            <option value="0">{t('widget-form.select')}</option>
            {selectedProjectMaps.map((m) => <option key={m.id} value={m.id}>{m.name}</option>)}
          </Form.Control>
        </Form.Group>
        <Form.Row>
          <Col>
            {selectedMap && (
            <Form.Group controlId="formType">
              <Form.Label>{t('widget-form.group')}</Form.Label>
              <Form.Control as="select" value={selectedAttributeGroupId} onChange={(e) => { setSelectedAttributeGroupId(Number(e.target.value)); }}>
                <option value="0">{t('widget-form.select')}</option>
                {selectedMap.attributeGroups.map((ag) => <option key={ag.id} value={ag.id}>{ag.name}</option>)}
              </Form.Control>
            </Form.Group>
            )}
          </Col>
          <Col>
            {attributes.length > 0 && (
            <Form.Group controlId="formType">
              <Form.Label>{t('widget-form.attribute')}</Form.Label>
              <br />
              {/* <Form.Control as="select" value={selectedAttributeName} onChange={e => { setSelectedAttributeName(e.target.value) }} >
                                <option value="0">Select...</option>
                                {attributes.map(a => <option key={a.id} value={a.name}>{a.name}</option>)}
                            </Form.Control> */}
              {attributes.map((a) => <Form.Check label={(<span style={{ fontWeight: a.parentId === null && 'bold' }}>{a.name}</span>)} inline type="checkbox" checked={selectedAttributes.map((sa) => sa.code).includes(a.code)} onChange={(e) => toggleSelectedAttributeName(a, e.target.checked)} />)}
            </Form.Group>
            )}
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <Form.Check
              label={t('widget-form.is-distribution')}
              name="isDistribution"
              checked={formData.isDistribution}
              onChange={handleChange}
            />
            {/* formData.isDistribution &&
                            <Form.Group controlId="formType">
                                <Form.Label>{t('widget-form.distribution-attribute-name')}</Form.Label>
                                <Form.Control as="select" value={formData.distributionNormalizeByAttributeName} name="distributionNormalizeByAttributeName" onChange={handleChange} >
                                    {attributes.map(a => <option key={a.id} value={a.name}>{a.name}</option>)}
                                </Form.Control>
                            </Form.Group>
                        */}
          </Col>
        </Form.Row>
      </>
      )}
      {currentStep === '2' && selectedAttributes.length > 0 && (
      <>
        <p>
          {t('widget-form.select-data-info')}
          :
        </p>

        {selectedMap && (
        <>
          <Form.Row>
            <Col>
              <Nav variant="tabs" className="mb-2" activeKey={selectedDataSourceTab} onSelect={(e) => setSelectedDataSourceTab(e)}>
                <Nav.Item className="mr-1" key="map">
                  <Nav.Link eventKey="map">{t('widget-form.map')}</Nav.Link>
                </Nav.Item>
                <Nav.Item className="mr-1" key="table">
                  <Nav.Link eventKey="table">{t('widget-form.table')}</Nav.Link>
                </Nav.Item>
              </Nav>
              <Row>
                <Col>
                  <Form.Control as="select" value={selectedLayerId} onChange={(e) => { console.log(e); setSelectedLayerId(Number(e.target.value)); }}>
                    <option value="0">{t('widget-form.select-layer')}</option>
                    {selectedMap.layers.map((m) => <option key={m.id} value={m.id}>{m.name}</option>)}
                  </Form.Control>
                </Col>
                <Col>
                  <Typeahead value="" onSelect={(f) => { toggleSelectedFeature(f, true); }} source={queryFeatures} resultText="{kunta} - {postinumer}" placeholder={t('Search')} />
                </Col>
              </Row>

              {selectedDataSourceTab === 'map'
                                && (
                                <div className="relative" style={{ height: '500px' }}>
                                  {/* <SelectionMap
                                        currentLayer={selectedLayer}
                                        selected={selectedFeatures.map(sf => Number(sf.layerFeatureId))}
                                        onSelection={handleMapSelection}
                                        attributes={[...selectedAttributes.map(a => a.code), ...['postinumer', 'kunta', 'livcy_i']]}>
                                    </SelectionMap> */}
                                </div>
                                )}
              {selectedDataSourceTab === 'table'
                                && (
                                <div style={{ height: '500px', overflowY: 'scroll' }}>
                                  <Table>
                                    <thead>
                                      <th>#</th>
                                      <th>Kunta</th>
                                      <th>Postinumero</th>
                                      <th>Postinumeroalue</th>
                                    </thead>
                                    <tbody>
                                      {features.sort((a, b) => (a < b ? -1 : 1)).map((f) => (
                                        <tr key={`${f.Id}-${selectedLayerId}`}>
                                          <td style={{ width: '22px' }}>
                                            <Form.Check inline type="checkbox" checked={selectedFeatures.map((sf) => Number(sf.layerFeatureId)).includes(Number(f.Id))} onChange={(e) => toggleSelectedFeature(f, e.target.checked)} />
                                          </td>
                                          <td>{f.kunta}</td>
                                          <td>{f.postinumer}</td>
                                          <td>{f.pnro_alue}</td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </Table>
                                </div>
                                )}
            </Col>
            <Col>
              <Card className="p-2 mb-2" style={{ display: 'block' }}>
                <h5>{t('widget-form.selected-data')}</h5>
                {selectedFeatures.length === 0
                  ? (
                    <p>
                      <FaRegSadTear />
                      {' '}
                      {t('widget-form.no-data')}
                    </p>
                  )
                  : (selectedFeatures.map((a) => (
                    <Form.Row key={a.layerFeatureId} className="mb-1">
                      {/* <Badge variant="primary" className="m-2" inline>
                                            {a.name} <FaTrash onClick={handleRemoveFeature(a)} className="ml-2"></FaTrash>
                                    </Badge> */}
                      <Col style={{ flexGrow: '1' }}>
                        <Form.Control
                          size="sm"
                          type="text"
                          placeholder={a.name}
                          name={a.layerFeatureId}
                          value={a.name}
                          onChange={handleChangeFeature(a)}
                        />
                      </Col>
                      <Col style={{ flexGrow: '0' }}>
                        <Button variant="danger" size="sm" onClick={handleRemoveFeature(a)}><FaTrash /></Button>
                      </Col>
                    </Form.Row>
                  )))}
              </Card>
              <Card className="p-2 mb-2" style={{ display: 'block' }}>
                <h5>{t('widget-form.preview')}</h5>
                <Card>
                  <Card.Header>
                    {formData.title}
                  </Card.Header>
                  <Card.Body>
                    <div style={{ height: '300px' }}>
                      {barTypeId === 0 && <HorizontalBar options={options} data={barData} />}
                      {barTypeId === 1 && <Bar options={options} data={barData} />}
                    </div>
                  </Card.Body>
                </Card>
              </Card>
            </Col>
          </Form.Row>
        </>
        )}
      </>
      )}
      <Form.Row className="mt-2">
        <Col className="justify-content-end" style={{ display: 'flex' }}>
          <ButtonGroup class="text-right">
            {currentStep === '1' && <Button disabled={selectedAttributes.length === 0} onClick={() => setCurrentStep('2')} variant="primary">{t('widget-form.select-data-sources')}</Button>}
            {currentStep === '2' && <Button variant="primary" onClick={handleCreate}>{formData.id === 0 ? t('widget-form.create') : t('widget-form.update')}</Button>}
          </ButtonGroup>
        </Col>
      </Form.Row>
    </Form>
  );
};

export default WidgetForm;
