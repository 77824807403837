import React, { useState, useEffect, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import RegisterForm from './RegisterForm';

const RegisterModal = (props) => {
  const { t } = useTranslation();
  const [showRegisterModal, setShowRegisterModal] = useState(false);

  useEffect(() => {
    setShowRegisterModal(props.show);
  }, [props.show]);

  return (
    <Modal show={showRegisterModal} onHide={() => { props.onHide(); }}>
      <Modal.Header closeButton>
        <Modal.Title>{t('register.create an account')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <RegisterForm onRegister={props.onRegister} />
      </Modal.Body>
    </Modal>
  );
};

export default RegisterModal;
