import React from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  Button,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import LoginForm from '../components/LoginForm';
import { withSettingsStore } from './common/SettingsContext';
import './Login.scss';
import loglevel from '../services/loglevel';

const Login = withSettingsStore(({ login, displayRegisterModal, displayForgotPasswordModal }) => {
  const { t } = useTranslation();
  const location = useLocation();

  if (location.pathname.startsWith('/public')) {
    loglevel.info(location.pathname);
    const accessToken = location.pathname.slice(8);
    const params = new URLSearchParams(location.search);
    const url = params.get('url');
    loglevel.info(accessToken, url);
    login('public', 'public', accessToken, url);
  }

  return (
    <div id="app">
      <div className="hero">
        <h1>Welcome to Livcy by Ramboll</h1>
      </div>
      <Container className="login my-4 font-weight-light">
        <Row className="justify-content-center">
          <Col sm={12} xl={4}>
            <Card>
              <Card.Body>
                <LoginForm onLogin={(username, password) => login(username, password)} />
              </Card.Body>
              <Card.Footer className="text-center">
                <Button variant="link" onClick={() => { displayRegisterModal(); }}>{t('register.create an account')}</Button>
                <Button variant="link" onClick={() => { displayForgotPasswordModal(); }}>{t('register.forgot password')}</Button>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
});

export default Login;
