import React, { useState } from 'react';
import {
  Container, Row, Col, Card, Help, ButtonGroup, Form, Button, Nav, Table, Badge, Alert,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Api from '../services/api';
import { withSettingsStore } from './common/SettingsContext';

const SetPassword = withSettingsStore((props) => {
  const { t } = useTranslation();
  const { login } = props;
  const projectId = props.match.params.token;
  const getEmail = () => {
    const paramsString = window.location.search;
    const params = new URLSearchParams(paramsString);
    return params.get('email');
  };
  const getInvite = () => {
    const paramsString = window.location.search;
    const params = new URLSearchParams(paramsString);
    return params.get('invite');
  };
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState(getEmail().toLowerCase());
  const [password2, setPassword2] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [showMessage, setShowMessage] = useState(false);

  const isInvite = getInvite() !== null;

  const getToken = () => {
    const paramsString = window.location.search;
    const params = new URLSearchParams(paramsString);
    return params.get('token');
  };

  const resetPassword = async () => {
    const token = getToken();
    try {
      await Api().accounts.resetPassword({
        token: isInvite ? getInvite() : token, password, email, isInvite,
      });
      await login(email, password);
      window.location = '/home';
    } catch (error) {
      let message = t('Something went wrong');
      if (error.response.data && error.response.data.errors) {
        const errors = Object.keys(error.response.data.errors);
        message = errors.map((field) => error.response.data.errors[field]).join(', ');
      } else if (error.response.data) {
        message = error.response.data.map((d) => t(d.code)).join(', ');
      }

      setMessage(message);
      setShowMessage(true);
      setLoading(false);
    }
  };

  return (
    <Container>
      <Row>
        <Col xs={{ span: 4, offset: 4 }}>
          <Form>
            <Card className="mt-2">
              <Card.Header>
                {isInvite ? t('reset-form.welcome') : t('reset-form.set-password')}
              </Card.Header>
              <Card.Body>
                <Alert show={showMessage} variant="danger" onClose={() => setShowMessage(false)} dismissible>{message}</Alert>
                {/* <Form.Group>
                                    <Form.Label>{t('reset-form.email')}</Form.Label>
                                    <Form.Control
                                        disabled
                                        type="text"
                                        placeholder={t('reset-form.enter-email')}
                                        name="email"
                                        value={email}
                                        onChange={e => setEmail(e.target.value)} />
                                </Form.Group> */}
                <Form.Group>
                  <Form.Label>{t('reset-form.password')}</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder={t('reset-form.enter-password')}
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>{t('reset-form.password-again')}</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder={t('reset-form.enter-password-again')}
                    name="password2"
                    value={password2}
                    onChange={(e) => setPassword2(e.target.value)}
                  />
                </Form.Group>
              </Card.Body>
              <Card.Footer>
                <Button onClick={() => resetPassword()}>{t('reset-form.set-password')}</Button>
              </Card.Footer>
            </Card>
          </Form>
        </Col>
      </Row>
    </Container>
  );
});

export default SetPassword;
