import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';

const privacyPolicyFi = (
  <>
    <h2 id="tietosuojaseloste"><strong>TIETOSUOJASELOSTE</strong></h2>
    <h2 id="section" />
    <h2 id="rekisterinpitäjä"><strong>REKISTERINPITÄJÄ</strong></h2>
    <p>
      Ramboll Finland Oy
      <br />
      Itsehallintokuja 3
      <br />
      02601 Espoo
    </p>
    <h2 id="yhteyshenkilö-rekisteriasioissa"><strong>YHTEYSHENKILÖ REKISTERIASIOISSA</strong></h2>
    <p>
      Eero Salminen
      <br />
      <a href="mailto:eero.salminen@ramboll.fi">eero.salminen@ramboll.fi</a>
    </p>
    <h2 id="tietosuoja-asioiden-yhteyshenkilö"><strong>TIETOSUOJA-ASIOIDEN YHTEYSHENKILÖ</strong></h2>
    <p>
      Seppo Mattila
      <br />
      <a href="mailto:seppo.mattila@ramboll.fi">seppo.mattila@ramboll.fi</a>
    </p>
    <h2 id="rekisterin-nimi"><strong>REKISTERIN NIMI</strong></h2>
    <p>LIVCY:n käyttäjätunnusrekisteri (jatkossa ”Rekisteri”).</p>
    <h2 id="henkilötietojen-käyttötarkoitus"><strong>HENKILÖTIETOJEN KÄYTTÖTARKOITUS</strong></h2>
    <p>
      Rekisteriin tallennettuja henkilötietoja käytetään järjestelmän käyttäjien tunnistamiseen, järjestelmän
      käyttöoikeuksien jakamiseen ja yhteydenottojen hoitamiseen. Keräämme, käsittelemme ja hyödynnämme henkilötietoja
      henkilötietolain säännösten mukaisesti.
    </p>
    <h2 id="rekisterin-tietosisältö"><strong>REKISTERIN TIETOSISÄLTÖ</strong></h2>
    <p>Rekisteriin kerätään järjestelmän käyttäjistä seuraavia tietoja:</p>
    <p>Nimi</p>
    <p>Sähköpostiosoite</p>
    <p>Matkapuhelinnumero</p>
    <p>Osoite</p>
    <p>Yritys</p>
    <p>Järjestelmän käyttöön liittyvistä tiedoista kerätään kirjautumisajankohta.</p>
    <h2 id="säännönmukaiset-tietolähteet"><strong>SÄÄNNÖNMUKAISET TIETOLÄHTEET</strong></h2>
    <p>Rekisteri koostetaan käyttäjätunnuksen luomisen yhteydessä asiakkaan toimittamista tiedoista.</p>
    <h2 id="tietojen-säännönmukaiset-luovutukset"><strong>TIETOJEN SÄÄNNÖNMUKAISET LUOVUTUKSET</strong></h2>
    <p>
      Rekisterin tietoja ei luovuteta EU:n tai ETA-alueen ulkopuolelle. Rekisterinpitäjä ei luovuta Asiakkaiden
      henkilötietoja ulkopuolisille.
    </p>
    <h2 id="rekisterin-suojauksen-periaatteet"><strong>REKISTERIN SUOJAUKSEN PERIAATTEET</strong></h2>
    <p>
      Henkilötiedot säilytetään luottamuksellisina. Järjestelmän tietoverkko ja laitteisto, jolla rekisteri sijaitsee, on
      suojattu palomuurilla ja muilla tarvittavilla teknisillä toimenpiteillä.
    </p>
    <h2 id="tietojen-poistaminen"><strong>TIETOJEN POISTAMINEN</strong></h2>
    <p>Tietoja voidaan poistaa Asiakkaan vaatimuksesta tai asiakassuhteen päättyessä.</p>
    <h2 id="kielto-oikeus"><strong>KIELTO-OIKEUS</strong></h2>
    <p>
      Asiakkaalla on oikeus kieltää tietojensa luovuttaminen ja käsittely suoramainontaa ja muuta markkinointia varten.
      Rekisterinpitäjä ei luovuta Asiakkaiden henkilötietoja ulkopuolisille vaikka Asiakas ei sitä kieltäisikään.
    </p>
  </>
);

const PrivacyPolicy = () => (
  <Container className="mt-4">
    <Row>
      <Col>
        {privacyPolicyFi}
      </Col>
    </Row>
  </Container>
);

export default PrivacyPolicy;
