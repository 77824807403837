import React from 'react';
import MapHome from './map/Home';
import MapView from './map/MapView';
import Me from '../components/Me';
import ProjectHome from './project/Home';
import Project from './project/Project';
import Compare from './compare/Compare';
import Reports from './reports/Reports';
import './App.css';
import {
  Switch,
  Route,
} from 'react-router-dom';
import NavBar from './common/NavBar';
import Footer from './common/Footer';
import About from './About';
import Login from './Login';
import SetPassword from './SetPassword';

import Admin from './admin/Admin';

import { SettingsStore, withSettingsStore } from './common/SettingsContext';
import PrivacyPolicy from './common/PrivacyPolicy';
import TermsOfUse from './common/TermsOfUse';
import ServiceDescription from './common/ServiceDescription';

const MainNav = withSettingsStore(({ isLoggedIn }) => (
  <div id="app-container">
    <NavBar />
    <section id="content" className="content" style={{ flexDirection: 'column' }}>
      <Switch>
        <Route exact path={['/', '/login']} component={Login} />
        <Route path="/public" component={Login} />
        <Route path="/about" component={About} />
        <Route path="/admin" component={Admin} />
        <Route path="/setpassword" component={SetPassword} />
        <Route path="/privacy-policy" component={PrivacyPolicy} />
        <Route path="/terms-of-use" component={TermsOfUse} />
        <Route path="/service-description" component={ServiceDescription} />
        {isLoggedIn && (
          <>
            <Route exact path={['/home', '/register', '/loginmodal']} component={MapHome} />
            <Route path="/projects" component={ProjectHome} />
            <Route path="/me" component={Me} />
            <Route path="/project/:id" component={Project} />
            <Route path="/map/:id" component={MapView} />
            <Route path="/compare" component={Compare} />
            <Route path="/report/:id" component={Reports} />
          </>
        )}
        {!isLoggedIn && <Route exact path={['/home']} component={Login} />}
      </Switch>
    </section>
    <Footer />
  </div>
));

const App = () => (
  <SettingsStore>
    <MainNav />
  </SettingsStore>
);

export default App;
