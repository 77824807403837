import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const EulaModal = ({ show, onAccept, onReject }) => {
    const {t} = useTranslation()
    return (
        <Modal show={show} size="l">
            <Modal.Header>
                <Modal.Title>{t('eula.title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {/*<Form.Control as="textarea">
                </Form.Control>*/}
                <a target="_blank" href="https://ramboll.com/terms-of-use-and-privacy-policy/Platform-Specific-Terms-Livcy" rel="noreferrer">{t('eula.terms')}</a>
                <br class="my-2" />
                {t('eula.disclaimer')}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onReject}>{t('eula.reject')}</Button>
                <Button onClick={onAccept}>{t('eula.accept')}</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default EulaModal;