import React, { useEffect, useState } from 'react';
import {
  Container,
} from 'react-bootstrap';
import Api from '../services/api';

import UserForm from './UserForm';

function removeNulls(obj) {
  return Object.keys(obj).reduce((acc, cur) => {
    acc[cur] = obj[cur] === null ? '' : obj[cur];
    return acc;
  }, {});
}

const Me = () => {
  const [currentUser, setCurrentUser] = useState(null);
  async function checkHealth() {
    await Api().health.available();
  }
  async function getCurrentUser() {
    const user = await Api().accounts.me.get();
    console.log(user);
    setCurrentUser(removeNulls(user));
  }

  useEffect(() => {
    checkHealth();
    getCurrentUser();
  }, []);

  return (
    currentUser ? (
      <Container className="py-4">
        <UserForm user={currentUser} />
      </Container>
    ) : (<div>Loading...</div>)
  );
};

export default Me;
