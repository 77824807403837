import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// import loglevel from '../services/loglevel'
import {
  Form,
} from 'react-bootstrap';

export default ({ onChange, attributeGroups, layers }) => {
  const { t } = useTranslation();

  const [widget, setWidget] = useState({
    name: '',
    description: '',
    type: null,
    layerName: '',
    attributeName: '',
    alwaysVisible: true,
  });

  const handleChange = (event) => {
    let { value } = event.target;
    let { name } = event.target;

    if (event.target.tagName === 'OPTION') {
      name = event.target.parentElement.name;
    }

    if (value === 'true') {
      value = true;
    }
    if (value === 'false') {
      value = false;
    }

    if (!isNaN(value) && value !== '') {
      value = Number(value);
    }

    setWidget((prev) => ({
      ...prev,
      [name]: value,
    }));

    onChange(widget);
  };

  return (
    <Form>
      <Form.Group controlId="formName">
        <Form.Label>{t('Name')}</Form.Label>
        <Form.Control name="name" value={widget.name} type="text" placeholder={t('Enter name')} onChange={handleChange} />
      </Form.Group>

      <Form.Group controlId="formDescription">
        <Form.Label>{t('Description')}</Form.Label>
        <Form.Control name="description" value={widget.description} type="text" placeholder={t('Enter description')} onChange={handleChange} />
      </Form.Group>

      <Form.Group controlId="formType">
        <Form.Label>{t('Type')}</Form.Label>
        <Form.Control name="type" value={widget.type} type="number" placeholder={t('Enter type')} onChange={handleChange} />
      </Form.Group>

      <Form.Group controlId="layerName">
        <Form.Label>{t('Layer name')}</Form.Label>
        <Form.Control as="select" custom name="layerId">
          <option value="null" onClick={handleChange} />
          {layers.map((ag) => <option selected={ag.id === widget.layerId} value={ag.id} onClick={handleChange}>{ag.name}</option>)}
        </Form.Control>

      </Form.Group>

      <Form.Group controlId="attributeName">
        <Form.Label>{t('Attribute group')}</Form.Label>
        <Form.Control as="select" custom name="attributeGroupId">
          <option value="null" onClick={handleChange} />
          {attributeGroups.map((ag) => <option selected={ag.id === widget.attributeGroupId} value={ag.id} onClick={handleChange}>{ag.name}</option>)}
        </Form.Control>
      </Form.Group>
    </Form>
  );
};
