import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './CircularChart.scss';

const CircularChart = (props) => {
  const [offset, setOffset] = useState(0);
  const {
    size,
    index,
    strokeWidth,
    circleOneStroke,
    circleTwoStroke,
  } = props;

  const center = size / 2;
  const radius = size / 2 - strokeWidth / 2;
  const circumference = 2 * Math.PI * radius;

  useEffect(() => {
    const indexOffset = ((100 - index) / 100) * circumference;
    setOffset(indexOffset);
  }, [setOffset, index, circumference, offset]);

  return (
    <>
      <svg
        className="circularChart"
        width={size}
        height={size}
      >
        <circle
          className="circularChart__shadow"
          stroke={circleOneStroke}
          cx={center}
          cy={center}
          r={radius}
          strokeWidth={strokeWidth}
        />
        <circle
          className="circularChart__circle"
          stroke={circleTwoStroke}
          cx={center}
          cy={center}
          r={radius}
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={offset}
        />
        <text
          className="circularChart__value"
          x={center}
          y={center}
        >
          {index}
        </text>
      </svg>
    </>
  );
};

CircularChart.propTypes = {
  size: PropTypes.number,
  index: PropTypes.number.isRequired,
  strokeWidth: PropTypes.number,
  circleOneStroke: PropTypes.string,
  circleTwoStroke: PropTypes.string,
};

CircularChart.defaultProps = {
  size: 40,
  strokeWidth: 2,
  circleOneStroke: 'currentColor',
  circleTwoStroke: 'currentColor',
};

export default CircularChart;
