import React, { useState } from 'react';
import { Alert, Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const LoginForm = (props) => {
  const { t } = useTranslation();
  const [showError, setShowError] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [validated, setValidated] = useState(false);

  const handleLogin = async (e) => {
    const form = e.currentTarget;

    // initialize
    setMessage('');

    // By default we prevent the default form action
    e.preventDefault();
    e.stopPropagation();

    // Show validation results
    setValidated(true);

    // If everything is fine then go forward
    if (form.checkValidity()) {
      setLoading(true);
      // login
      try {
        await props.onLogin(username, password);
        setLoading(false);
      } catch (error) {
        const resMessage = error.message;
        setLoading(false);
        setMessage(resMessage);
        setShowError(true);
      }
    }
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleLogin}>
      <Alert show={showError} variant="danger" onClose={() => setShowError(false)} dismissible>{t(`error.${message}`)}</Alert>

      <Form.Group>
        <Form.Label htmlFor="username">{t('login.username')}</Form.Label>
        <Form.Control
          required
          type="text"
          defaultValue={username}
          onChange={(e) => setUsername(e.target.value)}
        />
      </Form.Group>

      <Form.Group>
        <Form.Label htmlFor="password">{t('login.password')}</Form.Label>
        <Form.Control
          required
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </Form.Group>

      <Form.Group className="text-right">
        <Button disabled={loading || (password === '' || username === '')} type="submit">
          {loading && (
            <span className="spinner-border spinner-border-sm" />
          )}
          {!loading && (<span>{t('login.login')}</span>)}
        </Button>
      </Form.Group>
    </Form>
  );
};

export default LoginForm;
