import React, {
  useState, useEffect, useCallback, useMemo, useContext,
} from 'react';
import GeoJSON from 'ol/format/GeoJSON';
import WKT from 'ol/format/WKT';
import { getLogger } from 'loglevel';
import { FaSmileBeam } from 'react-icons/fa';
import Api from '../../services/api';
import loglevel from '../../services/loglevel';

import {
  TryNumber, GetMaxExtent, IsExtentWithin, GetExpandingExtents, IsExtentOverlapping,
} from '../common/helpers';
import { SettingsContext } from '../common/SettingsContext';

const MapContext = React.createContext();

const MapStore = ({
  mapId, layerId, attributeGroupId, attributeCode, filters, children,
  onLayerDataChange,
}) => {
  // Map object
  const [map, setMap] = useState(null);

  // Layers contain meta data associated to the layer
  const [layers, setLayers] = useState([]);

  // Layers data contains all feature data associated to layers
  const [layersData, setLayersData] = useState([]);
  const [layersDataLoading, setLayersDataLoading] = useState([]);

  // Internal state objects
  const [cancelTokens, setCancelTokens] = useState([]);
  const isLoadingFeatures = useMemo(() => layersDataLoading
    .map((l) => l.isLoading).filter((l) => l).length > 0, [layersDataLoading]);

  // Selections from UI

  // Currently selected layer. From layers array
  const [selectedLayer, setSelectedLayer] = useState(null);

  // Currently selected attribute. From layer object.
  const [selectedAttribute, setSelectedAttribute] = useState(null);

  // Currently selected attribute group. From layer object.
  const [selectedAttributeGroup, setSelectedAttributeGroup] = useState(null);

  // Currently selected extend [ax,ay,bx,by] format
  const [selectedExtent, setSelectedExtent] = useState(null);

  // Currently selected center of the map
  const [selectedCenter, setSelectedCenter] = useState([0, 0]);

  // Current loading request
  const [requestedLayerData, setRequestedLayerData] = useState({
    extent: null,
    attributes: [],
  });
  const [currentTimeout, setCurrentTimeout] = useState(null);

  const { isInRole } = useContext(SettingsContext);

  // Clear all values in this context
  const clear = () => {
    setLayers([]);
    setLayersData([]);
    setSelectedLayer(null);
    setSelectedAttribute(null);
    setSelectedAttributeGroup(null);
  };

  // Available attribute groups based on the selected layer
  const availableAttributeGroups = useMemo(() => {
    if (map === null || selectedLayer === null) {
      return [];
    }
    const layerAttributeGroups = [
      ...new Set(selectedLayer.attributes.map((a) => a.attributeGroupId)),
    ];
    return map.attributeGroups.filter((ag) => layerAttributeGroups.includes(ag.id));
  }, [map, selectedLayer]);

  // Available attributes based on the selected layer
  const availableAttributes = useMemo(() => {
    if (selectedLayer === null) {
      return [];
    }
    return selectedLayer.attributes;
  }, [selectedLayer]);

  // Available layers based on the map
  const availableLayers = useMemo(() => {
    if (map === null) {
      return [];
    }
    return map.layers.map((l) => ({
      ...l,
      available: isInRole(['Admin', 'User'], map.projectId) || l.name === 'Kunnat',
      selectable: true,
    }));
  }, [map]);

  // Available legends based on the layer and attribute group
  const availableLegends = useMemo(() => {
    if (map === null || selectedLayer === null) {
      return [];
    }
    return map.legends.filter((l) => l.layerId === selectedLayer.id);
  }, [map, selectedLayer]);

  const enabledLayers = useMemo(() => layers.filter((l) => l.enabled), [layers]);

  /* SIDE-EFFECTS ` */

  // If given attributeGroupId changes, change it to this context to (and for all the maps)
  useEffect(() => {
    loglevel.info('changing attribute group');
    const attributeGroup = availableAttributeGroups.find((ag) => ag.id === attributeGroupId);
    if (attributeGroup !== undefined) {
      loglevel.info('selecting', attributeGroup);
      setSelectedAttributeGroup(attributeGroup);
    }
  }, [attributeGroupId]);

  // If given attribute changes, change it to this context to (and for all the maps)
  useEffect(() => {
    const attribute = availableAttributes.find((a) => a.code === attributeCode);
    if (attribute !== undefined) {
      setSelectedAttribute(attribute);
    }
  }, [attributeCode]);

  // If map is changed, change the layer to the default one
  useEffect(() => {
    if (map === null) {
      return;
    }
    // Use the layerId given to the context or the first layer available for the current map
    const lId = layerId !== undefined ? layerId : map.layers.sort((a, b) => a.id - b.id)[0].id;
    toggleLayer({ layerId: lId, enabled: true });
  }, [map]);

  // If layer is changed, but default attribute selection is invalid => change it
  useEffect(() => {
    if (selectedAttributeGroup === null && availableAttributeGroups.length > 0) {
      setSelectedAttributeGroup(availableAttributeGroups[0]);
    }
  }, [selectedAttributeGroup, availableAttributeGroups]);

  // By default load map if id is set
  useEffect(() => {
    if (mapId !== undefined) {
      changeMap({ id: mapId });
    }
  }, [mapId]);

  /* Actions */
  // Helper to convert wkt string into geometry object coordinates
  // Needed for GeoJSON!
  const WKTToGeoJsonCoordinates = (wktString) => new GeoJSON().writeGeometryObject(
    new WKT().readGeometry(wktString),
  ).coordinates;

  // Helper to convert array to object
  // Needed for GeoJSON
  const valueArrayToObject = (arr, attrs) => attrs.reduce((acc, cur, idx) => ({ ...acc, [attrs[idx]]: TryNumber(arr[idx]) }), {});

  const setLayerDataLoading = (lId, state, cancelToken) => {
    const existing = layersDataLoading.find((l) => l.id === lId);
    if (existing === undefined) {
      setLayersDataLoading([...layersDataLoading, {
        id: lId,
        isLoading: state,
        cancelToken,
      }]);
    } else {
      loglevel.info(existing, lId, state, cancelToken);
      if (existing.cancelToken !== undefined && state === false) {
        existing.cancelToken = undefined;
      }
      if (existing.cancelToken !== undefined && state === true) {
        // cancel existing
        loglevel.debug('canceling', existing.cancelToken);
        existing.cancelToken.cancel('New query');
        existing.cancelToken = undefined;
      } else {
        existing.cancelToken = cancelToken;
      }
      existing.isLoading = state;

      setLayersDataLoading([...layersDataLoading.filter((l) => l.id !== lId), existing]);
    }
  };

  // Load layer data based on the given layerId, attribute and extent
  // TODO this should be pieced-up
  const loadLayerData = async ({
    layerId, attribute, extent,
  }) => {
    if (map === null) {
      loglevel.warn('Layer loading cancelled. Map is not yet loaded!');
      return null;
    }

    // lookup existing layer data
    let layerData = layersData.find((l) => l.id === layerId);
    if (layerData === undefined) {
      loglevel.info('Creating new layerdata...', layersData, layerId);
      layerData = {
        id: layerId,
        extents: [],
        attributes: [],
        features: [],
        timestamp: new Date().toString(),
      };
      setLayersData((ld) => [...ld, layerData]);
    }

    const getCancelTokenSource = () => {
      const newCancelTokenSource = Api().client.CancelToken.source();
      // setCancelTokens((tokens) => [...tokens, newCancelTokenSource]);
      return newCancelTokenSource;
    };

    // setLayerDataLoading(layerData.id, false);
    // Cancel existing loads if needed
    /* if (isLoadingFeatures && cancelTokens.length > 0) {
      // cancel
      loglevel.debug('canceling', cancelTokens);
      cancelTokens.forEach((ct) => ct.cancel('New query'));
      setCancelTokens([]);
    } */

    let attributeCodes = [...layerData.attributes.map((a) => a.code), attribute.code];

    // Add attribute codes in filters to queries
    if (filters !== undefined) {
      attributeCodes = [
        ...attributeCodes,
        ...filters.filter((f) => !attributeCodes.includes(f.attributeCode))
          .map((f) => f.attributeCode),
      ];
    }

    // attributeCodes = [...new Set(attributeCodes)];
    attributeCodes = [attribute.code];

    let isLayerWithin = false;
    let existingExtent = null;

    loglevel.info(layerData.extents, attributeCodes);
    let layerDataExtent = layerData.extents.find((e) => attributeCodes.includes(e.attributeCode) && IsExtentWithin(extent, e.coordinates));
    if (layerDataExtent === undefined) {
      // Not expanding. Maybe it is overlapping?
      layerDataExtent = layerData.extents.find((e) => attributeCodes.includes(e.attributeCode) && IsExtentOverlapping(e.coordinates, extent));
      if (layerDataExtent === undefined) {
        layerDataExtent = null;
      } else {
        layerDataExtent = [...layerDataExtent.coordinates];
        existingExtent = layerDataExtent;
      }
    } else {
      loglevel.info('extent is within!');
      isLayerWithin = true;
      layerDataExtent = [...layerDataExtent.coordinates];
      existingExtent = layerDataExtent;
    }

    if (attribute !== undefined && layerData.attributes.find((a) => a.id === attribute.id) === undefined) {
      loglevel.warn('attribute not loaded!', attribute.id, layerData.attributes);
      layerDataExtent = null;
    }
    // Start loading
    try {
      // XXX just load everything without limit
      const limit = 10000000;
      const offset = 0;
      const cancelToken = getCancelTokenSource();

      setLayerDataLoading(layerData.id, true, cancelToken);
      // Load geometries using extents
      const geometryPromises = Promise.all(
        isLayerWithin ? []
          : GetExpandingExtents(layerDataExtent, extent)
            .map((extent) => Api().maps.layer(map.id).geometry({
              layerId: layerData.id,
              extent,
              offset,
              limit,
              filters: Array.isArray(filters) ? filters.map((f) => (`${f.attributeCode}:${f.attributeValue}`)) : [],
            }, cancelToken.token)),
      );

      // Load values using extents
      const valuePromises = Promise.all(GetExpandingExtents(layerDataExtent, extent)
        .map((extent) => Api().maps.layer(map.id).value({
          layerId: layerData.id,
          extent,
          attributes: attributeCodes,
          offset,
          limit,
          filters: Array.isArray(filters) ? filters.map((f) => (`${f.attributeCode}:${f.attributeValue}`)) : [],
        }, cancelToken.token)));

      // Wait for load to finish
      const [geometryRes, valueRes] = await Promise.all([geometryPromises, valuePromises]);
      setLayerDataLoading(layerData.id, false);

      // Map loaded data
      const geometries = geometryRes
        .map((r) => r.items)
        .reduce((acc, cur) => ({ ...acc, ...cur }), {});
      const values = valueRes
        .map((r) => r.items)
        .reduce((acc, cur) => ({ ...acc, ...cur }), {});

      // Map features
      let newFeatures = Object.keys(values)
        .map((featureId) => ({
          id: Number(featureId),
          geometry: geometries[featureId],
          coordinates: !isLayerWithin && WKTToGeoJsonCoordinates(geometries[featureId]),
          values: valueArrayToObject(values[featureId], attributeCodes),
        }));

      // Filter data if needed
      // TODO this should be done on the backend
      if (filters !== undefined) {
        const filter = filters[0];
        newFeatures = newFeatures.filter((f) => f.values[filter.attributeCode] === filter.attributeValue);
        const coordinates = newFeatures
          .map((f) => f.coordinates[0])
          .reduce((acc, cur) => [...acc, ...cur], []);
        if (coordinates.length > 0) {
          const avg = coordinates.reduce((acc, cur) => {
            acc[0] += cur[0];
            acc[1] += cur[1];
            return acc;
          }, [0, 0]).map((v) => v / coordinates.length);

          /*
          const coordinatesX = coordinates.map((c) => c[0]);
          const coordinatesY = coordinates.map((c) => c[0]);
          const extent = [Math.min(...coordinatesX), Math.min(...coordinatesY), Math.max(...coordinatesX), Math.max(...coordinatesY)];
          */

          setSelectedCenter(avg);
        }
      }

      // Update layer data
      // if (layerDataExtent === null) {
      layerData.extents = [...layerData.extents.filter((e) => e !== existingExtent), ...attributeCodes.map((attrCode) => ({ attributeCode: attrCode, coordinates: GetMaxExtent(extent, layerDataExtent) }))];
      // } else {
      // layerData.extents = [...layerData.extents.filter((e) => e.coordinates)];
      // }
      // Merges two sets of features together with values
      const mergeFeatures = (f1, f2) => {
        const f1Ids = f1.map((f) => f.id);
        const f2Ids = f2.map((f) => f.id);
        const f2IdsSet = new Set(f2Ids);

        const intersection = new Set(f1Ids.filter((v) => f2IdsSet.has(v)));

        return [
          ...f1.filter((f) => !intersection.has(f.id)),
          ...f1.filter((f) => intersection.has(f.id)).map((f) => ({
            ...f, values: { ...f.values, ...f2[f2Ids.findIndex((ff) => ff === f.id)].values },
          })),
          ...f2.filter((f) => !intersection.has(f.id)),
        ];
      };

      // Merges two sets of attributes together without duplicates
      const mergeAttributes = (a1, a2) => [...a1, ...a2]
        .filter((a, idx, arr) => arr.map((aa) => aa.id).indexOf(a.id) === idx);

      // Update state
      setLayersData((lsd) => {
        const ld = lsd.find((l) => l.id === layerData.id);
        ld.id = layerData.id;
        const mergedFeatures = mergeFeatures(ld.features, newFeatures);
        layerData.features = mergedFeatures; // HACK
        ld.features = mergedFeatures;
        ld.attributes = mergeAttributes(ld.attributes, [attribute]);
        ld.extents = [...layerData.extents];
        ld.timestamp = new Date().toString();
        return [...lsd.map((ldd) => ({ ...ldd }))];
      });

      // Remove cancel tokens
      setCancelTokens([]);
    } catch (res) {
      loglevel.debug('error', res);
      loglevel.info('error', layerData.id);
      setLayerDataLoading(layerData.id, false);
    }

    // Return layer data fetched with this method
    if (onLayerDataChange !== undefined) {
      onLayerDataChange(layerData);
    }

    return layerData;
  };

  // Load and change map
  const changeMap = useCallback(async ({ id }) => {
    clear();
    const m = await Api().maps.get({ id, filters: filters !== undefined ? filters.map((f) => (`${f.attributeCode}:${f.attributeValue}`)) : [] });
    setSelectedExtent(m.extent);
    setMap(m);
  }, []);

  // Toggle layer by id
  const toggleLayer = useCallback(async ({ layerId, enabled }) => {
    if (map === null) {
      return;
    }

    const existing = layers.findIndex((l) => l.id === layerId);
    if (existing >= 0) {
      setLayers((ls) => {
        const l = ls[existing];
        if (l === undefined) {
          return ls;
        }
        if (enabled !== undefined) {
          l.enabled = enabled;
        } else {
          l.enabled = !l.enabled;
        }
        return [...ls];
      });
      if (enabled !== undefined && enabled) {
        /* await loadLayerData({
          layerId: layers[existing].id,
          attribute: layers[existing].attributes[0],
          extent: selectedExtent,
        });
        */
        setSelectedLayer(layers[existing]);
      }
    } else {
      const layer = await Api().maps.layer(map.id).get({ layerId });

      if (enabled !== undefined) {
        layer.enabled = enabled;
      } else {
        layer.enabled = true;
      }
      setLayers((ls) => ([...ls, layer]));
      /* await loadLayerData({
        layerId: layer.id,
        attribute: layer.attributes[0],
        extent: selectedExtent,
      }); */
      setSelectedLayer(layer);
    }
  }, [map, layers]);

  // Retrieves layer data and loads it if it is needed
  const getLayerData = useCallback(async ({ layerId, attribute }) => {
    // Load
    loglevel.info('loading data', layerId, attribute.code, selectedExtent, layersData, layersData.find((l) => l.id === layerId));

    const found = layersData.find((l) => l.id === layerId
      && l.extents
        .filter((e) => e.attributeCode === attribute.code)
        .find((e) => IsExtentWithin(selectedExtent, e.coordinates)) !== undefined);

    loglevel.info('loading updating found', found);
    if (found !== undefined) {
      return found;
    }
    return loadLayerData({
      layerId,
      attribute,
      extent: [...selectedExtent],
    });
  }, [map, layersData, selectedExtent, currentTimeout]);

  // Context
  const contextState = {
    map,
    availableLayers,
    availableAttributes,
    availableAttributeGroups,
    availableLegends,
    enabledLayers,
    // changeMap,
    toggleLayer,
    selectedAttributeGroup,
    selectedAttribute,
    selectedLayer,
    selectedExtent,
    selectedCenter,
    setSelectedExtent,
    getLayerData,
    isLoadingFeatures,
    filters,
  };

  return (
    <MapContext.Provider value={contextState}>
      {children}
    </MapContext.Provider>
  );
};

export { MapContext, MapStore };
