import React, { useState, useEffect } from 'react';
import {
  Modal, Form, Button, Container, Row, Col, Alert,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Api from '../services/api';

const ForgotPasswordModal = ({ show, onHide }) => {
  const { t } = useTranslation();

  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);

  useEffect(() => {
    setEmail('');
    setIsEmailSent(false);
    setIsLoading(false);
    setShowError(false);
  }, [show]);

  const handleSendResetQuery = async () => {
    setIsLoading(true);
    try {
      await Api().accounts.resetPasswordRequest({ email });
      setIsEmailSent(true);
    } catch {
      setShowError(true);
    }
    setIsLoading(false);
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{t('reset-password-form.reset-password')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isEmailSent && <Alert variant="info">{t('reset-password-form.email-sent')}</Alert>}
        {showError && <Alert variant="danger">{t('reset-password-form.something-went-wrong')}</Alert>}
        {!isEmailSent ? (
          <>
            <Form.Group controlId="formEmail">
              <Form.Label>{t('reset-password-form.email')}</Form.Label>
              <Form.Control name="email" defaultValue={email} type="email" placeholder={t('reset-password-form.email')} onChange={(e) => setEmail(e.target.value)} />
            </Form.Group>
            <Button disabled={isLoading} onClick={handleSendResetQuery}>
              {isLoading && (
                <span className="spinner-border spinner-border-sm mr-2" />
              )}
              {t('reset-password-form.submit')}
            </Button>
          </>
        ) : <Button onClick={onHide}>{t('reset-password-form.close')}</Button>}
      </Modal.Body>
    </Modal>
  );
};

export default ForgotPasswordModal;
