import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Form,
} from 'react-bootstrap';
import Api from '../services/api';
import { Layers } from './OpenLayersMap';

const ProjectForm = (props) => {
  const { t } = useTranslation();
  const create = !!props.create;
  const [isCopying, setIsCopying] = useState(false);
  const [projects, setProjects] = useState([]);
  const [attributeName, setAttributeName] = useState('');
  const [attributeValueSearch, setAttributeValueSearch] = useState('');

  const [selectedProjectId, setSelectedProjectId] = useState(0);
  const [selectedMapId, setSelectedMapId] = useState(0);
  const [selectedLayerId, setSelectedLayerId] = useState(0);
  const [selectedMaps, setSelectedMaps] = useState([]);
  const [selectedLayers, setSelectedLayers] = useState([]);
  const [project, setProject] = useState({
    name: '',
    description: '',
    id: -1,
    copy: false,
    copyAttributeName: '',
    copyAttributeQuery: '',
    ...props.project,
  });

  const getMaps = async (projectId) => await Api().maps.get({ projectId });
  const getLayers = async (mapId) => await Api().maps.layer(mapId).get({});

  const getProject = async (projectId) => await Api().projects.get({ projectId });
  const getProjects = async () => await Api().projects.get({});

  const allAttributes = useMemo(() => selectedLayers.map((l) => l.attributes).reduce((acc, cur) => [...acc, ...cur], []).filter((a) => a.attributeGroupId > 0).filter((a, idx, arr) => arr.map((at) => at.name).indexOf(a.name) === idx)
    .sort((a, b) => (a.name < b.name ? -1 : 1)), [selectedLayers]);

  useEffect(() => {
    if (selectedMapId > 0) {
      getLayers(selectedMapId).then((m) => setSelectedLayers(m));
    }
  }, [selectedMapId]);
  useEffect(() => {
    if (selectedProjectId > 0) {
      getMaps(selectedProjectId).then((m) => setSelectedMaps(m));
    }
  }, [selectedProjectId]);
  useEffect(() => {
    getProjects().then((r) => setProjects(r));
  }, []);
  useEffect(() => {
    console.log('change!', props.project);
    setProject(props.project);
  }, [props.project]);

  useEffect(() => {
    setProject((prev) => ({
      ...prev,
      copy: isCopying,
      copyProjectId: Number(selectedProjectId),
      copyMapId: Number(selectedMapId),
      copyAttributeName: attributeName,
      copyAttributeQuery: attributeValueSearch,
    }));
    props.onChange(project);
  }, [attributeName, attributeValueSearch, isCopying]);

  const handleChange = (event) => {
    project[event.target.name] = event.target.value;
    setProject(project);

    props.onChange(project);
  };

  return (
    <Form>
      <Form.Group controlId="formName">
        <Form.Label>{t('Name')}</Form.Label>
        <Form.Control name="name" defaultValue={project.name} type="text" placeholder={t('Enter name')} onChange={handleChange} />
      </Form.Group>
      <Form.Group controlId="formDescription">
        <Form.Label>{t('Description')}</Form.Label>
        <Form.Control name="description" defaultValue={project.description} type="text" placeholder={t('Enter description')} onChange={handleChange} />
      </Form.Group>
      {create && (
      <>
        <Form.Group>
          <Form.Check label={t('Use existing project as base')} inline type="checkbox" checked={isCopying} onChange={(e) => setIsCopying(e.target.checked)} />
        </Form.Group>
        {isCopying && (
        <>
          <Form.Group>
            <Form.Label>{t('Project')}</Form.Label>
            <Form.Control as="select" defaultValue={selectedProjectId} custom onChange={(e) => setSelectedProjectId(e.target.value)}>
              <option value="0" />
              {projects.map((p) => <option value={p.id}>{p.name}</option>)}
            </Form.Control>
          </Form.Group>
          {selectedProjectId > 0 && (
          <>
            <h5>Filter by</h5>
            <Form.Group>
              <Form.Label>{t('Map')}</Form.Label>
              <Form.Control as="select" defaultValue={selectedMapId} custom onChange={(e) => setSelectedMapId(e.target.value)}>
                <option value="0" />
                {selectedMaps.map((a) => <option value={a.id}>{a.name}</option>)}
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>{t('Attribute')}</Form.Label>
              <Form.Control as="select" defaultValue={attributeName} custom onChange={(e) => setAttributeName(e.target.value)}>
                <option value="0" />
                {allAttributes.map((a) => <option value={a.code}>{a.name}</option>)}
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>{t('Value')}</Form.Label>
              <Form.Control name="attributeValue" defaultValue={attributeValueSearch} type="text" placeholder={t('Enter attribute value')} onChange={(e) => setAttributeValueSearch(e.target.value)} />
            </Form.Group>
          </>
          )}

        </>
        )}
      </>
      )}
    </Form>
  );
};
export default ProjectForm;
