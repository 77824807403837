import React, {
  useEffect, useState, useMemo, useCallback,
} from 'react';
import {
  Card, Container, Row, Col, Button, ButtonToolbar, Table, Form, Spinner,
} from 'react-bootstrap';
import { FaPrint, FaPen, FaSave } from 'react-icons/fa';
import GeoJSON from 'ol/format/GeoJSON';
import WKT from 'ol/format/WKT';
import {
  IconCommerce, IconCycling, IconExercise, IconFilm, IconHealth, IconTree,
} from '../../assets/icons/LivcyIcons';

import './Reports.scss';
import Api from '../../services/api';
import { TryNumber } from '../common/helpers';
import CircularChart from '../map/CircularChart';
import RambollLogo from '../../assets/report/logo-white-ramboll.svg';
import loglevel from '../../services/loglevel';
import ReportMapView from './ReportMapView';

const Reports = (props) => {
  const reportId = props.match.params.id;
  const [report, setReport] = useState(null);
  const [mapData, setMapData] = useState(null);
  const [map, setMap] = useState(null);

  useEffect(async () => {
    const r = await Api().reports.get({ reportId });
    setReport(r);
  }, [reportId]);

  const print = () => {
    window.print();
  };

  const mapId = useMemo(() => (report !== null ? report.mapId : 0), [report]);
  const layerId = useMemo(() => (report !== null ? report.layerId : 0), [report]);
  const layer = useMemo(() => ((mapData !== null) ? mapData.layer : null), [mapData]);
  const extent = useMemo(() => ((map !== null) ? map.extent : null), [map]);
  const attributeGroup = useMemo(() => ((map !== null) ? map.attributeGroups.find((ag) => ag.name === 'Livcy-indeksi') ?? null : null), [map]);

  // Helper to convert wkt string into geometry object coordinates
  // Needed for GeoJSON!
  const valueArrayToObject = (arr, attrs) => attrs.reduce((acc, cur, idx) => ({ ...acc, [cur]: TryNumber(arr[idx]) }), {});
  const WKTToGeoJsonCoordinates = (wktString) => new GeoJSON().writeGeometryObject(
    new WKT().readGeometry(wktString),
  ).coordinates;

  const createFeatures = ({ geometries, values, attributeCodes }) => Object.keys(values)
    .map((featureId) => ({
      id: Number(featureId),
      geometry: geometries[featureId],
      coordinates: WKTToGeoJsonCoordinates(geometries[featureId]),
      values: valueArrayToObject(values[featureId], attributeCodes),
    }));

  const layerData = useMemo(() => ((mapData !== null) ? ({
    id: layerId,
    extent: null,
    extents: [],
    attributes: mapData.attributeCodes,
    features: createFeatures(mapData),
    timestamp: new Date().toString(),
  }) : null), [mapData]);

  const filters = useMemo(() => (report !== null ? report.filters.map((f) => {
    if (f.attributeCode === 'kunta') {
      return {
        ...f,
        attributeCode: 'kunta_1',
      };
    }
    return f;
  }) : []), [report]);

  const year = 2021;

  const [summary, setSummary] = useState({
    columns: [[{
      title: 'Livcy-indeksi', text: 'Tulossa...',
    }]],
  });
  const [isInEditMode, setIsInEditMode] = useState(false);

  const livcyRatingTexts = [
    'heikolla tasolla. Palvelut eivät ole kävellen saavutettavissa.​',
    'tyydyttävällä tasolla. Lähes kaikki palvelut ovat pitkän kävelymatkan etäisyydellä (25 min) tai pyörällä saavutettavissa.​',
    'kohtalaisella tasolla. Lähes kaikki palvelut ovat alle 20 minuutin kävelymatkan etäisyydellä.​',
    'hyvällä tasolla. Lähes kaikki palvelut ovat alle 15 minuutin kävelymatkan etäisyydellä.​',
    'erinomaisella tasolla. Lähes kaikki palvelut ovat alle 10 minuutin kävelymatkan etäisyydellä.​',
    'huipputasolla. Lähes kaikki palvelut ovat alle 5 minuutin kävelymatkan etäisyydellä.​',
  ];

  const getRatingValue = (number) => {
    let rating = 0;

    if (number <= 100) {
      rating = 5;
    }
    if (number < 95) {
      rating = 4;
    }
    if (number < 85) {
      rating = 3;
    }
    if (number < 70) {
      rating = 2;
    }
    if (number < 50) {
      rating = 1;
    }
    if (number < 25) {
      rating = 0;
    }

    return rating;
  };

  const [municipalityData, setMunicipalityData] = useState([]);
  const currentMunicipalityData = useMemo(() => (municipalityData.length > 0 ? municipalityData[0] : null),
    [municipalityData]);

  const getData = useCallback((attributeCode) => {
    if (currentMunicipalityData === null) {
      return '';
    }
    return Math.round(currentMunicipalityData[attributeCode]);
  }, [currentMunicipalityData]);

  const city = useMemo(() => (currentMunicipalityData !== null ? currentMunicipalityData.kunta : ''), [currentMunicipalityData]);
  const livcyIndex = useMemo(() => (currentMunicipalityData !== null ? Math.round(currentMunicipalityData.livcy_i) : 0), [currentMunicipalityData]);

  const getHighlightData = (pageName) => report?.sections.find((s) => s.pageName === pageName && s.sectionName === 'highlight');
  const getHighlight = (pageName) => ({
    title: getHighlightData(pageName)?.title,
    description: getHighlightData(pageName)?.body,
  });

  const reports = useMemo(() => [{
    icon: IconCommerce,
    code: 'commerce',
    title: 'Kauppa ja palvelut',
    index: getData('kaup_liv'),
    attributeCode: 'kaup_liv',
    indexDescription: 'Mittaa kauppojen ja palveluiden saavutettavuutta ja palvelutiheyttä',
    heading: `Elävyysindeksi painotettuna kauppaan ja palveluihin alueella ${city}`,
    description: [
      'Tiivis ja sekoittunut, mutta ihmisen mittakaavaan suunniteltu kaupunkirakenne, jossa koti sijaitsee lähellä työpaikkaa ja arjen perustarpeita tukevia palveluita luo kysyntää ja mahdollistaa paikallistason yrityksien toiminnan. Palveluiden korkea saavutettavuus taas lisää elämänlaatua ja edesauttaa kestävämpien liikkumismuotojen valintaa sekä lisää aktiivisuutta.',
      'LIVCY mittaa alueen kaupallisia palveluita päivittäistavarakauppojen, apteekkien, ravintoloiden, kahviloiden ja baarien, erikoistavarakauppojen ja –palveluiden sekä postipalveluiden osalta. Elävyyden kannalta olennaista on palveluiden saavutettavuus, joka mitataan kävelyetäisyytenä minuutteina lähimpään palveluun tiedossa olevaa tieverkostoa pitkin. Lisäksi mitataan palveluiden tiheyttä, sillä alueen elävyys kasvaa, mitä monimuotoisempia palveluita siellä sijaitsee.',
    ],
    results: [
      `Kuntasi elävyysindeksi painotettuna kauppaan ja palveluihin on ${getData('kaup_liv')} pistettä eli ${livcyRatingTexts[getRatingValue(getData('kaup_liv'))]}`,
      'Postinumeroaluiden välillä asukkaiden saavutettavuus palveluihin saattaa vaihdella. Tarkempi tarkastelu ja alueiden välinen vertailutieto saatavilla LIVCY-työkalusta.',
    ],
    highlight: getHighlight('commerce'),
  }, {
    icon: IconHealth,
    code: 'health',
    title: 'Koulutus- ja terveyspalvelut',
    attributeCode: 'sos_liv',
    index: getData('sos_liv'),
    indexDescription: 'Mittaa koulutus- ja terveyspalveluiden saavutettavuutta ja palvelutiheyttä',
    heading: `Elävyysindeksi painotettuna koulutus- ja terveyspalveluihin alueella ${city}`,
    description: [
      'Toimivassa arjessa ja elävässä yhteisössä korostuu myös pääsy julkisiin palveluihin, kuten terveydenhoitoon tai kouluun nopeasti, turvallisesti, esteettömästi ja lähelle. Eri väestöryhmien tarpeista riippuen, palveluvalikoiman tulee olla myös suhteellisen laaja. Elävät kaupunkialueet tarjoavat hyvän saavutettavuuden useiden julkisten palveluiden äärelle, mikä taas parantaa asukkaiden hyvinvointia, onnellisuutta ja tyytyväisyyttä.',
      'LIVCY mittaa alueen koulutus- ja terveyspalveluita kunnallisen ja yksityisen puolen päiväkotien, peruskoulujen, lukioiden ja julkisen terveydenhuollon osalta. Elävyyden kannalta olennaista on palveluiden saavutettavuus, joka mitataan kävelyetäisyytenä minuutteina lähimpään palveluun tiedossa olevaa tieverkostoa pitkin. Lisäksi mitataan palveluiden tiheyttä, sillä alueen elävyys kasvaa, mitä monimuotoisempia palveluita siellä sijaitsee.',
    ],
    results: [
      `Kuntasi elävyysindeksi painotettuna koulutus- ja terveyspalveluihin on ${getData('sos_liv')} pistettä eli ${livcyRatingTexts[getRatingValue(getData('sos_liv'))]}`,
      'Postinumeroaluiden välillä asukkaiden saavutettavuus palveluihin saattaa vaihdella. Tarkempi tarkastelu ja alueiden välinen vertailutieto saatavilla LIVCY-työkalusta.',
    ],
    highlight: getHighlight('health'),
  }, {
    icon: IconCycling,
    code: 'cycling',
    title: 'Liikenne ja liikkuminen',
    attributeCode: 'liike_liv',
    index: getData('liike_liv'),
    indexDescription: 'Mittaa liikenteen ja liikkumisen saavutettavuutta ja palvelutiheyttä',
    heading: `Elävyysindeksi painotettuna liikenteeseen ja liikkumiseen alueella ${city}`,
    description: [
      'Nykyään liikenne ja liikkuminen tuottaa valtaosan ympäristölle ja ihmisille aiheutuneista haitoista. Se on yksi suurimmista hiilidioksidipäästöjen aiheuttajista, tuottaa asuinympäristöön melua, huonontaa ilmanlaatua ja aiheuttaa stressiä. Toisaalta taas luotettava ja edullinen julkinen liikenne pyöräilyn ja kävelyn ohella vähentää ilmansaasteita ja ruuhkia, lisää tuottavuutta ja vähentää eriarvoisuutta. Hyvä liikenneverkosto yhdistää paikat ja toiminnot toisiinsa, ja sen laatu, turvallisuus, saavutettavuus ja tasavertaisuus vaikuttaa suuresti siihen, miten asukkaiden arki sujuu.',
      'Kestävät liikkumismuodot tarjoavatkin tehokkaan keinon elävän ja saavutettavan kaupunkialueen mahdollistamisessa, sillä ne edistävät sekä ekologista että sosiaalista kestävyyttä.',
      'LIVCY mittaa alueen liikennettä ja liikkumista joukkoliikenteen palveluiden osalta. Tällöin huomioidaan alueella olevien  hyvän palvelutason joukkoliikenteen pysäkkien (yli 3 lähtöä arkena klo 7-19) saavutettavuus ja määrä.',
    ],
    results: [
      `Kuntasi elävyysindeksi painotettuna liikenteeseen ja liikkumiseen on ${getData('liike_liv')} pistettä eli ${livcyRatingTexts[getRatingValue(getData('liike_liv'))]}`,
      'Postinumeroaluiden välillä asukkaiden saavutettavuus palveluihin saattaa vaihdella. Tarkempi tarkastelu ja alueiden välinen vertailutieto saatavilla LIVCY-työkalusta.',
    ],
    highlight: getHighlight('cycling'),
  }, {
    icon: IconTree,
    code: 'tree',
    title: 'Viheralueet',
    attributeCode: 'viher_liv',
    index: getData('viher_liv'),
    indexDescription: 'Mittaa viheraluiden saavutettavuutta ja palvelutiheyttä',
    heading: `Elävyysindeksi painotettuna viheraluisiin alueella ${city}`,
    description: [
      'Suomalaisille suosituin vapaa-ajan liikuntaympäristö on luonto, joten on loogista, että sen saavutettavuus ja viheralueiden läheisyys myös kaupunkialueella parantaa suoraan kokemusta paikan laadukkuudesta ja elävyydestä. Lisäksi ihmiset ovat valmiita maksamaan vehreydestä ja palveluiden läheisyydestä oman kotinsa läheisyydessä.',
      'Viheralueet eivät ainoastaan lisää alueen viihtyvyyttä ja elävyyttä, mutta myös parantavat mikroilmastoa, ilman laatua ja vähentävät melua. Luonnossa liikkuminen ja pelkästään sen näkeminen koti-ikkunasta vähentää tutkimusten mukaan stressitasoja.',
      'Viheralueiden osalta LIVCY-indeksi indikoi parhaiten kaupunkimaisten ympäristöjen elävyydestä, joissa rakennetuilla viherympäristöillä on tärkeä rooli alueen viihtyisyyden ja ihmisten hyvinvoinnin sekä luonnon monimuotoisuuden säilyttämisen ja alueen ekologian kannalta.',
      'LIVCY mittaa alueen viheralueita puistojen, virkistysreittien ja leikkikenttien saavutettavuutta ja määrää. Mittariin ei sisälly metsä- tai vesistöalueet. ',
    ],
    results: [
      `Kuntasi elävyysindeksi painotettuna viheralueisiin on ${getData('viher_liv')} pistettä eli ${livcyRatingTexts[getRatingValue(getData('viher_liv'))]}`,
      'Postinumeroaluiden välillä asukkaiden saavutettavuus palveluihin saattaa vaihdella. Tarkempi tarkastelu ja alueiden välinen vertailutieto saatavilla LIVCY-työkalusta.',
    ],
    highlight: getHighlight('tree'),
  }, {
    icon: IconExercise,
    code: 'exercise',
    title: 'Liikuntapalvelut',
    attributeCode: 'liiku_liv',
    index: getData('liiku_liv'),
    indexDescription: 'Mittaa liikuntapalveluiden saavutettavuutta ja palvelutiheyttä',
    heading: `Elävyysindeksi painotettuna liikuntapalveluihin alueella ${city}`,
    description: [
      'Arkielämään kuuluu työn ohella myös vapaa-aika, jota voidaan elävyyttä suunnitellessa tukea monin eri tavoin. Erilaisten liikunta- ja harrastus-mahdollisuuksien ja -paikkojen tarjoaminen tukee monipuolisesti kaupunkilaisten fyysistä ja psyykkistä terveyttä. Kun omasta naapurustosta löytyy myös vapaa-ajan palveluita, tulee arjesta sujuvaa ja hyvinvointiin kannustavaa.',
      'Liikuntapalvelut ja harrastemahdollisuudet ovat myös alueelle vetovoimatekijöitä, jotka lisäävät alueen houkuttelevuutta asukkaille peruspalvelujen ohella.',
      'LIVCY mittaa alueen liikuntapalveluita kuntosalien ja –keskuksien, lähiliikuntapaikkojen, liikuntahallien, pallokenttien sekä uimapaikkojen osalta. Mittarissa huomioidaan palveluiden ja paikkojen saavutettavuus (kävelyaika minuutteina) sekä palveluiden monipuolisuus alueella.',
    ],
    results: [
      `Kuntasi elävyysindeksi painotettuna liikuntapalveluihin on ${getData('liiku_liv')} pistettä eli ${livcyRatingTexts[getRatingValue(getData('liiku_liv'))]}`,
      'Postinumeroaluiden välillä asukkaiden saavutettavuus palveluihin saattaa vaihdella. Tarkempi tarkastelu ja alueiden välinen vertailutieto saatavilla LIVCY-työkalusta.',
    ],
    highlight: getHighlight('exercise'),
  }, {
    icon: IconFilm,
    code: 'film',
    title: 'Kulttuuri',
    attributeCode: 'kult_liv',
    index: getData('kult_liv'),
    indexDescription: 'Mittaa kulttuuripalveluiden saavutettavuutta ja palvelutiheyttä',
    heading: `Elävyysindeksi painotettuna kulttuuriin alueella ${city}`,
    description: [
      'Viihtyvyyden ja elävyyden kannalta kulttuuri on tärkeä osa arjen kokemuksellisuutta. Vapaa-ajalla virikkeiden tarjoaminen luo laadukasta ympäristöä, jossa kaupunkilaisille taataan elämyksiä erilaisin tavoin. Kulttuuripalvelut kasvattavat henkistä pääomaa, mutta myös luovat tiloja sosiaalisille kohtaamisille.',
      'LIVCY mittaa kulttuuria esittävän taiteen (teatterit, elokuvateatterit), museoiden ja gallerioiden sekä kirjastojen osalta. Mittarissa tarkastellaan kulttuuripalveluiden saavutettavuutta (matka-aika kävellen minuutteina) sekä palveluiden monipuolisuutta alueella.',
    ],
    results: [
      `Kuntasi elävyysindeksi painotettuna kulttuuriin on ${getData('kult_liv')} pistettä eli ${livcyRatingTexts[getRatingValue(getData('kult_liv'))]}`,
      'Postinumeroaluiden välillä asukkaiden saavutettavuus palveluihin saattaa vaihdella. Tarkempi tarkastelu ja alueiden välinen vertailutieto saatavilla LIVCY-työkalusta.',
    ],
    highlight: getHighlight('film'),
  },
  ], [currentMunicipalityData, report]);

  const retrieveReportMapData = useCallback(async () => {
    const m = await Api().maps.get({ id: report.mapId, filters: report.filters !== undefined ? report.filters.map((f) => (`${f.attributeCode}:${f.attributeValue}`)) : [] });
    setMap(m);
    const attributeCodes = ['kunta', 'livcy_i', ...reports.map((r) => r.attributeCode)];
    const valuesRes = await Api().reports.value({ reportId, attributes: attributeCodes });

    /*
    const valuesRes = await Api().maps.layer(mapId).value({
      layerId,
      extent: [0, 0, 0, 0],
      attributes: attributeCodes,
      filters: filters.map((f) => (`${f.attributeCode}:${f.attributeValue}`)),
    });
    */

    loglevel.info('values', valuesRes);

    const values = Object.keys(valuesRes.items).map((featureId) => valueArrayToObject(valuesRes.items[featureId], attributeCodes));
    setMunicipalityData(values);

    const rd = await Api().reports.mapdata({ reportId, attributes: attributeCodes });

    setMapData(rd);
  }, [mapId, layerId]);

  useEffect(() => {
    if (mapId > 0) {
      retrieveReportMapData();
    }
  }, [report]);

  return (
    <Container id="reports" fluid>
      <ButtonToolbar>
        <Button onClick={print}>
          <FaPrint />
          {' '}
          Tulosta
        </Button>
      </ButtonToolbar>
      {mapId > 0 && filters.length > 0 && (
        <div className="report">
          <Card className="title-bg">
            <Card.Title>
              <h1>LIVCY by Ramboll Elävyysindeksi-raportti</h1>
              <h2>
                {currentMunicipalityData === null ? <Spinner animation="border" size="sm" /> : `${city} ${year}`}
              </h2>
              <div className="ramboll-logo" />
            </Card.Title>
          </Card>
          <Card>
            <Card.Title>
              <h2 className="h1 mr-5">Elävyyden tarkastelu rakennetussa ympäristössä</h2>
            </Card.Title>
            <Card.Text as="div">
              <Row className="livcy-list">
                <Col className="livcy-list__item">
                  <div className="icon-circle commerce">
                    <IconCommerce />
                  </div>
                  <div className="livcy-list__title">Kauppa ja palvelut</div>
                </Col>
                <Col className="livcy-list__item">
                  <div className="icon-circle health">
                    <IconHealth />
                  </div>
                  <div className="livcy-list__title">Koulutus- ja terveyspalvelut</div>
                </Col>
                <Col className="livcy-list__item">
                  <div className="icon-circle cycling">
                    <IconCycling />
                  </div>
                  <div className="livcy-list__title">Liikenne ja liikkuminen</div>
                </Col>
                <Col className="livcy-list__item">
                  <div className="icon-circle tree">
                    <IconTree />
                  </div>
                  <div className="livcy-list__title">Viheralueet</div>
                </Col>
                <Col className="livcy-list__item">
                  <div className="icon-circle exercise">
                    <IconExercise />
                  </div>
                  <div className="livcy-list__title">Liikuntapalvelut</div>
                </Col>
                <Col className="livcy-list__item">
                  <div className="icon-circle film">
                    <IconFilm />
                  </div>
                  <div className="livcy-list__title">Kulttuuri</div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p>
                    Elävät naapurustot ovat paljon puhuttu aihe – mutta elävyyden mittaaminen
                    rakennetussa ympäristössä on haastavaa sen monimuotoisen ja näkökulmasta
                    riippuvan luonteen takia.
                  </p>
                  <p>
                    LIVCY by Ramboll on Rambollin monialaisen asiantuntijaryhmän kehittämä
                    ja tieteelliseen tutkimukseen perustuva elävyyden mittaamisen malli,
                    palvelu sekä digitaalinen sovellus rakennetun ympäristön elävyyden
                    mittaamiseen, kehitykseen ja seuraamiseen. LIVCY-elävyysindeksi antaa
                    mitattavat selitteet alueenne kestävyyttä tukeville elinvoimatekijöille.
                  </p>
                  <p>
                    Kaupunkisuunnittelun näkökulmasta elävyys lisää alueen vetovoimaa ja
                    kannattavuus-elementtejä. LIVCY-malli tutkii elävyyttä viiden
                    ulottuvuuden kautta:
                  </p>
                  <ul>
                    <li>asukaslähtöisyys​</li>
                    <li>saavutettavuus​</li>
                    <li>turvallisuus​</li>
                    <li>viihtyvyys ja laatu sekä </li>
                    <li>terveys ja hyvinvointi.</li>
                  </ul>
                  <p>
                    LIVCY yhdistää nämä ulottuvuudet kaupungin rakennuspalikoihin - liikkumiseen,
                    kaupunkirakenteeseen, toimintoihin sekä ihmisten kokemuksiin ja käyttäytymiseen
                    – ja näiden yhteisvaikutuksesta saadaan aikaan elävyyttä, joka luo
                    kokonaisuutena kestävän elinympäristön.
                  </p>
                </Col>
                <Col>
                  <p>
                    Käytännössä elävyys arjessa tarkoittaa, että elämän ja ihmisen perustarpeiden
                    mukaiset välttämättömimmät palvelut ja toiminnot, kuten ruokakaupat,
                    terveydenhuollon palvelut tai viheralueet, ovat helposti saavutettavissa ja
                    niitä on monipuolisesti. Alueiden elävyyden parantaminen lisäämällä erilaisia
                    palveluita sekä niiden saavutettavuutta mahdollistaa myös kestävät
                    liikkumismuodot ja vastaa moniin sosiaalisiin, ekologisiin ja taloudellisiin
                    haasteisiin lisäten samalla alueiden viihtyisyyttä ja asukkaiden positiivisia
                    kokemuksia ja terveyttä.
                  </p>
                  <p>
                    Tästä raportissa löydät koosteen alueenne elävyydestä. Raportti sisältää
                    LIVCY-elävyysindeksin sekä nykytila-analyysin kuuden elävyyden kategorian
                    kautta:
                  </p>
                  <ul>
                    <li>Kauppa ja palvelut​</li>
                    <li>Koulutus- ja terveyspalvelut​</li>
                    <li>Liikenne ja liikkuminen​</li>
                    <li>Viheralueet​</li>
                    <li>Liikuntapalvelut sekä​</li>
                    <li>Kulttuuri. </li>
                  </ul>
                  <p>
                    Näiden avulla löydät kuntasi vahvuudet ja saat työkalun kuntasi elävyydestä
                    ja elinvoimasta viestimiseen ja tulevaisuuden kehityksen tueksi.
                  </p>
                  <p>
                    <strong>Tekijänoikeudet: </strong>
                    Ramboll Finland Oy
                  </p>
                </Col>
              </Row>
            </Card.Text>
          </Card>

          <Card>
            <Card.Title>
              LIVCY-Elävyysindeksi
              <div>
                <small>{`— Kokonaiselävyys kunnassa ${city}`}</small>
              </div>
            </Card.Title>
            <Card.Text as="div">
              <Row>
                <Col>
                  <div className="livcy-list__container">
                    <div className="livcy-list__index">
                      <CircularChart
                        size={Number(120)}
                        index={Number(livcyIndex)}
                        strokeWidth={Number(6)}
                      />
                    </div>
                    <Row className="livcy-list livcy-list--index">
                      <Col className="livcy-list__item">
                        <div className="commerce">
                          <CircularChart index={Number(getData('kaup_liv'))} />
                        </div>
                        <div className="livcy-list__title">Kauppa ja palvelut</div>
                      </Col>
                      <Col className="livcy-list__item">
                        <div className="health">
                          <CircularChart index={Number(getData('sos_liv'))} />
                        </div>
                        <div className="livcy-list__title">Koulutus- ja terveyspalvelut</div>
                      </Col>
                      <Col className="livcy-list__item">
                        <div className="cycling">
                          <CircularChart index={Number(getData('liike_liv'))} />
                        </div>
                        <div className="livcy-list__title">Liikenne ja liikkuminen</div>
                      </Col>
                      <Col className="livcy-list__item">
                        <div className="tree">
                          <CircularChart index={Number(getData('viher_liv'))} />
                        </div>
                        <div className="livcy-list__title">Viheralueet</div>
                      </Col>
                      <Col className="livcy-list__item">
                        <div className="exercise">
                          <CircularChart index={Number(getData('liiku_liv'))} />
                        </div>
                        <div className="livcy-list__title">Liikuntapalvelut</div>
                      </Col>
                      <Col className="livcy-list__item">
                        <div className="film">
                          <CircularChart index={Number(getData('kult_liv'))} />
                        </div>
                        <div className="livcy-list__title">Kulttuuri</div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <section>
                    <h6>Mittari</h6>
                    <p>
                      LIVCY-elävyysindeksi on ns. yhdistelmäindeksi, joka laskee yhteen kaikkien
                      kuuden elävyyskategorian pisteet. Indeksi kuvaa alueen elävyyttä pistevälillä
                      0-100 ja huomioi elävyyden kannalta keskeisiä muuttujia. Indeksi laskee
                      elävyyden kokonaispistemäärää huomioiden elävyyskategorioiden saavutettavuutta
                      kävellen ja intensiteettiä eli palvelutiheyttä ja monipuolisuutta suhteessa
                      alueen asukasmäärään. LIVCY-elävyysindeksi on Rambollin kehittämä ja
                      tutkimustietoon perustuva rakennetun ympäristön elävyyden mittari.
                    </p>
                    <p>
                      Elävät ympäristöt ovat turvallisia, monimuotoisia, inklusiivisia ja
                      virikkeellisiä, jotka samalla tukevat kestäviä elintapoja ja terveyttä ja
                      hyvinvointia. Tutkimuksien mukaan ihmiset, jotka asuvat elävämmillä alueilla
                      kävelevät, pyöräilevät ja käyttävät julkista liikennettä enemmän ja siten myös
                      tukevat kestävää kaupunkiympäristöä.
                    </p>
                  </section>
                </Col>
                <Col>
                  <section>
                    <h6>Tulokset</h6>
                    <p>
                      {`Kuntasi LIVCY-elävyysindeksi kokonaisuudessaan on ${livcyIndex} pistettä eli elävyys alueella on ${livcyRatingTexts[getRatingValue(livcyIndex)]}`}
                    </p>
                    <p>
                      Kuntaasi vastaavassa kokoluokassa sijoitut vertailussa
                      2. elävimmäksi kunnaksi.
                    </p>
                    <p>
                      Sijoitukset
                    </p>
                    <ol>
                      <li>Helsinki, 86 pistettä</li>
                      <li>Turku, 77 pistettä</li>
                      <li>Tampere, 77 pistettä</li>
                    </ol>
                  </section>
                </Col>
              </Row>
            </Card.Text>
          </Card>

          <Card>
            <Card.Title>
              LIVCY-Elävyysindeksi
              <div>
                <small>{`— Kokonaiselävyys kunnassa ${city}`}</small>
              </div>
            </Card.Title>
            <Card.Text as="div">
              <Row className="flex-shrink">
                <Col>
                  <h2 className="h6">ALUEEN PERUSTIEDOT</h2>
                  <dl>
                    <dt>Väestömäärä:</dt>
                    <dd />
                    <dt>Työttömyysprosentti:</dt>
                    <dd />
                    <dt>Väestön ikäjakauma:</dt>
                    <dd />
                    <dt>Tulotaso:</dt>
                    <dd />
                  </dl>
                </Col>
              </Row>
              <Row className="flex-grow">
                <Col>
                  <section className="map-grow">
                    <ReportMapView
                      attributeGroup={attributeGroup}
                      attributeCode="livcy_i"
                      readonly
                      layerData={layerData}
                      map={map}
                      layer={layer}
                      extent={extent}
                      legendPosition="top"
                      showNumbers={false}
                      showZoom={false}
                    />
                  </section>
                </Col>
              </Row>
            </Card.Text>
          </Card>

          {reports.map((reportItem) => (
            <Card key={reportItem.code}>
              <Card.Body>
                <Card.Title>
                  <h2>{reportItem.title}</h2>
                  <div className="livcy-heading">
                    <div className={`icon-circle ${reportItem.code}`}>
                      <reportItem.icon />
                    </div>
                    <span className="livcy-index">{reportItem.index}</span>
                    <span className="livcy-index-desc">{reportItem.heading}</span>
                  </div>
                </Card.Title>
                <Card.Text as="div">
                  <Row>
                    <Col>
                      <section>
                        <h6>Mittari</h6>
                        {reportItem.description.map((d) => <p>{d}</p>)}
                      </section>
                      <section className="takeaway">
                        <span className="takeaway-highlight">{reportItem.highlight.title}</span>
                        {reportItem.highlight.description}
                      </section>
                    </Col>
                    <Col>
                      <section>
                        <h6>Tulokset</h6>
                        {reportItem.results.map((r) => <p>{r}</p>)}
                      </section>
                      <section className="map">
                        <ReportMapView
                          readonly
                          layerData={layerData}
                          map={map}
                          layer={layer}
                          extent={extent}
                          attributeGroup={attributeGroup}
                          attributeCode={reportItem.attributeCode}
                          legendPosition="top"
                          showNumbers={false}
                          showZoom={false}
                        />
                      </section>
                    </Col>
                  </Row>
                </Card.Text>
              </Card.Body>
            </Card>
          ))}

          <Card>
            <Card.Title>
              <h2>Yhteenveto</h2>
            </Card.Title>
            <Card.Body>
              <Table className="report-table">
                <thead>
                  <tr>
                    <th>Indikaattori</th>
                    <th>Lyhyt kuvaus</th>
                    <th>Arvo</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><strong>LIVCY-elävyysindeksi</strong></td>
                    <td>Mittaa alueen kokonaiselävyyden perustuen alla oleviin kategorioihin</td>
                    <td><strong>{livcyIndex}</strong></td>
                  </tr>
                  {reports.map((r) => (
                    <tr key={r.title}>
                      <td>
                        <div className="table-icon-text">
                          <div className={`icon-circle ${r.code}`}>
                            <r.icon />
                          </div>
                          <div className="title">
                            {r.title}
                          </div>
                        </div>
                      </td>
                      <td>
                        {r.indexDescription}
                      </td>
                      <td>
                        {r.index}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>

          <Card>
            <Card.Title className="summary">
              <h2>Yhteenveto</h2>
            </Card.Title>
            <Card.Text as="div" className="summary">
              <Row>
                {summary.columns.map((col, idx) => (
                  <Col>
                    <section>
                      {col.map((c) => (
                        <>
                          {isInEditMode ? (
                            <>
                              <Form.Control type="text" placeholder="Väliotsikko" value={c.title} />
                              <Form.Control
                                as="textarea"
                                placeholder="Lisää yhteenveto"
                                value={c.text}
                              />
                            </>
                          ) : (
                            <>
                              <h6>{c.title}</h6>
                              <p>{c.text}</p>
                            </>
                          )}
                        </>
                      ))}
                    </section>
                    <section>
                      {isInEditMode && <Button onClick={() => { setSummary((s) => { s.columns[idx].push({ title: '', text: '' }); return { ...s }; }); }}>Lisää Tekstikenttä</Button>}
                    </section>
                  </Col>
                ))}
              </Row>
              {false && (!isInEditMode ? (
                <Button onClick={() => { setIsInEditMode((m) => !m); }}>
                  <FaPen />
                  {' '}
                  Muokkaa
                </Button>
              ) : (
                <Button onClick={() => { setIsInEditMode((m) => !m); }}>
                  <FaSave />
                  {' '}
                  Tallenna
                </Button>
              ))}
            </Card.Text>
          </Card>

          <Card className="card-blue">
            <Card.Title>
              <h2>LIVCY by Ramboll</h2>
            </Card.Title>
            <Card.Text as="div">
              <Row>
                <Col>
                  <section>
                    <p>Haluatko lisätietoa kuntasi elävyydestä ja nykytilasta?</p>
                    <p>
                      LIVCY-sovelluksen avulla voit tarkastella alueiden elävyyttä kunta-,
                      postinumeroalue- tai ruututasolla (ruudut 250 m x 250 m). Lisäksi näet
                      tarkemmin, miten saavutettavia tai miten tiheästi palvelut sijaitsevat
                      alueellasi ja pääset tarkastelemaan elävyyskategorioiden tarkempia
                      palveluluokituksia. Työkalu mahdollistaa myös alueiden välisen vertailun
                      sekä väestö- ja työpaikkapotentiaalin visualisoinnin alueella, jolloin voit
                      selvittää mm. mitkä alueet tarvitsevat enemmän elävyyttä ja lisäarvoa
                      tuottavia palveluita.
                    </p>
                    <p>
                      LIVCY-sovellus on osa LIVCY-työkalupakkia, jonka sisältö määritellään ja
                      räätälöidään sinun alueesi tarpeiden mukaisesti. Palveluun voidaan tuoda
                      uusia mittareita tai yhdistää siihen tietoa myös asukkaiden käyttäytymisestä
                      ja kokemuksista. Työkalupakki voi pitää sisällään työpajoja, kyselyitä,
                      haastatteluja tai muita elävyydestä lisätietoja antavia työkaluja ja
                      prosesseja.
                    </p>
                    <p>Kysy lisää!</p>
                  </section>
                </Col>
              </Row>
              <Row>
                <Col>
                  <section>
                    <h3>OTA YHTEYTTÄ</h3>
                    <p>
                      <strong>Eero Salminen</strong>
                      <br />
                      Palvelupäällikkö, kaupunki- ja hankekehitys
                    </p>
                    <p>
                      eero.salminen@ramboll.fi
                      <br />
                      P: +358 503 257 830
                    </p>

                    <h4>Ramboll Finland Oy</h4>
                    <p>
                      fi.ramboll.com
                      <br />
                      info@ramboll.fi
                      <br />
                      +358 (0)20 755 611
                    </p>
                  </section>
                </Col>
                <Col>
                  <section>
                    <p>
                      <br />
                      fi.ramboll.com/livcy
                      <br />
                      Facebook: rambollfinland
                      <br />
                      LinkedIn: ramboll
                      <br />
                      Youtube: rmbllfnlnd
                      <br />
                      Twitter: @ramboll_fi
                      <br />
                      Instragram: rambollfinland
                    </p>
                  </section>
                </Col>
              </Row>
              <Row className="footer">
                <Col>
                  <h2 className="mb-4">Bright ideas. Sustainable change.</h2>
                  <img src={RambollLogo} alt="Ramboll logo" width="140px" />
                </Col>
              </Row>
            </Card.Text>
          </Card>
        </div>
      )}
    </Container>
  );
};

export default Reports;
