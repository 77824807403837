import { clone } from 'dda-helpers';
import React, { useEffect, useState } from 'react';
import {
  Alert, Button, ButtonGroup, Container, Form, Table,
} from 'react-bootstrap';
import Api from '../../services/api';

const Admin = () => {
  const [accounts, setAccounts] = useState([]);
  const [account, setAccount] = useState(null);
  const [error, setError] = useState('');
  // Retrieve users
  const retrieveAccounts = async () => await Api().accounts.get({});

  useEffect(() => {
    retrieveAccounts().then((a) => setAccounts(a));
  }, []);

  const handleEditAccount = async (accountId) => {
    // At the moment the retrieved accounts is the full list and there is no other way to retrieve single users
    setAccount(clone(accounts.find((a) => a.id === accountId)));
  };
  const handleRemoveAccount = async (userId) => {
    await Api().accounts.delete({ userId });
    setAccount(null);
    retrieveAccounts().then((a) => setAccounts(a));
  };

  const updateAccount = async () => {
    setError('');
    try {
      await Api().accounts.update({ ...account, password: account.password === undefined ? '' : account.password });
      setAccount(null);
    } catch (e) {
      setError(e.response.data);
    }
  };

  return (
    <Container>
      <Table className="my-4">
        <thead>
          <tr>
            <th>#</th>
            <th>Email</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {accounts.map((a) => (
            <>
              <tr key={a.id}>
                <td>{a.id}</td>
                <td>{a.email}</td>
                <td>
                  <ButtonGroup size="sm">
                    <Button variant="danger" onClick={() => handleRemoveAccount(a.id)}>Remove</Button>
                    <Button onClick={() => handleEditAccount(a.id)}>Edit</Button>
                  </ButtonGroup>
                </td>
              </tr>
              {account !== null && a.id === account.id && (
              <tr>
                <td colSpan="3">
                  <Form>
                    <Form.Group>
                      <Form.Label>Username (login)</Form.Label>
                      <Form.Control type="text" placeholder="Enter username" defaultValue={account.username} onChange={(e) => setAccount((p) => ({ ...p, username: e.target.value }))} />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Name</Form.Label>
                      <Form.Control type="text" placeholder="Enter name" defaultValue={account.name} onChange={(e) => setAccount((p) => ({ ...p, name: e.target.value }))} />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Email address</Form.Label>
                      <Form.Control type="email" placeholder="Enter email" defaultValue={account.email} onChange={(e) => setAccount((p) => ({ ...p, email: e.target.value }))} />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Phone</Form.Label>
                      <Form.Control type="text" placeholder="Enter phone" defaultValue={account.telephone} onChange={(e) => setAccount((p) => ({ ...p, telephone: e.target.value }))} />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Company</Form.Label>
                      <Form.Control type="text" placeholder="Enter company" defaultValue={account.company} onChange={(e) => setAccount((p) => ({ ...p, company: e.target.value }))} />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Address</Form.Label>
                      <Form.Control type="text" placeholder="Enter address" defaultValue={account.address} onChange={(e) => setAccount((p) => ({ ...p, address: e.target.value }))} />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Password</Form.Label>
                      <Form.Control type="password" placeholder="Enter password" defaultValue={account.password} onChange={(e) => setAccount((p) => ({ ...p, password: e.target.value }))} />
                    </Form.Group>

                    <Button onClick={() => updateAccount()}>Update</Button>
                    {error !== '' && <Alert variant="danger">{error}</Alert>}

                  </Form>
                </td>
              </tr>
              )}
            </>
          ))}

        </tbody>
      </Table>
    </Container>
  );
};

export default Admin;
