import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';

import fi from './fi.json';
import en from './en.json';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
// .use(LanguageDetector)
  .init({
    resources: {
      en: {
        translation: en,
      },
      fi: {
        translation: fi,
      },
    },
    whitelist: ['en', 'fi'],
    lng: 'fi',
    fallbackLng: 'fi',

    interpolation: {
      escapeValue: false,
    },
  });
