import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Form,
  Button,
  Col,
  Card,
} from 'react-bootstrap';

import { FaTrash } from 'react-icons/fa';
import loglevel from '../services/loglevel';

const LegendForm = (props) => {
  const { t } = useTranslation();

  const [projectMap, setProjectMap] = useState({
    name: '',
    description: '',
    attributeGroups: [],
    arcgisOnlineMapAppId: '',
    id: -1,
    isPublic: false,
    ...props.projectMap,
  });

  /* useEffect(() => {
        setProjectMap(props.projectMap, [props.projectMap])
    }, [props])
    */

  useEffect(() => {
    loglevel.info('project changed');
    console.log(projectMap);
    props.onChange(projectMap);
  }, [props, projectMap]);

  const handleChange = (legend, pname) => (event) => {
    let { value } = event.target;
    const name = pname === undefined && event.target.name;

    console.log(value, name, event);
    console.log(event.currentTarget.name);
    console.log(event.target.name);

    if (value === 'true') {
      value = true;
    }
    if (value === 'false') {
      value = false;
    }

    setProjectMap((map) => {
      const foundLegend = map.legends.find((l) => l === legend);
      foundLegend[name] = value;
      return {
        ...map,
        legends: map.legends,
      };
    });
  };

  const handleStepChange = (legend, step) => (event) => {
    const { value } = event.target;
    const { name } = event.target;

    setProjectMap((map) => {
      const foundLegend = map.legends.find((l) => l === legend);
      const foundStep = foundLegend.steps.find((s) => s === step);
      foundStep[name] = value;
      return map;
    });
  };

  const handleRemoveStep = (legend, step) => {
    setProjectMap((map) => {
      const foundLegend = map.legends.find((l) => l === legend);
      foundLegend.steps.splice(legend.steps.findIndex((s) => s === step), 1);

      return {
        ...map,
        legends: [...map.legends],
      };
    });
  };

  const handleAddStep = (legend) => {
    setProjectMap((map) => {
      map.legends.find((l) => l === legend).steps.push({ limit: 0, color: '', description: '' });

      return {
        ...map,
        legends: [...map.legends],
      };
    });
  };

  const handleAddLegend = () => {
    setProjectMap((map) => ({
      ...map,
      legends: [...map.legends, {
        id: 0, name: '', description: '', steps: [],
      }],
      // legends: [...map.legends, { id: Math.max(map.legends.map(l => Number(l.id))) + 1, name: '', description: '', steps: [] }]
    }));
  };

  return (
    <div>
      {projectMap.legends.map((l) => (
        <Card key={l.id} className="mb-2">
          <Form>
            <Card.Body>
              <Form.Group controlId="formName">
                <Form.Label>{t('Name')}</Form.Label>
                <Form.Control name="name" defaultValue={l.name} type="text" placeholder={t('Enter name')} onChange={handleChange(l)} />
              </Form.Group>
              <Form.Group controlId="formDescription">
                <Form.Label>{t('Description')}</Form.Label>
                <Form.Control name="description" defaultValue={l.description} type="text" placeholder={t('Enter description')} onChange={handleChange(l)} />
              </Form.Group>
            </Card.Body>
            <hr />
            <Card.Body>
              <Form.Group controlId="formAttributeGroup">
                <Form.Label>{t('Attribute group')}</Form.Label>
                <Form.Control as="select" custom name="attributeGroupId" onChange={handleChange(l)}>
                  <option />
                  {projectMap.attributeGroups.map((ag) => <option selected={ag.id === l.attributeGroupId} value={ag.id}>{ag.name}</option>)}
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="formLayer">
                <Form.Label>{t('Layer')}</Form.Label>
                <Form.Control as="select" custom name="layerId" onChange={handleChange(l)}>
                  <option />
                  {projectMap.layers.map((ag) => <option selected={ag.id === l.layerId} value={ag.id}>{ag.name}</option>)}
                </Form.Control>
              </Form.Group>
            </Card.Body>
            <hr />
            <Card.Body>
              <Form.Group>
                <Form.Label>{t('Steps')}</Form.Label>
                {l.steps.map((step) => (
                  <div>
                    <Form.Row className="my-2">
                      <Col>
                        <Form.Control name="threshold" defaultValue={step.threshold} type="text" placeholder={t('Threshold')} onChange={handleStepChange(l, step)} />
                      </Col>
                      <Col>
                        <Form.Control name="color" defaultValue={step.color} type="text" placeholder={t('Color')} onChange={handleStepChange(l, step)} />
                      </Col>
                      <Col>
                        <Form.Control name="description" defaultValue={step.description} type="text" placeholder={t('Description')} onChange={handleStepChange(l, step)} />
                      </Col>
                      <Col>
                        <Button onClick={() => handleRemoveStep(l, step)}><FaTrash /></Button>
                      </Col>
                    </Form.Row>
                  </div>
                ))}

                <br />
                <Button onClick={() => handleAddStep(l)}>{t('Add step')}</Button>
              </Form.Group>

            </Card.Body>
          </Form>
        </Card>
      ))}
      <Button onClick={() => handleAddLegend()}>{t('Add legend')}</Button>
    </div>
  );
};
export default LegendForm;
