import React from 'react';
import { ButtonGroup, Button, Dropdown } from 'react-bootstrap';
import { Bar, HorizontalBar } from 'react-chartjs-2';
import { FaPen, FaCog } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

const BarWidget = ({
  allowEdit, title, description, data, onEdit, onInlineEdit, onRemove, onCopy, type, children,
}) => {
  const { t } = useTranslation();
  const options = {
    legend: {
      display: true,
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem, d) => {
          const { index } = tooltipItem;
          const { label } = d.datasets[tooltipItem.datasetIndex];
          const currentValue = Math.round(
            Number(d.datasets[tooltipItem.datasetIndex].data[index]),
          );
          return `${label}: ${currentValue}`;
        },
      },
    },
    scales: {
      xAxes: [{
        gridLines: {
          display: true,
        },
        ticks: {
          beginAtZero: true,
          callback(value) {
            const unit = data.datasets[0]?.unit;
            if (data.graphType === 1 && data.attributeGroupId === 2) {
              return `${value} ${unit}`;
            }
            if (data.graphType === 0 && data.isDistribution) {
              return `${value} %`;
            }
            return value;
          },
        },
      }],
      yAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            mirror: false,
            z: 1,
            padding: -4,
            fontColor: '#000',
            beginAtZero: true,
            callback(value) {
              const unit = data.datasets[0]?.unit;
              if (data.graphType === 0 && data.attributeGroupId === 2) {
                return `${value} ${unit}`;
              }
              if (data.graphType === 1 && data.isDistribution) {
                return `${value} %`;
              }
              return value;
            },
          },
        },
      ],
    },
    maintainAspectRatio: false,
  };

  return (
    <div className="box">
      {children ? <div className="box__body">{children}</div> : (
        <>
          <div className="box__header px-2 pt-2 relative">
            <h2 className="box__title">{title}</h2>
            <small>{description}</small>
            <ButtonGroup style={{ position: 'absolute', right: '1em', top: '1em' }} size="sm">
              {allowEdit && (
                <Dropdown>
                  <Dropdown.Toggle size="sm" variant="outline-primary">
                    <FaCog />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => onRemove()}>{t('remove')}</Dropdown.Item>
                    <Dropdown.Item onClick={() => onCopy !== undefined && onCopy()}>{t('copy')}</Dropdown.Item>
                    <Dropdown.Item onClick={() => onEdit()}>{t('edit')}</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
              <Button variant="outline-primary" onClick={() => onInlineEdit()}><FaPen /></Button>
            </ButtonGroup>
          </div>
          <div className="box__body" style={{ height: '300px' }}>
            {type === 0 && <HorizontalBar options={options} data={data} />}
            {type === 1 && <Bar options={options} data={data} />}
          </div>
        </>
      )}
    </div>
  );
};

export default BarWidget;
