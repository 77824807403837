import http from './http-common';

const Api = () => ({
  get client() {
    return http;
  },
  get health() {
    const available = async () => {
      try {
        const healthyOrNot = await http.get('health');
        return healthyOrNot === 'Healthy';
      } catch (e) {
        return false;
      }
    };
    return { available };
  },
  get settings() {
    const app = async () => http.get('settings/app');
    return { app };
  },
  get accounts() {
    const login = async ({
      email, password, rememberMe, refreshToken, service, code, accessToken,
    }) => http.post('account/login', {
      email, password, rememberMe, code, accessToken,
    }, { params: { service }, withCredentials: true });

    const get = async ({ projectId, userId }) => {
      if (userId !== undefined) {
        return http.get('account', { params: { userId } });
      }
      return http.get('account', { params: { projectId } });
    };
    const refresh = async () => http.post('account/login/refresh/', null, { withCredentials: true });
    const me = {
      get: async () => http.get('account/me'),
      update: async ({
        username, password, name, email, telephone, company, address, currentPassword, eulaAcceptedVersion,
      }) => {
        if (password === undefined) {
          password = '-';
        }
        return http.put('account/me', {
          username, password, name, email, telephone, company, address, currentPassword, eulaAcceptedVersion,
        });
      },
      roles: async () => http.get('account/me/roles'),
    };

    const update = async ({
      id, username, password, name, email, telephone, company, address, isAdmin,
    }) => http.put(`account/${id}`, {
      username, password, name, email, telephone, company, address, isAdmin,
    });

    const register = async ({
      username, password, name, email, telephone, company, address, isAdmin,
    }) => http.post('account/register', {
      username, password, name, email, telephone, company, address, isAdmin,
    });
    const deleteUser = async ({ userId }) => http.delete(`account/${userId}`);
    const logout = async () => http.post('account/logout');
    const resetPassword = async ({
      token, password, email, isInvite,
    }) => http.post('account/resetpassword', {
      token, password, email, isInvite: !!isInvite,
    });
    const resetPasswordRequest = async ({ email }) => http.get(`account/resetpassword?email=${email}`);

    return {
      login, get, register, me, update, delete: deleteUser, refresh, logout, resetPassword, resetPasswordRequest,
    };
  },
  get projects() {
    const projectDelete = async ({ projectId }) => http.delete(`project/${projectId}`);

    const get = async ({ projectId }) => {
      if (projectId !== undefined && projectId !== null) {
        return http.get(`project/${projectId}`);
      }
      return http.get('project');
    };
    const users = ({ projectId }) => ({
      get: async () => http.get(`project/${projectId}/user`),
      delete: async (userId) => http.delete(`project/${projectId}/user/${userId}`),
    });

    const status = async ({ projectId }) => await http.get(`project/${projectId}/status`);

    const post = async ({
      name, description, client, status, copy, copyAttributeName, copyAttributeQuery, copyMapId, copyProjectId,
    }) => http.post('project', {
      name, description, client, status, copy, copyAttributeName, copyAttributeQuery, copyProjectId, copyMapId,
    });
    const update = async ({
      projectId, name, description, client, status,
    }) => http.put(`project/${projectId}`, {
      name, description, client, status,
    });

    const addUser = async ({ projectId, userId, roleId }) => http.put(`project/${projectId}/user/${userId}`, undefined, { params: { roleId } });
    const removeUser = async ({ projectId, userId }) => http.delete(`project/${projectId}/user/${userId}`);
    const extractCancel = async ({ projectId }) => http.post(`project/${projectId}/extract/cancel/`);
    const inviteUser = async ({ projectId, email, roleId }) => http.post(`project/${projectId}/invite/`, { projectId, email, roleId: Number(roleId) });

    const widgets = ({ projectId, widgetId }) => ({
      get: async () => http.get(`project/${projectId}/widget`),
      update: async ({
        graphType, attributeGroupId, title, description, attributes, features, mapId, isDistribution,
      }) => http.put(`project/${projectId}/widget/${widgetId}`, {
        mapId: Number(mapId),
        graphType: Number(graphType),
        attributeGroupId: Number(attributeGroupId),
        title,
        description,
        attributes,
        isDistribution,
        features: features.map((f) => ({
          layerFeatureId: Number(f.layerFeatureId),
          name: f.name,
        })),
      }),
      remove: async () => http.delete(`project/${projectId}/widget/${widgetId}`),
    });

    return {
      get, post, update, delete: projectDelete, addUser, removeUser, status, extractCancel, inviteUser, widgets, users,
    };
  },
  get reports() {
    const get = async ({ reportId }) => {
      if (reportId !== undefined && reportId !== null) {
        return http.get(`report/${reportId}`);
      }
      return http.get('report');
    };
    const value = async ({ reportId, attributes }) => {
      if (reportId !== undefined && reportId !== null) {
        return http.get(`report/${reportId}/value`, { params: { attributes: attributes.join(',') } });
      }
    };
    const mapdata = async ({ reportId, attributes }) => {
      if (reportId !== undefined && reportId !== null) {
        return http.get(`report/${reportId}/mapdata`, { params: { attributes: attributes.join(',') } });
      }
    };

    return { get, value, mapdata };
  },
  get maps() {
    const compare = ({ widgetId, mapId }) => ({
      get: async () => http.get(`map/${mapId}/compare/widget`, { params: {} }),
      post: async ({
        graphType, attributeGroupId, title, description, attributes, features, isDistribution,
      }) => {
        console.log('features', features);
        return http.post(`map/${mapId}/compare/widget`, {
          mapId: Number(mapId),
          graphType: Number(graphType),
          attributeGroupId: Number(attributeGroupId),
          title,
          description,
          attributes,
          isDistribution,
          features: features.map((f) => ({
            layerFeatureId: Number(f.layerFeatureId),
            name: f.name,
          })),
        });
      },
    });
    const get = async ({ id, projectId, filters }) => {
      if (!Array.isArray(filters)) {
        filters = [];
      }
      if (projectId !== undefined && projectId !== null) {
        return http.get('map/', { params: { projectId, filters: filters.join(',') } });
      }
      return http.get(`map/${id}`, { params: { filters: filters.join(',') } });
    };
    const post = async ({
      projectId, name, description, arcgisOnlineMapAppId, attributeGroups, legends,
    }) => http.post('map', {
      projectId: Number(projectId), name, description, arcgisOnlineMapAppId, attributeGroups, legends,
    });
    const mapDelete = async ({ id }) => http.delete(`map/${id}`);
    const mapUpdate = async ({
      id, name, description, arcgisOnlineMapAppId, isPublic, attributeGroups, legends,
    }) => {
      legends = legends.map((l) => ({
        ...l,
        layerId: Number(l.layerId),
        attributeGroupId: Number(l.attributeGroupId),
        steps: l.steps.map((s) => ({
          ...s,
          threshold: Number(s.threshold),
        })),
      }));
      return http.put(`map/${id}`, {
        name, description, arcgisOnlineMapAppId, isPublic, attributeGroups, legends,
      });
    };

    const layer = (mapId) => {
      const get = async ({ layerId }) => {
        if (layerId !== undefined) {
          return http.get(`map/${mapId}/layer/${layerId}`, { params: {} });
        }
        return http.get(`map/${mapId}/layer/`, { params: {} });
      };

      const layerDelete = async ({ layerId }) => http.delete(`map/${mapId}/layer/${layerId}`, { params: {} });

      const layerUpdate = async ({
        id, name, description, attributes,
      }) => http.put(`map/${mapId}/layer/${id}`, {
        id, name, description, attributes,
      });

      const geojson = async ({ layerId }) =>
      // return await http.get('map/' + mapId + '/layer/' + layerId + '/geojson', { params: { attributes: 'livcy_i,NIMI' } })
        http.get(`map/${mapId}/layer/${layerId}/geojson`, { params: {} });

      const geometry = ({
        layerId, extent, limit, offset, filters,
      }, cancelToken) => {
        if (extent === null) {
          extent = [];
        }
        if (limit === undefined) {
          limit = 1000;
        }
        if (offset === undefined) {
          offset = 0;
        }
        if (!Array.isArray(filters)) {
          filters = [];
        }
        return http.get(`map/${mapId}/layer/${layerId}/geometry`, {
          cancelToken,
          params: {
            extent: extent.join(','),
            limit,
            offset,
            filters: filters.join(','),
          },
        });
      };
      const value = ({
        layerId, extent, limit, offset, attributes, filters,
      }, cancelToken) => {
        if (extent === null) {
          extent = [];
        }
        if (limit === undefined) {
          limit = 1000;
        }
        if (offset === undefined) {
          offset = 0;
        }
        if (!Array.isArray(filters)) {
          filters = [];
        }
        return http.get(`map/${mapId}/layer/${layerId}/value`, {
          cancelToken,
          params: {
            extent: extent.join(','),
            attributes: attributes.join(','),
            limit,
            offset,
            filters: filters.join(','),
          },
        });
      };
      const feature = ({ layerId, featureId }, cancelToken) => http.get(`map/${mapId}/layer/${layerId}/feature/${featureId}`, {
        cancelToken,
      });
      return {
        get, geojson, delete: layerDelete, update: layerUpdate, geometry, feature, value,
      };
    };

    const widget = (mapId) => {
      const get = async ({ projectId }) => {
        console.log(projectId, mapId);
        return http.get(`map/${mapId}/widget/`, { params: { projectId, mapId } });
      };
      const post = async ({
        projectMapId, name, description, type, layerName, attributeName, alwaysVisible, attributeGroupId, layerId,
      }) => http.post(`map/${mapId}/widget/`, {
        projectMapId, name, description, type, layerName, attributeName, alwaysVisible, attributeGroupId, layerId,
      });
      const widgetDelete = async ({ id }) => http.delete(`map/${mapId}/widget/${id}`);
      return { get, post, delete: widgetDelete };
    };

    const legend = (mapId) => {
      const get = async ({ layerId, legendId }) => {
        if (legendId === undefined) {
          return http.get(`map/${mapId}/layer/${layerId}/legend`, { params: {} });
        }
        return http.get(`map/${mapId}/layer/${layerId}/legend/${legendId}`, { params: {} });
      };
      const post = async ({
        layerId, legendId, id, attributeGroupId, name, description, steps,
      }) => http.post(`map/${mapId}/layer/${layerId}/legend`, {
        layerId,
        attributeGroupId,
        name,
        description,
        steps: steps.map((s) => ({
          ...s,
          threshold: Number(s.threshold),
        })),
      });

      return { get, post };
    };

    return {
      get, post, update: mapUpdate, delete: mapDelete, widget, layer, legend, compare,
    };
  },
});

export default Api;
